import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  signal
} from '@angular/core';
import { InputBaseClass } from '@core/classes';
import { InputComponent } from '../input/input.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-password-input',
  standalone: true,
  imports: [CommonModule, InputComponent, FontAwesomeModule],
  templateUrl: './password-input.component.html',
  styleUrl: './password-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordInputComponent extends InputBaseClass {
  @HostBinding('class.valid')
  public get valid(): boolean {
    return this.control.valid;
  }

  public visible = signal(false);

  public toggleVisibility(): void {
    this.visible.set(!this.visible());
  }
}
