import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LabelComponent } from '@shared/components';
import { DialogComponent } from '@shared/components/dialog/dialog/dialog.component';
import { ButtonDirective } from '@shared/directives';
import { SanitizePipe } from '@shared/pipes';

@Component({
  selector: 'app-license-expires-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    LabelComponent,
    TranslateModule,
    DialogComponent,
    SanitizePipe
  ],
  templateUrl: './license-expires-dialog.component.html',
  styleUrl: './license-expires-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseExpiresDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: { email: string; hours: number },
    public dialogRef: DialogRef<LicenseExpiresDialogComponent>
  ) {}

  public close(): void {
    void this.dialogRef.close();
  }
}
