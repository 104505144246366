import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services';

export const isAuthenticatedGuard: CanActivateFn = () => {
  const authenticated = inject(AuthService).isAuthenticated();

  if (!authenticated) {
    void inject(Router).navigate(['/'], { queryParamsHandling: 'merge' });
  }

  return authenticated;
};

export const isNotAuthenticatedGuard: CanActivateFn = () => {
  const authenticated = inject(AuthService).isAuthenticated();

  if (authenticated) {
    void inject(Router).navigate(['/auth/connection'], {
      queryParamsHandling: 'merge'
    });
  }

  return !authenticated;
};
