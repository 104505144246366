<app-dialog [disableClose]="false" (closeClicked)="close()">
  <img src="assets/img/logos/wemind-blue.svg" class="logo" alt="EMDR logo" />
  <h2 class="text-bold mb-10">Thank you for subscribing</h2>
  <p class="text-bold mb-8">
    As a sign of appreciation and a shared step in the right direction, we will
    pay for your onboarding worth <span class="orange">$100</span> for you
  </p>
  <button appButton="primary" size="sm" class="px-20" (click)="claim()">
    <app-label>Claim it here!</app-label>
  </button>
</app-dialog>
