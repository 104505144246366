import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  @Input()
  public size: 'md' | 'lg' | 'xl' | '2xl' = 'md';

  @HostBinding('class.md')
  public get isMd(): boolean {
    return this.size === 'md';
  }

  @HostBinding('class.lg')
  public get isLg(): boolean {
    return this.size === 'lg';
  }

  @HostBinding('class.xl')
  public get isXl(): boolean {
    return this.size === 'xl';
  }

  @HostBinding('class.xl2')
  public get is2Xl(): boolean {
    return this.size === '2xl';
  }
}
