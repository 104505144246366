export interface AWSChimeCreateMeetingResponse {
  body: {
    newMeeting: {
      meeting: AWSChimeMeeting;
      attendee: AWSChimeAttendee;
    };
  };
}

export interface AWSChimeCreateAttendeeResponse {
  body: {
    newAttendee: {
      attendee: AWSChimeAttendee;
    };
  };
}

export interface AWSChimeMeeting {
  ExternalMeetingId: string;
  MediaPlacement: AWSChimeMediaPlacement;
  MediaRegion: string;
  MeetingArn: string;
  MeetingHostId: string;
  MeetingId: string;
  TenantIds: Array<any>;
}

export interface AWSChimeAttendee {
  AttendeeId: string;
  Capabilities: {
    Audio: string;
    Content: string;
    Video: string;
  };
  ExternalUserId: string;
  JoinToken: string;
}

export interface AWSChimeMediaPlacement {
  AudioFallbackUrl: string;
  AudioHostUrl: string;
  EventIngestionUrl: string;
  ScreenDataUrl: string;
  ScreenSharingUrl: string;
  ScreenViewingUrl: string;
  SignalingUrl: string;
  TurnControlUrl: string;
}
