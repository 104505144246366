import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { Company, CompanyStatus } from '@core/models/company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private cachedCompanyStatus: CompanyStatus | null = null;

  constructor(private http: HttpClient) {}

  //public GetAllCompanies() {
  // return this.http.get<Company[]>(environment.baseUrl + 'Company');
  // }

  // public AddCompany(company: Company) {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   const body = JSON.stringify(company);
  //   return this.http.post<Company>(environment.baseUrl + 'Company', body, { headers });
  // }

  // public UpdateCompany(company: Company) {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   const body = JSON.stringify(company);
  //   return this.http.put<Company>(environment.baseUrl + 'Company', body, { headers });
  // }

  // public DeleteCompany(company: Company) {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http.delete<void>(environment.baseUrl + 'Company/id/' + company.id, { headers });
  // }

  public GetCompanyByName(companyName: string) {
    const headers = new HttpHeaders({ companyname: companyName });
    return this.http.get<Company>(
      environment.baseUrl + 'Company/name/' + companyName,
      { headers }
    );
  }

  // public getCompanyByID(id: number): Observable<Company> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  //   return this.http.get<Company>(environment.baseUrl + `company/id/${id}`, {
  //    headers
  //  });
  //}

  // Method to fetch the company status, with caching
  public getCompanyStatusByEmail(email: string): Observable<CompanyStatus> {
    if (this.cachedCompanyStatus) {
      // Return cached status if available
      return of(this.cachedCompanyStatus);
    }

    // Fetch new status and update cache
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .get<CompanyStatus>(
        `${environment.identityApiUrl}Company/getCompanyState/${email}`,
        { headers }
      )
      .pipe(
        tap((status) => {
          this.cachedCompanyStatus = status;
        })
      );
  }

  public clearCachedStatus(): void {
    this.cachedCompanyStatus = null;
  }

  // public getCompanyStatusByName(companyName: string): Observable<Company> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  //  return this.http.get<Company>(
  //    environment.identityApiUrl + `company/getCompanyStatus/${companyName}`,
  //    { headers }
  //  );
  // }
}
