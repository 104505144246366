/* eslint-disable */
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  computed,
  signal
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  LabelComponent,
  ToggleButtonComponent,
  ToggleButtonItemComponent
} from '@shared/components';
import { UnorderedListComponent } from '@shared/components/unordered-list/unordered-list.component';
import { SanitizePipe } from '@shared/pipes';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { AuthService } from 'src/app/core/services';
import { ButtonDirective } from 'src/app/shared/directives';
import { environment } from 'src/environments/environment';

export enum PaymentInterval {
  monthly = 1,
  yearly = 2
}

export enum SubscriptionType {
  essential = 1,
  perform = 2
}

export interface StripeUrlInterface {
  essential: string;
  perform: string;
}

@Component({
  selector: 'app-new-pricing-table',
  templateUrl: './new-pricing-table.component.html',
  styleUrls: ['./new-pricing-table.component.scss'],
  standalone: true,
  imports: [
    ButtonDirective,
    ToggleButtonComponent,
    ToggleButtonItemComponent,
    LabelComponent,
    UnorderedListComponent,
    TranslateModule,
    SanitizePipe
  ]
})
export class NewPricingTableComponent implements OnChanges {
  @Input()
  public email?: string | null;

  @Input()
  public disabled = false;

  @Input()
  public showToggle = true;

  @Input()
  public showEnterprise = true;

  @Input()
  public showFreeTrial = true;

  @Input()
  public paymentIntervalType = PaymentInterval.yearly;

  @Input()
  public customStripeURLS?: StripeUrlInterface;

  public paymentInterval = PaymentInterval;
  public subscriptionType = SubscriptionType;

  public _paymentIntervalType = signal(this.paymentIntervalType);

  public prices = {
    [SubscriptionType.essential]: {
      yearly: '395',
      montly: '35.95'
    },
    [SubscriptionType.perform]: {
      yearly: '460',
      montly: '42.95'
    }
  };

  public isYearly = computed(() => {
    return this._paymentIntervalType() === this.paymentInterval.yearly;
  });

  constructor(
    public auth: AuthService,
    private gtmService: GoogleTagManagerService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['paymentIntervalType']) {
      this._paymentIntervalType.set(this.paymentIntervalType);
    }
  }

  private getClientReferenceId() {
    return (
      // @ts-ignore
      (window.Rewardful && window.Rewardful.referral) ||
      'checkout_' + new Date().getTime()
    );
  }

  public changeInterval(value: PaymentInterval): void {
    this._paymentIntervalType.set(value);
  }

  public getActualYearlyPrice(monthlyPrice: string) {
    return (parseFloat(monthlyPrice) * 12).toFixed(1);
  }

  private getStripeSubscription(selectedSubscription: SubscriptionType) {
    if (this.customStripeURLS) {
      if (selectedSubscription === SubscriptionType.essential) {
        return this.customStripeURLS.essential;
      } else {
        return this.customStripeURLS.perform;
      }
    }

    if (this.isYearly()) {
      if (selectedSubscription === SubscriptionType.essential) {
        return environment.stripePaymentLinks.essentialYearly;
      }

      return environment.stripePaymentLinks.performYearly;
    } else if (!this.isYearly()) {
      if (selectedSubscription === SubscriptionType.essential) {
        return environment.stripePaymentLinks.essentialMonthly;
      }

      return environment.stripePaymentLinks.performMonthly;
    }

    return '';
  }

  public redirectToCheckout(selectedSubscription: SubscriptionType) {
    if (!this.disabled) {
      this.pushSignupGtmEvent(selectedSubscription);

      window.open(
        this.getStripeSubscription(selectedSubscription) +
          `?prefilled_email=${this.email}&client_reference_id=` +
          this.getClientReferenceId(),
        '_blank'
      );
    }
  }

  private pushSignupGtmEvent(selectedSubscription: SubscriptionType) {
    const gtmTag = {
      event: `Customer click subscription plan: ${selectedSubscription}`,
      data: {
        subscriptionType: selectedSubscription
      }
    };

    this.gtmService.pushTag(gtmTag).catch(() => {
      console.error('failed to push gtmTag');
    });
  }
}
