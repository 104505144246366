import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-flag-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './flag-icon.component.html',
  styleUrls: ['./flag-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlagIconComponent {
  @Input({ required: true })
  public countryCode!: string;

  @Input()
  @HostBinding('class')
  public size: 'md' | 'lg' = 'md';
}
