import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordStrengthValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;
    if (!value) {
      return null;
    }
    const hasLowercase = /[a-z]+/.test(value);
    const hasUppercase = /[A-Z]+/.test(value);
    const hasNumeric = /[0-9]+/.test(value);
    const hasSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]+/.test(value);
    const hasLength = value.length >= 8;
    const errors: Record<string, boolean> = {};
    let isPasswordValid = false;
    const numOfValid =
      +hasLowercase + +hasUppercase + +hasNumeric + +hasSpecial;

    if (numOfValid < 4 || !hasLength) {
      if (!hasLowercase) {
        errors['lowercase'] = true;
      }
      if (!hasUppercase) {
        errors['uppercase'] = true;
      }
      if (!hasNumeric) {
        errors['number'] = true;
      }
      if (!hasSpecial) {
        errors['special'] = true;
      }
      if (!hasLength) {
        errors['length'] = true;
      }
    } else {
      isPasswordValid = true;
    }

    return isPasswordValid ? null : { passwordStrength: errors };
  };
};
