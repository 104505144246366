import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSwitchComponent } from '../language-switch/language-switch.component';

@Component({
  selector: 'app-emdr-logo-card',
  standalone: true,
  imports: [CommonModule, LanguageSwitchComponent],
  templateUrl: './emdr-logo-card.component.html',
  styleUrls: ['./emdr-logo-card.component.scss']
})
export class EmdrLogoCardComponent {
  @Input()
  @HostBinding('class.has-header')
  public hasHeader = true;

  @Input()
  public languageSwitch = true;
}
