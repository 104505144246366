/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  computed,
  signal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterModule
} from '@angular/router';
import { NavbarComponent } from '../navbar/navbar.component';
import {
  LabelComponent,
  LanguageSwitchComponent,
  MenuDropdownComponent
} from '@shared/components';

import { ButtonDirective, LinkDirective } from '@shared/directives';
import { TranslateModule } from '@ngx-translate/core';
import { SanitizePipe } from '@shared/pipes';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { I18nService } from '@core/i18n';

import { toSignal } from '@angular/core/rxjs-interop';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subscription, timer } from 'rxjs';
import { TrialBannerComponent } from 'src/app/feature/auth/components/trial-banner/trial-banner.component';
import {
  MobileService,
  DialogService,
  AuthService,
  SessionService,
  TutorialService
} from '@core/services';
import { OnBoardingContentComponent } from 'src/app/feature/session/components/onboarding-content/onboarding-content.component';

@Component({
  selector: 'app-auth-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NavbarComponent,
    LanguageSwitchComponent,
    MenuDropdownComponent,
    ButtonDirective,
    LabelComponent,
    TranslateModule,
    SanitizePipe,
    RouterLink,
    LinkDirective,
    FontAwesomeModule,
    TrialBannerComponent
  ],
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthLayoutComponent implements OnInit {
  public isConnectionRoute: boolean = false;
  public isLoggedIn: boolean = false;
  public isTypeRoute: boolean = false;
  public isNewSignupRoute: boolean = false;
  public sessionID = toSignal(this.sessionService.sessionId$);
  public copyClicked = signal(false);
  public showTipsVisible = signal(false);
  private copyClickedTimer?: Subscription;

  public decryptedSessionID = computed(() => {
    return this.sessionService.decryptSessionId(this.sessionID());
  });

  public startMaintenance = new Date(
    'Sunday March 10 2024 12:00:00 GMT+0100'
  ).getHours();
  public formattedStart =
    this.startMaintenance < 10
      ? '0' + this.startMaintenance
      : this.startMaintenance;
  public endMaintenance = new Date(
    'Sunday March 10 2024 17:00:00 GMT+0100'
  ).getHours();
  public formattedEnd =
    this.endMaintenance < 10 ? '0' + this.endMaintenance : this.endMaintenance;

  @ViewChild(MenuDropdownComponent)
  public menuDropdown!: MenuDropdownComponent;

  @ViewChild('main')
  public main?: ElementRef;
  constructor(
    private router: Router,
    public mobileService: MobileService,
    private dialogService: DialogService,
    private i18nService: I18nService,
    private authService: AuthService,
    public sessionService: SessionService,
    private clipboard: Clipboard,
    public tutorialService: TutorialService
  ) {}

  public ngOnInit() {
    this.isLoggedIn = this.authService.isAuthenticated();
    this.isConnectionRoute = this.checkIfConnectionRoute();
    this.isTypeRoute = this.checkIfTypeRoute();
    this.isNewSignupRoute = this.checkIfNewSignupRoute();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          if (this.main?.nativeElement) {
            this.main.nativeElement.scrollTop = 0;
          }
        });

        this.isConnectionRoute = this.checkIfConnectionRoute();
        this.isLoggedIn = this.authService.isAuthenticated();
        this.isTypeRoute = this.checkIfTypeRoute();
        this.isNewSignupRoute = this.checkIfNewSignupRoute();
      }
    });
  }

  public openOnboarding(): void {
    this.dialogService.open(OnBoardingContentComponent, null);
  }

  public copyCode(): void {
    this.copyClicked.set(true);
    this.clipboard.copy(this.decryptedSessionID());

    this.copyClickedTimer?.unsubscribe();
    this.copyClickedTimer = timer(1500).subscribe(() => {
      this.copyClicked.set(false);
    });
  }

  public logOut(): void {
    this.dialogService
      .openPopup({
        title: this.i18nService.translate('label.are_you_sure'),
        description: this.i18nService.translate('dialog.confirm.logout'),
        secondaryLabel: this.i18nService.translate('label.cancel'),
        primaryLabel: this.i18nService.translate('label.confirm')
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.authService.logout();
        }
      });
  }

  public newSession(): void {
    this.dialogService
      .openPopup({
        title: this.i18nService.translate('label.are_you_sure'),
        description: this.i18nService.translate('dialog.confirm.new_session'),
        secondaryLabel: this.i18nService.translate('label.cancel'),
        primaryLabel: this.i18nService.translate('label.confirm')
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          void this.router.navigate(['/auth/connection']);
        }
      });
  }

  private checkIfConnectionRoute(): boolean {
    return this.router.url.includes('connection');
  }

  private checkIfTypeRoute(): boolean {
    return this.router.url.includes('type');
  }

  private checkIfNewSignupRoute(): boolean {
    return this.router.url.includes('signup');
  }

  public showOnboarding() {
    this.tutorialService.open();
    this.menuDropdown.dropdown?.close();
  }
}
