import { Injectable } from '@angular/core';
import Peer from 'peerjs';
import { Subject } from 'rxjs';

export interface PeerUsage {
  streaming: boolean;
  file: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PeerCoordinatorService {
  public peerUsage: PeerUsage = {
    streaming: false,
    file: false
  };

  public peer?: Peer;

  public destroyPeer$ = new Subject<void>();

  public destroyPeer(): void {
    this.destroyPeer$.next();
  }
}
