<fa-icon *ngIf="iconRight" [icon]="iconRight"> </fa-icon>
<div class="slider-wrapper">
  <div *ngIf="hasLabels" [ngClass]="['slider-top', 'text-' + fontSize]">
    <span #minLabelElement [class.hidden]="!showMinLabel()">{{
      minLabel
    }}</span>
    <span #maxLabelElement [class.hidden]="!showMaxLabel()">{{
      maxLabel
    }}</span>
  </div>
  <input
    #range
    [ngClass]="['slider', thumbType, bubbleType]"
    [class.disabled]="disabled"
    [formControl]="control"
    type="range"
    [min]="min"
    [max]="max"
    [step]="[step]"
    (mousedown)="onMouseDown()"
    (mouseup)="onMouseUp()"
    (touchstart)="onMouseDown()"
    (touchend)="onMouseUp()"
    (click)="$event.stopPropagation()" />
  <div class="slider-line"></div>
  <div class="slider-filler" [style.width.%]="getWidthPercentage()"></div>
  <span
    #bubble
    *ngIf="
      thumbType !== 'square' &&
      showBubble &&
      (bubbleType === 'bubble' || bubbleType === 'xlbubble')
    "
    [ngClass]="['value-bubble', bubbleType, 'text-' + fontSize]"
    [style.left]="getLeftValue()"
    [style.background-color]="
      bubbleType === 'xlbubble' && valueBackgroundColor
        ? valueBackgroundColor
        : undefined
    "
    [style.color]="valueColor">
    {{
      control.value
        ? (control.value * multiplier | number: getDecimals())
        : (control.value | number: getDecimals())
    }}{{ valueType }}
  </span>
  <div
    *ngIf="showRange && thumbType !== 'square'"
    [ngClass]="['slider-bottom', 'text-' + fontSize]">
    <span>{{ min }}{{ valueType }}</span>
    <span>{{ max }}{{ valueType }}</span>
  </div>
</div>
<div class="value-chip" *ngIf="showBubble && bubbleType === 'side'">
  {{
    control.value
      ? (control.value * multiplier | number: getDecimals())
      : (control.value | number: getDecimals())
  }}{{ valueType }}
</div>
<fa-icon *ngIf="iconLeft" [icon]="iconLeft"> </fa-icon>
