import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import dayjs from 'dayjs';
import { CompanyStatus } from '@core/models/company.model';
import { MailchimpTag, SessionStorageKey } from '@core/enums';
import { User, Credentials } from '@core/models';
import { Signup } from '@core/models/signup.model';
import { SessionStorageService } from '@core/services';
import { Observable, Subject, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public readonly user = signal<User | undefined>(
    this.getUserFromSessionStorage()
  );
  public isAuthenticated = computed(() => {
    return !!this.user();
  });
  private logoutSubject = new Subject<void>();

  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private router: Router
  ) {}

  public createUserAndCompany(
    signupData: Signup,
    customerType: MailchimpTag
  ): Observable<unknown> {
    const data = {
      companyDto: {
        name: signupData.company,
        email: signupData.email,
        phoneNumber: signupData.phonenumber,
        kvk: '',
        language: 0,
        signatory: '',
        estimateEMDRPractitioners: 0,
        estimateEMDRSessionPerMonth: 0,
        estimateExposurePractitioners: 0,
        estimateExposureSessionPerMonth: 0,
        paymentMethod: 1,
        iban: '',
        accountHolder: '',
        bic: '',
        countryAlpha3Code: '',
        state: CompanyStatus.DEMO,
        postalAddressId: 0,
        postalAddress: {
          street: '',
          houseNumber: 0,
          houseNumberSuffix: '',
          postalcode: '',
          city: ''
        },
        invoiceAddressId: 0,
        invoiceAddress: {
          street: '',
          houseNumber: 0,
          houseNumberSuffix: '',
          postalcode: '',
          city: ''
        },
        enableSSOLogin: false,
        promotionCode: '',
        customerType: customerType
      },
      inviteUserDto: {
        firstName: signupData.firstname,
        lastName: signupData.lastname,
        email: signupData.email,
        vret: false,
        online_EMDR: true,
        exposure: true,
        companyName: signupData.company,
        password: signupData.password,
        phoneNumber: signupData.phonenumber,
        VRET_ExpiresAt: '',
        EMDR_ExpiresAt: dayjs().add(14, 'day').format('YYYY-MM-DD')
      },
      dontCreateUser: true
    };

    return this.registerCompany(data).pipe(
      mergeMap(() => {
        return this.registerUser(data);
      })
    );
  }

  private registerCompany(data: unknown): Observable<unknown> {
    return this.http.post<Signup>(
      environment.identityApiUrl + 'Register/RegisterTrialCompany',
      data,
      {}
    );
  }

  private registerUser(data: unknown): Observable<unknown> {
    return this.http.post<Signup>(
      environment.identityApiUrl + 'Register/RegisterTrialUser',
      data,
      {}
    );
  }

  public checkIfEmailExists(email: string) {
    return this.http.get<boolean>(
      environment.identityApiUrl + 'User/Available/email/' + email,
      {}
    );
  }

  public checkIfCompanyExists(companyName: string) {
    return this.http.get<boolean>(
      environment.identityApiUrl + 'Company/available/' + companyName,
      {}
    );
  }

  /**
   * Method for logging in with authorization of the EMDR api (Legacy)
   * @legacy Original method of logging in
   */
  public login(
    credentials: Credentials,
    isHashed = false
  ): Observable<User | undefined> {
    const headers = new HttpHeaders({
      username: credentials.username.toLowerCase().trim(),
      password: credentials.password,
      isHashed: isHashed.toString()
    });
    return this.http.post<User | undefined>(
      environment.baseUrl + 'Auth/authenticate',
      {},
      { headers }
    );
  }

  /**
   * Logs the user out, aka empty the session and redirect
   */
  public logout(): void {
    this.user.set(undefined);
    this.sessionStorageService.remove(SessionStorageKey.LoggedInUser);
    this.sessionStorageService.remove(SessionStorageKey.IdentityLogin);
    this.logoutSubject.next(); // Notify logout subscribers

    void this.router.navigate(['/']);
  }

  public onLogout(): Observable<void> {
    return this.logoutSubject.asObservable();
  }
  /**
   * Sets the user in the authservice and sessionstorage
   * @legacy Method to get original user
   * @note Method could be removed after migration
   */
  public setUser(user: User): void {
    this.user.set(user);
    this.sessionStorageService.set(SessionStorageKey.LoggedInUser, user);
    this.sessionStorageService.set(SessionStorageKey.IdentityLogin, false);
  }

  /**
   * Gets the current user
   * @legacy Method of getting orginal user
   * @note Method could be removed after migration
   */
  public getUser(): User | undefined {
    return this.user();
  }

  /**
   * Get the current user from the session storage
   * @legacy Method of getting orginal user from session storage
   * @note Method could be removed after migration
   */
  public getUserFromSessionStorage(): User | undefined {
    return this.sessionStorageService.get(SessionStorageKey.LoggedInUser);
  }

  /**
   * Returns the user token
   */
  public getAccessToken(): string | undefined {
    const user = this.getUser();
    return user?.token;
  }

  /**
   * Get boolean status if ever user logged in before based on last lastLoggedIn property
   * @param lastLoggedIn
   * @returns Boolean status of wheter user has logged in before or not
   */
  public hasLoggedInBefore(lastLoggedIn?: Date): boolean {
    return !!lastLoggedIn;
  }
}
