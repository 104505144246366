import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { SessionService } from '@core/services';

export const waitingRoomGuard: CanActivateFn = () => {
  const waitingRoom = inject(SessionService).waitingRoom();

  if (!waitingRoom) {
    const queryParams = inject(ActivatedRoute).snapshot.queryParams;
    void inject(Router).navigate(['/auth/connection/waiting-room'], {
      queryParams
    });
  }

  return true;
};
