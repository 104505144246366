<div>
  <h3 class="text-bold">{{ 'testimonial.title' | translate }}</h3>

  <div class="testimonial-wrapper mt-12">
    <div class="image-wrapper">
      <img src="assets/img/naomi-johnson.png" alt="a person face" />
    </div>

    <blockquote>
      <fa-icon icon="quote-left" size="2xl"></fa-icon>

      <p>{{ 'testimonial.quote' | translate }}</p>
      <cite class="mt-8">{{ 'testimonial.author' | translate }}</cite>
    </blockquote>
  </div>
</div>
