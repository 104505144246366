<app-trial-banner></app-trial-banner>
<app-navbar [title]="'navbar.title' | translate" [shadow]="true">
  @if (isNewSignupRoute) {
    <a
      [routerLink]="['/auth/login']"
      appLink
      class="login-link text-md mr-16"
      >{{ 'label.login' | translate }}</a
    >
  }

  @if (isLoggedIn) {
    <button
      appButton="primary"
      class="mr-8 transparent-button text-bold"
      (click)="openOnboarding()">
      <app-label [gap]="0.5" icon="circle-info">
        {{ 'label.product_tutorial' | translate }}
      </app-label>
    </button>
  }
  @if (isConnectionRoute) {
    @if (sessionID()) {
      <button
        appButton="primary"
        class="mr-8 copy-button text-bold"
        (click)="copyCode()">
        <app-label [icon]="copyClicked() ? 'check' : 'link'">{{
          decryptedSessionID()
        }}</app-label>
      </button>
    }

    <app-menu-dropdown>
      <app-language-switch size="md" [stretch]="true"></app-language-switch>

      @if ((sessionService.sessionId$ | async) !== undefined) {
        <button
          [shadow]="true"
          appButton
          [stretch]="true"
          class="mt-4"
          (click)="newSession()">
          <app-label>{{ 'label.connect_new_session' | translate }}</app-label>
        </button>
      }

      @if (isTypeRoute) {
        <button
          class="mt-4"
          id="show-tips-button"
          appButton="moovd-light-blue-50"
          [isDisabled]="(tutorialService.open$ | async) ?? false"
          (click)="showOnboarding()"
          [stretch]="true">
          <fa-icon icon="circle-exclamation"></fa-icon>
          <app-label class="ml-3">{{
            'label.show_tips' | translate
          }}</app-label>
        </button>
      }
      <button
        class="mt-4"
        appButton="primary"
        [stretch]="true"
        (click)="logOut()">
        <app-label icon="right-from-bracket" iconPosition="right" [gap]="0.5">{{
          'label.logout' | translate
        }}</app-label>
      </button>
    </app-menu-dropdown>
  } @else {
    <app-language-switch
      class="mr-4"
      [size]="(mobileService.mobile$ | async) ? 'md' : 'lg'"
      [square]="(mobileService.mobile$ | async) === true" />
  }
</app-navbar>
<main #main>
  <router-outlet></router-outlet>
</main>
