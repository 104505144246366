<app-dialog [fullBorderRadius]="true">
  @if (data.hours) {
    <h2 class="text-bold mb-10">{{ 'license.dialog.expiring' | translate }}</h2>
    <p
      class="description mb-14"
      [innerHTML]="
        'license.dialog.expiring.description'
          | translate: { email: data.email, hours: data.hours }
          | sanitize
      "></p>
  } @else {
    <h2 class="text-bold mb-10">{{ 'license.dialog.expired' | translate }}</h2>
    <p
      class="description mb-14"
      [innerHTML]="
        'license.dialog.expired.description'
          | translate: { email: data.email }
          | sanitize
      "></p>
  }

  <button appButton="primary" [stretch]="true" (click)="close()">
    <app-label>{{ 'label.confirm' | translate }}</app-label>
  </button>
</app-dialog>
