<button
  #button
  appButton
  [appDropdown]="dropdown"
  [offsetY]="8"
  class="flag-button"
  [size]="this.size"
  [stretch]="stretch"
  [shadow]="true"
  [class.square]="square"
  (opened)="onOpened()"
  (closed)="onClosed()">
  @if (currentLanguage | async; as language) {
    <app-flag-icon class="flag" [countryCode]="language.countryCode" />

    @if (!square) {
      <app-label class="label"> {{ language.label | translate }} </app-label>
    }
  }
  @if (!square) {
    <fa-icon icon="chevron-down" class="chevron" [@indicatorRotate]="isOpen" />
  }
</button>

<ng-template #dropdown>
  <app-dropdown>
    @for (language of languages; track language.countryCode) {
      <button appButton [shadow]="true" (click)="useLanguage(language.locale)">
        <app-flag-icon class="flag" [countryCode]="language.countryCode" />
        <app-label>{{ language.label | translate }}</app-label>
      </button>
    }
  </app-dropdown>
</ng-template>
