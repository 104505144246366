import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private googleAnalyticsId = environment.googleAnalyticsId;
  private renderer2: Renderer2;
  private scriptsLoaded = false;

  constructor(
    private rendererFactory2: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {
    this.renderer2 = this.rendererFactory2.createRenderer(null, null);
  }

  public init() {
    if (!this.scriptsLoaded) {
      this.insertMainScript();
    }
  }

  public trackSinglePageView(event: NavigationEnd) {
    if (this.googleAnalyticsId && this.scriptsLoaded) {
      gtag('config', this.googleAnalyticsId, {
        page_path: event.urlAfterRedirects,
        send_page_view: true
      });
    }
  }

  public trackSignUpPageViews() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.trackSinglePageView(event);
      }
    });
  }

  private insertMainScript() {
    if (this.googleAnalyticsId) {
      const script: HTMLScriptElement = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.onload = this.insertSecondHalfOfScript.bind(this);
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsId}`;
      script.text = '';
      this.renderer2.appendChild(this.document.body, script);
    }
  }

  private insertSecondHalfOfScript() {
    const script: HTMLScriptElement = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = '/assets/scripts/google-analytics-script.js';
    script.text = '';
    this.renderer2.appendChild(this.document.body, script);
    script.onload = () => {
      this.scriptsLoaded = true;
    };
  }
}
