import { Device } from './device.model';

export interface Session {
  sessionId: number;
  userId: number;
  deviceId: number;
  device: Device;
  active: boolean;
  time: string;
}

export enum SessionFormFields {
  SessionId = 'sessionId'
}
export interface SessionFormValues {
  [SessionFormFields.SessionId]: string;
}

export interface SessionLogin {
  sessionId: number;
  version?: string;
  platform: string;
  browser?: string;
}
