import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionObject } from 'src/app/core/models/option.model';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlagIconComponent } from '../../flag-icon/flag-icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-option-label',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    FlagIconComponent,
    TranslateModule
  ],
  templateUrl: './option-label.component.html',
  styleUrls: ['./option-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionLabelComponent {
  @Input()
  public option!: OptionObject;
}
