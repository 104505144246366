import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  inject
} from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { DefaultMeetingSession } from 'amazon-chime-sdk-js';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { SelectComponent } from '@shared/components/select';
import { DeviceSelectionService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonDirective } from '@shared/directives';
import { LabelComponent } from '@shared/components/label';

@Component({
  selector: 'app-device-selection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogComponent,
    SelectComponent,
    TranslateModule,
    ButtonDirective,
    LabelComponent
  ],
  templateUrl: './device-selection-dialog.component.html',
  styleUrl: './device-selection-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceSelectionDialogComponent implements OnInit {
  public deviceSelectionService = inject(DeviceSelectionService);
  constructor(
    private dialogRef: DialogRef<boolean, DeviceSelectionDialogComponent>,
    @Inject(DIALOG_DATA) public data: { meetingSession: DefaultMeetingSession }
  ) {}

  public ngOnInit(): void {
    void this.initalize();
  }

  public close(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }

  private async initalize(): Promise<void> {
    await this.data.meetingSession.audioVideo.listAudioInputDevices();
    await this.data.meetingSession.audioVideo.listAudioOutputDevices();
    await this.data.meetingSession.audioVideo.listVideoInputDevices();
    await this.deviceSelectionService.getDevicesOptions();
    this.deviceSelectionService.resetFormControls();
  }
}
