import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, fromEvent } from 'rxjs';
const MOBILE_BREAKPOINT = 992;

@Injectable({
  providedIn: 'root'
})
export class MobileService {
  private mobile!: boolean;
  public readonly mobile$ = new BehaviorSubject(this.mobile);

  private window: Window;

  public get isMobile(): boolean {
    return this.mobile;
  }

  constructor() {
    this.window = document.defaultView as Window;

    this.setMobile();

    fromEvent(window, 'resize')
      .pipe(debounceTime(250))
      .subscribe(() => this.onResize());
  }

  private onResize(): void {
    this.setMobile();
  }

  private setMobile(): void {
    const mobile = this.window.innerWidth < MOBILE_BREAKPOINT;

    if (this.mobile !== mobile) {
      this.mobile = mobile;
      this.mobile$.next(mobile);
    }
  }
}
