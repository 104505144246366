import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-button-group',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button-group.component.html',
  styleUrl: './button-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonGroupComponent implements OnDestroy, OnChanges {
  @Input()
  public control = new FormControl();

  @Input()
  public value?: unknown;

  @Output()
  public valueChanged = new EventEmitter<any>();

  public valueUpdated$ = new Subject();

  private controlSubscription = this.control.valueChanges.subscribe((value) => {
    this.value = value;
    this.valueChanged.emit(value);
    this.valueUpdated();
  });

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      const value = changes['value'].currentValue;
      this.control.setValue(value, { emitEvent: false });
      this.valueUpdated();
    }

    if (changes['control']) {
      if (this.controlSubscription) {
        this.controlSubscription.unsubscribe();
      }
      this.controlSubscription = this.control.valueChanges.subscribe(
        (value) => {
          this.value = value;
          this.valueChanged.emit(value);
          this.valueUpdated();
        }
      );
    }
  }

  public ngOnDestroy(): void {
    this.controlSubscription.unsubscribe();
  }

  private valueUpdated(): void {
    this.valueUpdated$.next(this.value);
  }
}
