@if (option.icon) {
  <fa-icon [icon]="option.icon" [fixedWidth]="true"></fa-icon>
}
@if (option.country) {
  <app-flag-icon [countryCode]="option.country"></app-flag-icon>
}
@if (option.name) {
  <div class="label-wrapper">
    @if (option.translate) {
      <div class="label">{{ option.name | translate }}</div>
    } @else {
      <div class="label">{{ option.name }}</div>
    }
  </div>
}
