import { Validators as NgValidators } from '@angular/forms';
import { emailAvailable } from './email-available.validator';
import { companyAvailable } from './company-available.validator';
import { phoneNumberForCountry } from './phone-number-for-country.validator';
import { matchControlValidator } from './match-control.validator';
import { passwordStrengthValidator } from './password.validator';

export class Validators {
  // Map built-in Angular form validators
  public static minLength = NgValidators.minLength;
  public static maxLength = NgValidators.maxLength;
  public static required = NgValidators.required;
  public static requiredTrue = NgValidators.requiredTrue;
  public static email = NgValidators.email;
  public static min = NgValidators.min;
  public static max = NgValidators.max;
  public static emailAvailable = emailAvailable;
  public static companyAvailable = companyAvailable;
  public static matchControl = matchControlValidator;
  public static passwordStrength = passwordStrengthValidator;
  public static validPhoneNumberForCountry = phoneNumberForCountry;
}
