@if (licenseExpiresIn()) {
  <p
    class="trial_period_banner header"
    class="button"
    (click)="onPaymentClick()"
    (keyup.enter)="onPaymentClick()"
    tabindex="0"
    [innerHTML]="
      'trial.banner.text'
        | translate
          : {
              days: licenseExpiresIn()?.days,
              hours: licenseExpiresIn()?.hours || 0
            }
        | sanitize
    "></p>
}
