import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor() {}

  public saveStringToPdf(content: string) {
    const doc = new jsPDF();
    const date = dayjs(new Date()).format('MM-DD-YYYY');

    this.setConfigAndHeader(doc, date);
    this.addWrappedText(content, doc);

    doc.save(`notes-session_${date}.pdf`);
  }

  public previewPDF(content: string) {
    const doc = new jsPDF();
    const date = dayjs(new Date()).format('MM-DD-YYYY');

    doc.setProperties({
      title: `notes-session_${date}.pdf`
    });
    this.setConfigAndHeader(doc, date);
    this.addWrappedText(content, doc);

    window.open(doc.output('bloburl'));
  }

  private setConfigAndHeader(doc: jsPDF, date: string) {
    doc.setFontSize(11);
    doc.addImage('assets/img/logos/wemind-emdr.png', 'PNG', 15, 10, 50, 20);
    doc.line(0, 40, 300, 40);
    doc.setFont('helvetica', 'bold');
    doc.text('Session Notes', 167, 18);
    doc.setFont('helvetica', 'normal');
    doc.text(date, 174, 24);
  }

  private addWrappedText(
    text: string,
    doc: jsPDF,
    lineSpacing = 7,
    xPosition = 15,
    initialYPosition = 50,
    pageWrapInitialYPosition = 15
  ) {
    const textLines = doc.splitTextToSize(text, 180); // Split the text into lines
    const pageHeight = doc.internal.pageSize.height; // Get page height, well use this for auto-paging
    let cursorY = initialYPosition;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    textLines.forEach((lineText: string) => {
      // add 10 to give padding
      if (cursorY + 10 > pageHeight) {
        // Auto-paging
        doc.addPage();
        cursorY = pageWrapInitialYPosition;
      }
      doc.text(lineText, xPosition, cursorY);
      cursorY += lineSpacing;
    });
  }
}
