<app-dialog (closeClicked)="cancel()">
  <div class="title-icon-container">
    <div class="image-container">
      <app-instruction-image
        [browser]="deviceInfo?.browser"></app-instruction-image>
    </div>
    <div class="text-container">
      <h5 class="text-bold mb-2">
        {{ 'permissions.popup.title' | translate }}
      </h5>
      <ol>
        @if (
          (deviceInfo?.browser === 'Safari' && deviceService.isMobile()) ||
          (deviceInfo?.browser === 'Safari' && deviceService.isTablet())
        ) {
          <li
            class="description"
            [innerHTML]="
              'permissions.popup.list1.safari-mobile' | translate | sanitize
            "></li>
          <li
            class="description"
            [innerHTML]="
              'permissions.popup.list2.safari-mobile' | translate | sanitize
            "></li>
          <li
            class="description"
            [innerHTML]="
              'permissions.popup.list3.safari-mobile' | translate | sanitize
            "></li>
        } @else if (deviceInfo?.browser === 'Safari') {
          <li class="description">
            {{ 'permissions.popup.list1.safari' | translate }}
          </li>
          <li class="description">
            {{ 'permissions.popup.list2.safari' | translate }}
          </li>
        } @else {
          <li class="description">
            {{ 'permissions.popup.list1.part1' | translate }}
            <span class="icon-container"
              ><fa-icon [icon]="icon"></fa-icon
            ></span>
            {{ 'permissions.popup.list1.part2' | translate }}
          </li>
          <li class="description">
            {{ 'permissions.popup.list2' | translate }}
          </li>
        }
      </ol>
    </div>
  </div>
</app-dialog>
