import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Room } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  constructor(private http: HttpClient) {}

  public fetch() {
    return this.http.get<Array<Room>>(environment.baseUrl + 'room');
  }
}
