<main>
  @if (showToggle) {
    <app-toggle-button
      [value]="_paymentIntervalType()"
      (valueChanged)="changeInterval($event)"
      class="mb-12">
      <app-toggle-button-item
        [value]="paymentInterval.monthly"
        class="px-16 py-8">
        <app-label>{{ 'label.monthly' | translate }}</app-label>
      </app-toggle-button-item>
      <app-toggle-button-item
        [value]="paymentInterval.yearly"
        class="px-16 py-8">
        <div class="discount">
          <span class="discount-chip">Save 10%</span>
        </div>
        <app-label>{{ 'label.yearly' | translate }}</app-label>
      </app-toggle-button-item>
    </app-toggle-button>
  }

  <section>
    <!-- essential -->
    <div class="price-card essential">
      <span class="chip" style="visibility: hidden">{{
        'label.recommended' | translate
      }}</span>
      <div
        class="card-header mt-8"
        [class.min-height-header]="showEnterprise && showFreeTrial">
        <h4 class="card-title text-bold">ESSENTIAL</h4>
        <p class="card-desc">
          {{ 'payment.table.essential.description' | translate }}
        </p>
      </div>
      <div
        class="card-pricing mt-16"
        [class.min-height-pricing]="showFreeTrial">
        @if (showFreeTrial) {
          <p>
            <span class="free text-bold essential">
              {{ 'auth.featured-plan.trial' | translate }}
            </span>
          </p>
        }

        <p class="then">
          {{ 'auth.featured-plan.then' | translate }}
        </p>

        @if (isYearly()) {
          <p>
            <span class="actual-price">
              {{
                '$ ' +
                  getActualYearlyPrice(
                    prices[subscriptionType.essential].montly
                  )
              }}
              <div class="cross-line"></div>
            </span>
            <span
              class="final-price text-bold"
              [class.bigger-text-size]="!showFreeTrial">
              {{ '$ ' + prices[subscriptionType.essential].yearly }}
              {{ '/' + ('label.year' | translate) }}
            </span>
          </p>
        } @else {
          <p>
            <span
              class="final-price text-bold"
              [class.bigger-text-size]="!showFreeTrial">
              {{ '$ ' + prices[subscriptionType.essential].montly }}
              {{ '/' + ('label.month' | translate) }}
            </span>
          </p>
        }
      </div>
      <div class="card-button">
        <button
          appButton="primary"
          class="mb-7 mt-8"
          size="lg"
          [stretch]="false"
          (click)="redirectToCheckout(subscriptionType.essential)">
          {{
            showFreeTrial
              ? ('label.start_free_trial' | translate)
              : ('label.purchase_plan' | translate)
          }}
        </button>
      </div>
      <div class="card-includes">
        <p class="text-bold">{{ 'label.includes' | translate }}</p>
        <app-unordered-list
          [listData]="[
            'plan.perform.benefit2' | translate,
            'plan.perform.benefit3' | translate,
            'plan.perform.benefit4' | translate,
            'plan.perform.benefit5' | translate,
            'plan.perform.benefit7' | translate,
            'plan.perform.benefit8' | translate
          ]"
          class="mt-8"></app-unordered-list>
      </div>
    </div>

    <!-- perform -->
    <div class="price-card perform">
      <span class="chip">{{ 'label.recommended' | translate }}</span>
      <div
        class="card-header mt-8"
        [class.min-height-header]="showEnterprise && showFreeTrial">
        <h4 class="card-title text-bold">PERFORM</h4>
        <p class="card-desc">
          {{ 'payment.table.peform.description' | translate }}
        </p>
      </div>
      <div
        class="card-pricing mt-16"
        [class.min-height-pricing]="showFreeTrial">
        @if (showFreeTrial) {
          <p>
            <span class="free text-bold perform">
              {{ 'auth.featured-plan.trial' | translate }}
            </span>
          </p>
        }

        <p class="then perform">
          {{ 'auth.featured-plan.then' | translate }}
        </p>

        @if (isYearly()) {
          <p>
            <span class="actual-price">
              {{
                '$ ' +
                  getActualYearlyPrice(prices[subscriptionType.perform].montly)
              }}

              <div class="cross-line"></div>
            </span>
            <span
              class="final-price text-bold"
              [class.bigger-text-size]="!showFreeTrial">
              {{ '$ ' + prices[subscriptionType.perform].yearly }}
              {{ '/' + ('label.year' | translate) }}
            </span>
          </p>
        } @else {
          <p>
            <span
              class="final-price text-bold"
              [class.bigger-text-size]="!showFreeTrial">
              {{ '$ ' + prices[subscriptionType.perform].montly }}
              {{ '/' + ('label.month' | translate) }}
            </span>
          </p>
        }
      </div>
      <div class="card-button">
        <button
          appButton="primary"
          class="mb-7 mt-8"
          size="lg"
          [stretch]="false"
          (click)="redirectToCheckout(subscriptionType.perform)">
          {{
            showFreeTrial
              ? ('label.start_free_trial' | translate)
              : ('label.purchase_plan' | translate)
          }}
        </button>
      </div>
      <div class="card-includes">
        <p class="text-bold">{{ 'label.includes' | translate }}</p>
        <app-unordered-list
          [listData]="[
            'plan.benefit.all-essential' | translate,
            'plan.perform.benefit8' | translate,
            'plan.perform.benefit9' | translate
          ]"
          class="mt-8"></app-unordered-list>
      </div>
    </div>
  </section>
</main>
