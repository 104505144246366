import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputBaseClass } from '@core/classes';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-toggle-button',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './toggle-button.component.html',
  styleUrl: './toggle-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleButtonComponent
  extends InputBaseClass
  implements OnChanges, OnDestroy
{
  @Input()
  public value?: unknown;

  @Input()
  @HostBinding('class.stretch')
  public stretch = false;

  @Input()
  @HostBinding('class.shrink')
  public shrink = false;

  @Output()
  public valueChanged = new EventEmitter<any>();

  public valueUpdated$ = new Subject();

  private controlSubscription = this.control.valueChanges.subscribe((value) => {
    this.value = value;
    this.valueChanged.emit(value);
    this.valueUpdated();
  });

  public ngOnDestroy(): void {
    this.controlSubscription?.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      const value = changes['value'].currentValue;
      this.control.setValue(value, { emitEvent: false });
    }
    if (changes['control']) {
      if (this.controlSubscription) {
        this.controlSubscription.unsubscribe();
      }
      this.controlSubscription = this.control.valueChanges.subscribe(
        (value) => {
          this.value = value;
          this.valueChanged.emit(value);
          this.valueUpdated();
        }
      );
    }
  }

  public changeValue(value: unknown): void {
    if (this.control.value !== value) {
      this.control.setValue(value);
    }
  }

  private valueUpdated(): void {
    this.valueUpdated$.next(this.value);
  }
}
