import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputBaseClass } from '@core/classes';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Validators } from '@shared/validators/validator.class';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent extends InputBaseClass {
  @Input()
  public inputType = 'text';

  @Input()
  public autocomplete = 'no-autocomplete';

  @Input()
  public min?: string;

  @Input()
  public max?: string;

  @Input()
  public size: 'sm' | 'md' = 'md';

  @Input()
  public preventEnter = false;

  @Input()
  public paddingRight = 1.25;

  @Input()
  public split?: 'left' | 'right';

  public validators = Validators;

  public onPaste(): void {
    this.control.updateValueAndValidity();
  }
}
