import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
  HostBinding
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule],
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input()
  public control = new FormControl<number | null>(null);

  @Input({ required: true })
  public min!: number;

  @Input()
  public minLabel?: string;

  @Input({ required: true })
  public max!: number;

  @Input()
  public maxLabel?: string;

  @Input()
  public multiplier = 1;

  @Input()
  public iconRight?: IconProp;

  @Input()
  public iconLeft?: IconProp;

  @Input()
  public step = 1;

  @Input()
  public value?: number;

  @Input()
  public valueType?: string;

  @Input()
  public valueBackgroundColor?: string;

  @Input()
  public valueColor?: string;

  @Input()
  public disabled = false;

  @Input()
  public showRange = true;

  @Input()
  public thumbType: 'round' | 'square' = 'round';

  @Input()
  public fontSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';

  @Input()
  public showBubble = true;

  @Input()
  public bubbleType: 'bubble' | 'side' | 'xlbubble' = 'bubble';

  @Input()
  public revertValue = false;

  @Input()
  public decimals = 0;

  @Output()
  public valueChanged = new EventEmitter<number>();

  @Output()
  public userChangeValue = new EventEmitter<number>();

  @Output()
  public userChangeStart = new EventEmitter<void>();

  @Output()
  public userChangeEnd = new EventEmitter<void>();

  @HostBinding('class.mt-5') get addMarginTop() {
    return (
      this.hasLabels ||
      (this.thumbType === 'round' &&
        this.showBubble &&
        this.bubbleType === 'bubble')
    );
  }

  @HostBinding('class.mb-5') get addMarginBottom() {
    return (
      (this.showRange && this.showBubble && this.bubbleType === 'bubble') ||
      this.thumbType === 'square'
    );
  }

  @ViewChild('bubble')
  public bubble?: ElementRef<HTMLElement>;

  @ViewChild('minLabelElement')
  public minLabelElement?: ElementRef<HTMLElement>;

  @ViewChild('maxLabelElement')
  public maxLabelElement?: ElementRef<HTMLElement>;

  @ViewChild('range')
  public range?: ElementRef<HTMLInputElement>;

  public get hasLabels(): boolean {
    return !!this.minLabel || !!this.maxLabel;
  }

  private mouseDown = false;

  private subs: Array<Subscription> = [];

  private hasValueChanged = false;

  constructor(private cd: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.cd.detectChanges();
  }
  public ngOnInit(): void {
    this.initialize();
  }

  public ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      let value = changes['value'].currentValue as number;

      if (value > this.max) {
        value = this.max;
      }

      if (value < this.min) {
        value = this.min;
      }

      if (this.revertValue) {
        value = this.getRevertedValue(value);
      }

      if (!this.mouseDown) {
        this.control.setValue(value, { emitEvent: false });
      }
    }

    if (changes['disabled']) {
      if (this.disabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  public showMinLabel(): boolean {
    if (this.thumbType === 'square') {
      return true;
    }
    const minLabelElement = this.minLabelElement?.nativeElement;
    const range = this.range?.nativeElement;
    const thumbPosition = this.getThumbPosition();
    if (minLabelElement && thumbPosition !== undefined && range) {
      const labelWidthPercentage =
        (100 / range.clientWidth) * minLabelElement.clientWidth;

      if (thumbPosition < labelWidthPercentage) {
        return false;
      }
    }
    return !!this.minLabel;
  }

  public showMaxLabel(): boolean {
    if (this.thumbType === 'square') {
      return true;
    }

    const maxLabelElement = this.maxLabelElement?.nativeElement;
    const range = this.range?.nativeElement;
    const thumbPosition = this.getThumbPosition();
    if (maxLabelElement && thumbPosition !== undefined && range) {
      const labelWidthPercentage =
        (100 / range.clientWidth) * maxLabelElement.clientWidth;

      if (thumbPosition > 100 - labelWidthPercentage) {
        return false;
      }
    }
    return !!this.maxLabel;
  }

  public getLeftValue(): string | undefined {
    const thumbPosition = this.getThumbPosition();
    const bubble = this.bubble?.nativeElement;
    if (thumbPosition !== undefined && bubble) {
      return `calc(${thumbPosition}% + (${8 - thumbPosition * 0.16}px))`;
    }
    return;
  }
  public getWidthPercentage(): number {
    return (
      (((this.control.value || 0) - this.min) / (this.max - this.min)) * 100
    );
  }

  public onMouseDown(): void {
    this.userChangeStart.emit();
    this.mouseDown = true;
  }

  public onMouseUp(): void {
    this.userChangeEnd.emit();

    if (this.hasValueChanged) {
      if (this.revertValue) {
        this.userChangeValue.emit(
          this.getRevertedValue(this.control.value as number)
        );
      } else {
        this.userChangeValue.emit(this.control.value as number);
      }
    }

    this.mouseDown = false;
  }

  private initialize(): void {
    this.subs.push(
      this.control.valueChanges.subscribe((value) => {
        this.hasValueChanged = true;
        this.valueChanged.emit(value || 0);
      })
    );
  }

  private getThumbPosition(): number | undefined {
    const range = this.range?.nativeElement;
    if (range) {
      const val = range.value;
      const min = range.min ? range.min : 0;
      const max = range.max ? range.max : 100;
      const newVal = Number(
        ((Number(val) - Number(min)) * 100) / (Number(max) - Number(min))
      );
      return newVal;
    }

    return;
  }

  private getRevertedValue(value: number) {
    return (this.max + this.min) / 2 - value + (this.max + this.min) / 2;
  }

  public getDecimals() {
    return '1.0-' + this.decimals.toString();
  }
}
