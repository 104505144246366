import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  HostListener
} from '@angular/core';
import { Moovd_ENV } from '@core/enums';
import {
  GoogleAnalyticsService,
  LangParamsService,
  DialogService
} from '@core/services';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { Logger } from '@core/classes';
import { I18nService } from '@core/i18n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.key === 'K') {
      this.dialogService
        .openPopup({
          title: this.i18nService.translate('dialog.download.logs.title'),
          description: this.i18nService.translate(
            'dialog.download.logs.description'
          ),
          secondaryLabel: this.i18nService.translate('label.cancel'),
          primaryLabel: this.i18nService.translate('label.download')
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            Logger.exportLogs();
            Logger.resetLogs();
          }
        });
    }
  }

  title = 'emdr-therapist-angular-3.0';
  constructor(
    private googleAnalytics: GoogleAnalyticsService,
    private gtmService: GoogleTagManagerService,
    private langParamsService: LangParamsService,
    private dialogService: DialogService,
    private i18nService: I18nService
  ) {}

  public ngOnInit() {
    if (environment.env === Moovd_ENV.PRODUCTION) {
      this.googleAnalytics.init();
      this.googleAnalytics.trackSignUpPageViews();
      this.gtmService.pushTag({ event: 'landedOnWeMindApp' }).catch(() => {
        Logger.error('failed to push gtmTag');
      });
    }

    this.langParamsService.initialize();
  }
}
