<header *ngIf="filterable">
  <app-input
    [control]="queryControl"
    [preventEnter]="true"
    placeholder="Search..."
    [autofocus]="true"
    class="query-input"
    size="sm"></app-input>
</header>

<main
  appLazyload
  (scrollEvent)="onScroll($event)"
  [dataSource]="filteredOptions"
  #main>
  <ng-container *ngFor="let option of loadedOptions">
    <button
      type="button"
      [title]="option.name"
      class="option"
      (click)="select(option)">
      <app-option-label [option]="option"></app-option-label>
    </button>
  </ng-container>

  <ng-container *ngIf="loadedOptions.length === 0">
    <p class="no-results">No results</p>
  </ng-container>
</main>
