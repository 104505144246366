<button
  #button
  appButton
  [appDropdown]="dropdown"
  [width]="250"
  [offsetY]="8"
  class="account-button"
  appButton
  [shadow]="true"
  [square]="true">
  <fa-icon icon="bars"></fa-icon>
</button>

<ng-template #dropdown>
  <app-dropdown style="display: block" id="menu-dropdown-container">
    <ng-content></ng-content>
  </app-dropdown>
</ng-template>
