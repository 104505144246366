import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { ButtonDirective } from '@shared/directives';
import { LabelComponent } from '@shared/components/label';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-claim-dialog',
  standalone: true,
  imports: [CommonModule, DialogComponent, ButtonDirective, LabelComponent],
  templateUrl: './claim-dialog.component.html',
  styleUrl: './claim-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClaimDialogComponent {
  constructor(
    private dialogRef: DialogRef<boolean, ClaimDialogComponent>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public claim(): void {
    window.open('https://calendly.com/d-schuttenbeld/15min', '_blank');
    this.dialogRef.close(true);
  }

  public close(): void {
    this.dialogRef.close(false);
  }
}
