import { Injectable, signal } from '@angular/core';
import { SessionService } from '../session/session.service';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import {
  Observable,
  catchError,
  from,
  tap,
  throwError,
  BehaviorSubject,
  Subject
} from 'rxjs';
import { SocketEnum } from '@core/enums';
import { SocketMessage, SignalRResponse } from '@core/models';
import { Logger } from '@core/classes';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public reconnecting = signal(false);
  public hasConnection = signal(false);
  public recievedSinkSettings: Array<number> = [];
  private _message$ = new BehaviorSubject<SocketMessage | undefined>(undefined);
  private _componentMessage$ = new Subject<SocketMessage>();

  public message$ = this._message$.asObservable();
  public componentMessage$ = this._componentMessage$.asObservable();

  public ignoreVisualSpeed = false;
  private connection!: signalR.HubConnection;
  constructor(private sessionService: SessionService) {}

  /**
   * Returns the message as a value instead of observable
   */
  public getMessage(): SocketMessage | undefined {
    return this._message$.getValue();
  }

  /**
   * Sets the message subject in the service
   */
  public setMessage(message: SocketMessage): void {
    this._message$.next(message);
  }

  /**
   * Triggers a event that notifies the components that require to be notified about a new possible message
   */
  public sendMessageToComponents(message: SocketMessage): void {
    this._componentMessage$.next(message);
  }

  /**
   * Connects to signalR
   */
  public connect(): Observable<void> {
    const url = environment.baseSignalR;
    Logger.log(`SignalrService.connect - SignalR URL: ${url}`);

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (!this.reconnecting()) {
            this.reconnecting.set(true);
          }
          if (retryContext.elapsedMilliseconds < 20000) {
            if (retryContext.elapsedMilliseconds < 1500) {
              return 250;
            }
            return Math.floor(Math.random() * (1500 - 250 + 1)) + 250;
          } else {
            this.reconnecting.set(false);
            return null;
          }
        }
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.registerOnReconnected();

    return this.startConnection().pipe(
      tap(() => {
        this.sendConnectionMessage();
        this.registerConnectionHandlers();
      }),
      catchError((error) => {
        Logger.error(
          `SignalrService.connect - Failed to connect to SignalR Server URL ${url}`
        );
        Logger.error('SignalrService.connect - Connection error:', error);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return throwError(() => error);
      })
    );
  }

  /**
   * Disconnect the SignalR connection
   */
  public disconnect() {
    if (this.connection) {
      this.reconnecting.set(false);
      this.hasConnection.set(false);
      this.recievedSinkSettings = [];
      this.stopConnection().subscribe();
    } else {
      Logger.log(
        'SignalRService.disconnect - disconnect but connection is undefined'
      );
    }
  }

  /**
   * Sends a SocketMessage to the SignalR
   * @param msg The SocketMessage that needs to be send
   * @param apiOnly If the message should only be send to the API and not also to the components
   */
  public sendMessageToSocket(msg: SocketMessage, apiOnly = false) {
    if (!apiOnly) {
      this.sendMessageToComponents(msg);
    }
    this.sendSocketMessageToAll(msg);
  }

  /**
   * Sends sink settings
   */
  public sendSinkSettings() {
    const sessionId = this.sessionService.getSessionId()?.trim();
    if (sessionId && sessionId !== '0') {
      this.connection.invoke('SinkSetSettings', sessionId).catch((error) => {
        Logger.error(error);
      });
    }
  }

  /**
   * Start the connection based on the this.connection
   * @returns this.connection.start as observable
   */
  private startConnection(): Observable<void> {
    return from(this.connection.start());
  }

  /**
   * Stops the connection based on the this.connection
   * @returns this.connection.stop as observable
   */
  private stopConnection(): Observable<void> {
    return from(this.connection.stop());
  }

  /**
   * Registers the handler onReconnected onto the connection
   */
  private registerOnReconnected(): void {
    this.connection.onreconnected(() => {
      Logger.log(`SignalrService.registerOnReconnected - Reconnect`);
      this.reconnecting.set(false);
      this.sendConnectionMessage();
    });
  }

  /**
   * Register all the needed handlers on connection
   */
  private registerConnectionHandlers(): void {
    this.connection.on('ReceiveJsonFromClient', (data) =>
      this.handleMessage(data)
    );
    this.connection.on('ReceiveJsonFromTherapist', (data) =>
      this.handleMessage(data)
    );
    this.connection.on('ReceiveJsonFromApi', (data) =>
      this.handleMessage(data)
    );
    this.connection.on('sinkSettings', (data) => this.handleSinkSettings(data));
    this.connection.on('AloneInSession', (data) =>
      this.handleAloneInSession(data)
    );
  }

  /**
   * Sends connection message
   */
  private sendConnectionMessage() {
    const sessionId = this.sessionService.getSessionId();
    if (sessionId !== null) {
      void this.connection.invoke('ConnectFrontend', sessionId);
    }
  }

  /**
   * Invokes FromFrontendToAllOthers
   */
  private sendSocketMessageToAll(msg: SocketMessage) {
    const sessionId = this.sessionService.getSessionId();
    if (!this.connection) {
      Logger.log(
        'SignalRService.sendWebsocketMessageToAll not send; connection is undefined'
      );
    } else if (sessionId !== null) {
      this.connection
        .invoke('FromFrontendToAllOthers', sessionId, JSON.stringify(msg))
        .catch((error) => {
          Logger.error('SignalRService.sendWebsocketMessageToAll:', error);
        });
    }
  }

  /**
   * Handles the message that is recieved from a handler
   */
  private handleMessage(data: string): void {
    const message: SocketMessage = JSON.parse(data);
    if (message.name === SocketEnum.ARRAY) {
      this.handleMessageArray(JSON.parse(message.value));
    } else {
      message.value_opt3 = 'msg';
      this._message$.next(message);
    }
  }

  /**
   * Handles a array of socketmessages
   */
  private handleMessageArray(messages: Array<SocketMessage>): void {
    messages.forEach((msg) => this.handleMessage(JSON.stringify(msg)));
  }

  /**
   * Handles the SinkSettings handler event
   */
  private handleSinkSettings(data: string): void {
    const message = this.getSocketMessage(JSON.parse(data) as SignalRResponse);

    if (!this.recievedSinkSettings.includes(message.name)) {
      this.recievedSinkSettings.push(message.name);
      message.value_opt3 = 'jobject';
      this.setMessage(message);
    }
  }

  /**
   * Handles the AloneInSession handler event
   */
  private handleAloneInSession(data: string): void {
    const sessionId = this.sessionService.getSessionId();
    const message: SocketMessage = {
      sessionId,
      name: SocketEnum.EXCEPTION,
      value: data
    };
    this.setMessage(message);
  }

  /**
   * Returns a SocketMessage based on the SignalRResponse
   */
  private getSocketMessage(response: SignalRResponse): SocketMessage {
    const sessionId = this.sessionService.getSessionId();
    return {
      name: this.getSocketEnum(response.Name),
      sessionId,
      value: response.Value
    };
  }

  /**
   * Returns a SocketEnum based on the provided name
   */
  private getSocketEnum(name: string): SocketEnum {
    return (
      Object.keys(SocketEnum).indexOf(name) - Object.keys(SocketEnum).length / 2
    );
  }
}
