import { BuildEnum } from './build.enum';

export interface Device {
  deviceId: number;
  companyId: number;
  deviceUniqueIdentifier: string;
  deviceName: string;
}

export interface DeviceInfo {
  Platform: BuildEnum;
  BuildVersion: string;
}
