<label [for]="id">
  <input
    [class]="value"
    type="radio"
    [name]="prefix"
    (change)="onChanged($event.target)"
    [checked]="isChecked"
    [value]="value"
    [id]="id" />
</label>
