import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface ThreadMessageResponse {
  aiMessage: string;
  aiAudioMessage: string;
  userMessage: string;
}
@Injectable({
  providedIn: 'root'
})
export class AiService {
  constructor(private http: HttpClient) {}

  public startConversation(userId: string) {
    return this.http.get(
      environment.baseUrl + 'AI/StartConversation/' + userId,
      { responseType: 'text' }
    );
  }

  public endConversation(
    userId: string,
    threadId: string
  ): Observable<boolean> {
    return this.http.get<boolean>(
      environment.baseUrl + `AI/EndConversation/${userId}/${threadId}`
    );
  }

  public sendThreadMessage(
    threadId: string,
    audioBlob: Blob,
    aiAssistant: number
  ): Observable<ThreadMessageResponse> {
    const audioFile = new File([audioBlob], 'audio.mp3', {
      type: 'audio/mp3'
    });

    const formData: FormData = new FormData();
    formData.append('voiceMessage', audioFile);

    return this.http.post<ThreadMessageResponse>(
      environment.baseUrl +
        `AI/SendThreadMessage?threadId=${threadId}&aiAssistant=${aiAssistant}&language=en`,
      formData
    );
  }
}
