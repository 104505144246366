import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioInputComponent } from '../radio-input/radio-input.component';
import { RadioOptionBase } from '../radio-option-base.class';

@Component({
  selector: 'app-radio-option',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './radio-option.component.html',
  styleUrls: ['./radio-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioOptionComponent extends RadioOptionBase {
  constructor(cd: ChangeDetectorRef, host?: RadioInputComponent) {
    super(cd, host);
  }
}
