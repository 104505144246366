import { Injectable } from '@angular/core';
import Peer, { MediaConnection, PeerJSOption, DataConnection } from 'peerjs';
import {
  BehaviorSubject,
  Observable,
  Subscriber,
  Subscription,
  of,
  timer
} from 'rxjs';
import { GetIceServers } from 'src/app/shared/functions';
import { SignalRService } from '../signalr/signalr.service';
import { PeerCoordinatorService } from '../peer-coordinator/peer-coordinator.service';
import { Logger } from '@core/classes';
import { SocketEnum } from '@core/enums';
import { FileDataTransfer2Service } from '../file-data-transfer2/file-data-transfer2.service';

const mediaConstraints: MediaStreamConstraints = {
  audio: true,
  video: {
    frameRate: 15,
    width: 640,
    height: 480
  }
};

const peerJsOptions: PeerJSOption = {
  debug: 1,
  host: 'peertje2.moovd.nl',
  secure: true,
  config: {
    iceServers: GetIceServers()
  }
};

export interface PeerConnection {
  recieverPeerID?: string;
  connection?: DataConnection;
  mediaCall?: MediaConnection;
}

@Injectable({
  providedIn: 'root'
})
export class Peer2Service {
  public remoteStream$ = new BehaviorSubject<MediaStream | undefined>(
    undefined
  );
  public localStream$ = new BehaviorSubject<MediaStream | undefined>(undefined);
  public dataConnection$ = new BehaviorSubject<DataConnection | undefined>(
    undefined
  );
  public mediaConnection$ = new BehaviorSubject<MediaConnection | undefined>(
    undefined
  );

  private isDestroyingPeer = false;

  private timeout?: Subscription;

  private peerConnection$?: Observable<DataConnection>;
  private peerConnectionSubscriber?: Subscriber<DataConnection>;

  constructor(
    private signalRService: SignalRService,
    private fileDataTransferService: FileDataTransfer2Service,
    private peerCoordinatorService: PeerCoordinatorService
  ) {
    this.peerCoordinatorService.destroyPeer$.subscribe(() => {
      this.destroyPeer(true);
    });
  }

  public createRecieverPeerID(id: string): string {
    return 'R_' + id;
  }

  /**
   * Requests the media devices from the user
   */
  public async requestMediaDevices(videoId?: string, audioId?: string) {
    Logger.log('WebrtcService.requestMediaDevices - init');
    try {
      const constraints =
        videoId && audioId
          ? {
              ...mediaConstraints,
              ...{
                video: {
                  deviceId: { exact: videoId }
                },
                audio: {
                  deviceId: { exact: audioId }
                }
              }
            }
          : mediaConstraints;

      if (!navigator.mediaDevices) {
        throw new Error('No media devices');
      }
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.localStream$.next(stream);
    } catch (error) {
      Logger.error('Error accessing user media:', error);
      throw error;
    }
  }

  /**
   * Creates a new peer
   * @param id ID for own Peer so that others can connect with this peer by this ID
   * @param setEvents If peer events should be set/handled
   * @returns Returns the created peer
   */
  public createPeer(id: string, setEvents = false) {
    Logger.log('PeerService.createPeer - init - id:', id);
    if (
      this.peerCoordinatorService.peer &&
      this.peerCoordinatorService.peer.id !== id
    ) {
      this.destroyPeer();
    }

    if (!this.peerCoordinatorService.peer) {
      try {
        this.peerCoordinatorService.peer = new Peer(id, peerJsOptions);
        if (setEvents) {
          this.peerCoordinatorService.peer.on(
            'connection',
            this.handleDataConnection
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.peerCoordinatorService.peer.on('call', this.handlePeerOnCall);

        return this.peerCoordinatorService.peer;
      } catch (error) {
        Logger.error('PeerService.createPeer - something went wrong', error);
        throw error;
      }
    } else {
      return this.peerCoordinatorService.peer;
    }
  }

  /**
   * Initalizes a peer connection with provided ID
   * @param id ID for the peer connection
   * @returns Returns observable that will be triggered when a dataconnection has been established
   */
  public peerConnection(id: string) {
    Logger.log('PeerService.peerConnection - init - id:', id);

    try {
      this.createPeer(id);
      const dataConnection = this.dataConnection$.value;

      if (this.peerConnection$) {
        return this.peerConnection$;
      } else {
        if (dataConnection) {
          return of(dataConnection);
        } else {
          this.peerConnection$ = new Observable<DataConnection>(
            (subscriber) => {
              this.peerConnectionSubscriber = subscriber;
            }
          );
          this.signalRService.sendMessageToSocket({
            name: SocketEnum.PEER_OFFER,
            value: id
          });
          this.createTimeOut();
          return this.peerConnection$;
        }
      }
    } catch (error) {
      Logger.error(
        'PeerService.initalizePeerConnection - something went wrong',
        error
      );
      this.destroyPeer(true);
      return new Observable<DataConnection>((subscriber) => {
        subscriber.error(error);
        subscriber.complete();
      });
    }
  }

  /**
   * Handles a incoming peer offer, and creates a peer
   * @param id The id of the incoming peer offer
   */
  public handlePeerOffer(id: string): void {
    Logger.log('PeerService.handlePeerOffer - init - id:', id);

    const ownID = this.createRecieverPeerID(id);
    try {
      this.createPeer(ownID, true);

      this.signalRService.sendMessageToSocket({
        name: SocketEnum.PEER_ANWSER,
        value: ownID
      });
    } catch (error) {
      Logger.error('PeerService.handlePeerOffer - something went wrong', error);
      this.destroyPeer(true);
    }
  }

  /**
   * Handles a peer anwser, and creates a data connection with the provided recieverID
   * @param recieverID
   */
  public handlePeerAnwser(recieverID: string): void {
    Logger.log('PeerService.HandlePeerAnwser - init - recieverID:', recieverID);
    this.destroyTimeOut();

    if (this.peerCoordinatorService.peer) {
      const currentConnection = this.dataConnection$.value;
      if (!currentConnection) {
        const connection = this.peerCoordinatorService.peer.connect(
          recieverID,
          {}
        );
        this.handleDataConnection(connection);
        connection?.on('open', () => {
          this.peerConnectionSubscriber?.next(connection);
          this.complete();
        });
      } else {
        Logger.log(
          'PeerService.HandlePeerAnwser - A data connection already exist'
        );
      }
    }
  }

  /**
   * Calls the connected peer NOTE: Only works when there is a peer connected
   * @param stream
   */
  public callPeer(stream: MediaStream): void {
    const dataConnection = this.dataConnection$.value;
    if (
      this.peerCoordinatorService.peer &&
      dataConnection &&
      !this.peerCoordinatorService.peer?.disconnected
    ) {
      const connection = this.peerCoordinatorService.peer.call(
        dataConnection.peer,
        stream
      );
      this.handleMediaConnection(connection);
    }
  }

  /**
   * Destroys the peer and closes all data connections
   * @param full If everything needs to be reset to default state
   */
  public destroyPeer(full?: boolean): void {
    if (!this.isDestroyingPeer) {
      Logger.log('PeerService.destroyPeer - Destroying peer');
      this.isDestroyingPeer = true;
      if (full) {
        this.peerCoordinatorService.peerUsage = {
          file: true,
          streaming: false
        };
      }

      this.complete();
      this.closeMediaConnection();
      this.fileDataTransferService.resetAll();
      this.destroyTimeOut();

      this.peerCoordinatorService.peer?.disconnect();
      this.peerCoordinatorService.peer?.destroy();
      this.peerCoordinatorService.peer = undefined;

      this.dataConnection$.next(undefined);

      this.isDestroyingPeer = false;
    }
  }

  /**
   * If the dataconnection closes you want to reset everything to default state
   * Because dataconnection is the main connection between the peers
   */
  private handleDataConnectionOnClose = () => {
    Logger.log('PeerService.handleDataConnectionOnClose - Connection closed');
    this.destroyPeer(true);
  };

  /**
   * Handles error events from a DataConnection
   * @param error
   */
  private handleDataConnectionOnError = (error: unknown) => {
    Logger.error('PeerService.handlePeerAnwser - something went wrong', error);
    this.destroyPeer(true);
  };

  /**
   * Sets events handles on the provided DataConnection
   * @param connection
   */
  private setDataConnectionEvents(connection: DataConnection): void {
    Logger.log('PeerService.setDataConnectionEvents - Init');
    connection?.on('error', this.handleDataConnectionOnError);
    connection?.on('close', this.handleDataConnectionOnClose);
    connection?.on('data', (data) => {
      this.fileDataTransferService.handleDataConnectionOnData(data);
    });
  }

  /**
   * Handles a DataConnection
   * @param connection
   */
  private handleDataConnection = (connection: DataConnection) => {
    Logger.log('PeerService.handleDataConnection - init');
    this.setDataConnectionEvents(connection);
    this.dataConnection$.next(connection);
  };

  /**
   * Handles the onCall event from the Peer
   * @param connection
   */
  private handlePeerOnCall = async (connection: MediaConnection) => {
    Logger.log('PeerService.handlePeerOnCall - init');
    this.handleMediaConnection(connection);
    try {
      await this.requestMediaDevices();

      const stream = this.localStream$.value as MediaStream;
      connection.answer(stream);
    } catch (error: unknown) {
      Logger.error(
        'PeerService.handlePeerOnCall - something went wrong',
        error
      );
      this.mediaConnectionError(connection);
    }
  };

  /**
   * Closes the active MediaConnection, if none is active then remove the streams only
   */
  public closeMediaConnection(): void {
    Logger.log('PeerService.closeMediaConnection - init');
    const connection = this.mediaConnection$.value;

    if (connection) {
      Logger.log('PeerService.closeMediaConnection - Closing media connection');

      if (!connection.open) {
        this.handleMediaConnectionOnClose(); // Manually trigger on close because if there is no active, connection events dont get triggered
      }

      connection.close();
    } else {
      // this.removeMediaStreams();
      this.handleMediaConnectionOnClose();
    }
  }

  /**
   * Handles the MediaConnection on stream event and sets the remotestream
   * @param stream
   */
  private handleMediaConnectionOnStream = (stream: MediaStream) => {
    this.remoteStream$.next(stream);
  };

  /**
   * Handles error event from the MediaConnection
   * @param error
   */
  private handleMediaConnectionOnError = (error: unknown) => {
    Logger.error(
      'PeerService.handleMediaConnectionOnError - something went wrong',
      error
    );
    this.mediaConnectionError(this.mediaConnection$.value);
  };

  /**
   * Removes all active streams and stop them
   */
  public removeMediaStreams(): void {
    Logger.log('PeerService.removeMediaStreams - Init');
    this.remoteStream$.value?.getTracks().forEach((track) => {
      track.stop();
    });
    this.localStream$.value?.getTracks().forEach((track) => {
      track.stop();
    });

    this.remoteStream$.next(undefined);
    this.localStream$.next(undefined);
  }

  /**
   * Handles the on Close event from a MediaConnection
   */
  private handleMediaConnectionOnClose = () => {
    Logger.log('PeerService.handleMediaConnectionOnClose - Init');
    this.removeMediaStreams();
    this.mediaConnection$.next(undefined);
    this.peerCoordinatorService.peerUsage.streaming = false;

    if (!this.peerCoordinatorService.peerUsage.file) {
      this.destroyPeer(true);
    }
  };

  /**
   * Handles a new MediaConnection
   * @param connection
   */
  private handleMediaConnection = (connection: MediaConnection) => {
    Logger.log('PeerService.handleMediaConnection - init');
    this.peerCoordinatorService.peerUsage.streaming = true;
    connection.on('stream', this.handleMediaConnectionOnStream);
    connection.on('close', this.handleMediaConnectionOnClose);
    connection.on('error', this.handleMediaConnectionOnError);
    this.mediaConnection$.next(connection);
  };

  /**
   * Used when a error occurs with the MediaConnection and needs to decide if the peer needs to be destroyed
   * @param connection
   */
  private mediaConnectionError(connection?: MediaConnection): void {
    if (!this.peerCoordinatorService.peerUsage.file) {
      this.destroyPeer(true);
    } else {
      connection?.close();
    }
  }

  /**
   * Creates a timeout that takes x amount of seconds, if timer is reached it destroys the peer
   */
  private createTimeOut(): void {
    Logger.log('PeerService.createTimeOut - init');
    const time = 60000;
    this.timeout = timer(time).subscribe(() => {
      if (!this.dataConnection$.value) {
        Logger.error('Timeout: The request has been timed out');

        if (this.peerConnectionSubscriber) {
          this.peerConnectionSubscriber.error(
            new Error('The connection has timed out')
          );
        }

        this.destroyPeer(true);
      }
    });
  }

  /**
   * Destroys the timeout for the peer
   */
  private destroyTimeOut(): void {
    if (this.timeout) {
      Logger.log('PeerService.destroyTimeOut - init');
      this.timeout?.unsubscribe();
      this.timeout = undefined;
    }
  }

  /**
   * Completes the previous peer data connection subscriber and makes it undefined if there is one
   */
  private complete(): void {
    Logger.log('PeerService.complete - Complete peerConnectionSubscriber');
    this.peerConnectionSubscriber?.complete();
    this.peerConnectionSubscriber = undefined;
    this.peerConnection$ = undefined;
  }
}
