import { ChangeDetectorRef, Directive, Input, OnDestroy } from '@angular/core';
import { RadioInputComponent } from './radio-input/radio-input.component';
import { Subscription } from 'rxjs';

@Directive()
export abstract class RadioOptionBase implements OnDestroy {
  @Input({ required: true })
  public value!: unknown;

  public prefix!: string;

  public get isChecked(): boolean {
    return this.value === this.host?.control.value;
  }

  public get id(): string {
    return this.prefix + '-' + (this.value as string);
  }

  private _valueUpdated?: Subscription;

  constructor(
    protected cd: ChangeDetectorRef,
    protected host?: RadioInputComponent
  ) {
    this.prefix = host?.prefix || 'radio';

    this._valueUpdated = this.host?.valueUpdated$.subscribe(() => {
      cd.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this._valueUpdated?.unsubscribe();
  }

  public onChanged(target: EventTarget | null): void {
    if (!target) {
      return;
    }

    this.host?.control.setValue(this.value);
  }
}
