import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

export const phoneNumberForCountry = (country: CountryCode): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const error = { isValid: false };
    const value = control.value as string;

    if (!value) {
      return null;
    }

    if (value.length <= 2 || !country) {
      return error;
    }

    try {
      const phoneNumber = parsePhoneNumber(value.toString(), country);

      if (!phoneNumber || !phoneNumber.isValid()) {
        return error;
      }

      return null;
    } catch (e) {
      return error;
    }
  };
};
