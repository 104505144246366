<app-input
  [inputType]="visible() ? 'text' : 'password'"
  [control]="control"
  [autofocus]="autofocus"
  [paddingRight]="2.5"
  [placeholder]="placeholder"
  class="password-input" />

<fa-icon
  suffix
  (click)="toggleVisibility()"
  (keydown.enter)="toggleVisibility()"
  tabindex="0"
  [icon]="visible() ? 'eye-slash' : 'eye'"></fa-icon>
