/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Injectable, computed, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Logger } from '@core/classes';
import { OptionObject } from '@core/models/option.model';
import { Validators } from '@shared/validators/validator.class';

@Injectable({
  providedIn: 'root'
})
export class DeviceSelectionService {
  public readonly backgroundBlur = signal(false);

  public inputOptions = signal(
    {} as Record<MediaDeviceKind, MediaDeviceInfo[]>
  );

  public audioInputOptions = computed(() => {
    const inputOptions = this.inputOptions();

    if (inputOptions.audioinput) {
      return inputOptions.audioinput.map((o) =>
        this.mediaDeviceInfoToOptionObject(o)
      );
    }
    return;
  });

  public audioOutputOptions = computed(() => {
    const inputOptions = this.inputOptions();

    if (inputOptions.audiooutput) {
      return inputOptions.audiooutput.map((o) =>
        this.mediaDeviceInfoToOptionObject(o)
      );
    }
    return;
  });

  public videoInputOptions = computed(() => {
    const inputOptions = this.inputOptions();

    if (inputOptions.videoinput) {
      return inputOptions.videoinput.map((o) =>
        this.mediaDeviceInfoToOptionObject(o)
      );
    }
    return;
  });

  public audioInputControl = new FormControl<string | null>(null, [
    Validators.required
  ]);
  public videoInputControl = new FormControl<string | null>(null, [
    Validators.required
  ]);
  public audioOutputControl = new FormControl<string | null>(null, [
    Validators.required
  ]);

  public resetFormControls(): void {
    this.audioInputControl.setValue(
      this.inputOptions().audioinput?.[0]?.deviceId,
      {
        emitEvent: false
      }
    );

    this.videoInputControl.setValue(
      this.inputOptions().videoinput?.[0]?.deviceId,
      {
        emitEvent: false
      }
    );

    this.audioOutputControl.setValue(
      this.inputOptions().audiooutput?.[0]?.deviceId,
      {
        emitEvent: false
      }
    );
  }
  public attachSinkId(
    element: any,
    sinkId: string | null,
    prevValue: string | null
  ) {
    if (typeof element.sinkId !== 'undefined') {
      element
        .setSinkId(sinkId)
        .then(() => {
          console.log(`Success, audio output device attached: ${sinkId}`);
        })
        .catch((error: any) => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          Logger.error(errorMessage);
          // Jump back to previous output device in the list as it's the default.
          this.audioOutputControl.setValue(prevValue);
        });
    } else {
      console.warn('Browser does not support output device selection.');
    }
  }

  public resetInputOptions(): void {
    this.inputOptions.set({} as Record<MediaDeviceKind, MediaDeviceInfo[]>);
  }

  public async getDevicesOptions(): Promise<void> {
    this.resetInputOptions();
    let inputOptions = {} as Record<MediaDeviceKind, MediaDeviceInfo[]>;
    // Get all available devices and store it inside DeviceSelectionService
    await navigator.mediaDevices.enumerateDevices().then((devices) => {
      devices.forEach((device) => {
        if (inputOptions[device.kind]) {
          inputOptions = {
            ...inputOptions,
            [device.kind]: [...inputOptions[device.kind], device]
          };
        } else {
          inputOptions[device.kind] = [device];
        }
      });
    });
    this.inputOptions.set(inputOptions);
  }

  private mediaDeviceInfoToOptionObject(media: MediaDeviceInfo): OptionObject {
    const option: OptionObject = {
      name: media.label,
      value: media.deviceId,
      translate: true
    };

    if (option.name === '') {
      option.name = 'label.permissions.needed';
    }
    return option;
  }
}
