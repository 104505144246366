import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-radio-input',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioInputComponent implements OnDestroy, OnChanges {
  @Input()
  public control = new FormControl();

  @Input({ required: true })
  public prefix!: string;

  @Input()
  public value?: any;

  @HostBinding('class.grid')
  @Input()
  public grid = false;

  @Input()
  @HostBinding('style.gap.rem')
  public gap = 0.875;

  @Output()
  public valueChanged = new EventEmitter<any>();

  public valueUpdated$ = new Subject();

  private controlSubscription = this.control.valueChanges.subscribe((value) => {
    this.value = value;
    this.valueChanged.emit(value);
    this.valueUpdated();
  });

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      const value = changes['value'].currentValue;
      this.control.setValue(value, { emitEvent: false });
      this.valueUpdated();
    }
  }

  public ngOnDestroy(): void {
    this.controlSubscription.unsubscribe();
  }

  private valueUpdated(): void {
    this.valueUpdated$.next(this.value);
  }
}
