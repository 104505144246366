import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonDirective,
  DropdownComponent,
  DropdownDirective
} from '@shared/directives';
import { LabelComponent } from '../label';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LanguageSwitchComponent } from '../language-switch/language-switch.component';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-menu-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    DropdownDirective,
    LabelComponent,
    DropdownComponent,
    LanguageSwitchComponent,
    TranslateModule,
    FontAwesomeModule
  ],
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuDropdownComponent implements OnInit {
  @ViewChild(DropdownDirective)
  public dropdown?: DropdownDirective;

  constructor(private router: Router) {}

  public ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.dropdown?.close();
      }
    });
  }
}
