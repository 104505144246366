import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { Observable, of, switchMap, timer } from 'rxjs';
import { AuthService } from 'src/app/core/services';

export const companyAvailable = (
  authService: AuthService
): AsyncValidatorFn => {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return timer(500).pipe(
      switchMap(() =>
        authService.checkIfCompanyExists(control.value as string)
      ),
      switchMap((result) => {
        if (result === true || control.pristine) {
          return of(null);
        }

        control.markAsTouched();
        return of({ companyAvailable: 'this company is unavailable' });
      })
    );
  };
};
