<app-dialog
  [disableClose]="false"
  (closeClicked)="close()"
  [fullBorderRadius]="true">
  <div *ngIf="hasSpecialTips()">
    <p>Roy Kiessling tips have been enabled</p>
    <button
      appButton="primary"
      [stretch]="true"
      *ngIf="hasSpecialTips()"
      (click)="disableSpecialTips()">
      Disable
    </button>
  </div>
  <div *ngIf="!hasSpecialTips()">
    <app-form-field [label]="'label.tips.code' | translate">
      <app-input
        [control]="formControl"
        [placeholder]="'placeholder.enter_tips_code' | translate"
        class="mb-5" />
      <button
        appButton="primary"
        [stretch]="true"
        (click)="setSpecialTips(formControl.value || '')">
        Submit
      </button>
    </app-form-field>
  </div>
</app-dialog>
