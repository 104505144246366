import { SocketEnum } from '../enums';

export interface SocketMessage {
  name: SocketEnum;
  sessionId?: string;
  value: string;
  value_opt1?: string;
  value_opt2?: string;
  value_opt3?: string;
}

export interface SignalRResponse {
  Name: string;
  Value: string;
}
