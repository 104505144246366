import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ExposureCategory, ExposureVideo } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class ExposureService {
  constructor(private http: HttpClient) {}

  public fetchAllCategoriesWithVideos() {
    return this.http.get<ExposureCategory[]>(
      environment.exposureUrl + 'Category/GetAllCategoriesWithVideos'
    );
  }

  public fetchVideos() {
    return this.http.get<Array<ExposureVideo>>(
      environment.exposureUrl + 'Video/Published'
    );
  }
}
