import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@angular/cdk/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DialogComponent } from '../dialog/dialog.component';
import { LabelComponent } from '@shared/components/label';
import { ButtonDirective } from '@shared/directives';
import { InstructionImageComponent } from './components/instruction-image/instruction-image.component';
import { faSliders, faLock } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslateModule } from '@ngx-translate/core';
import { SanitizePipe } from '@shared/pipes';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    DialogComponent,
    LabelComponent,
    ButtonDirective,
    InstructionImageComponent,
    SanitizePipe,
    TranslateModule
  ],
  templateUrl: './permission-instruction-dialog.component.html',
  styleUrls: ['./permission-instruction-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionInstructionDialogComponent implements OnInit {
  protected faSliders = faSliders;
  protected faLock = faLock;
  protected deviceInfo?: DeviceInfo;

  protected get icon(): IconProp {
    switch (this.deviceInfo?.browser) {
      case 'Chrome':
        return this.faSliders;
      case 'Firefox':
        return this.faSliders;
      default:
        return this.faLock;
    }
  }

  constructor(
    private dialogRef: DialogRef<boolean, PermissionInstructionDialogComponent>,
    public deviceService: DeviceDetectorService
  ) {}

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public ngOnInit(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }
}
