import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-label',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelComponent {
  @Input()
  public icon?: IconProp;

  @Input()
  public iconPosition: 'left' | 'right' = 'left';

  @Input()
  public iconOnly = false;

  @Input()
  public underline = false;

  @Input()
  public spinning = false;

  @Input()
  public loading = false;

  @Input()
  @HostBinding('style.gap.rem')
  public gap = 0.25;
}
