/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ToggleButtonComponent } from '../toggle-button/toggle-button.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toggle-button-item',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './toggle-button-item.component.html',
  styleUrl: './toggle-button-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleButtonItemComponent implements OnDestroy {
  @Input()
  @HostBinding('class.is-ellipsis')
  isEllipsis: boolean = false;

  @Input({ required: true })
  public value!: unknown;

  @HostBinding('class.active')
  public get isChecked(): boolean {
    return this.value === this.host?.control.value;
  }

  private _valueUpdated?: Subscription;

  constructor(
    cd: ChangeDetectorRef,
    private host?: ToggleButtonComponent
  ) {
    this._valueUpdated = this.host?.valueUpdated$.subscribe(() =>
      cd.markForCheck()
    );
  }

  public ngOnDestroy(): void {
    this._valueUpdated?.unsubscribe();
  }

  @HostListener('click', ['$event'])
  @HostListener('keyup.enter', ['$event'])
  public onChanged(event: any): void {
    event.stopPropagation();
    if (this.isChecked) {
      return;
    }
    this.host?.control.setValue(this.value);
  }
}
