/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { I18nService } from '@core/i18n';
import { I18nLocale } from '@core/i18n/enums/i18n.enum';

@Injectable({
  providedIn: 'root'
})
export class FreshdeskService {
  private readonly widgetIDENG = 80000009396;
  private readonly widgetIDNL = 80000007679;
  private containerId = 'freshdesk-container';
  constructor(
    private i18nService: I18nService, // Replace with the actual path to your I18nService
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public addFreshdeskToPage(_renderer2: Renderer2) {
    // Check if the container already exists; if not, create it
    let container = this._document.getElementById(this.containerId);
    if (!container) {
      container = _renderer2.createElement('div') as HTMLElement;
      container.id = this.containerId;
      _renderer2.appendChild(this._document.body, container);
    }

    const widgetID =
      this.i18nService.getCurrentLocale() === I18nLocale.nl_NL
        ? this.widgetIDNL
        : this.widgetIDENG;

    // Create and append the Freshdesk script to the container div
    const script = _renderer2.createElement('script');
    script.type = 'text/javascript';
    script.text =
      `
        {
          window.fwSettings={
            'widget_id':` +
      widgetID +
      `
          };
          !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
        }
      `;

    const script2 = _renderer2.createElement('script');
    script2.type = 'text/javascript';
    script2.src =
      'https://euc-widget.freshworks.com/widgets/' + widgetID + '.js';
    script2.async = true;
    script2.defer = true;

    _renderer2.appendChild(container, script);
    _renderer2.appendChild(container, script2);
  }

  public removeFreshdeskFromPage() {
    const container = this._document.getElementById(this.containerId);
    if (container?.parentNode) {
      // Remove the container and its content
      container.parentNode.removeChild(container);
    }
  }
}
