import { Injectable, signal } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressLoadingService {
  private progress = signal(0);
  public error = signal(false);
  public progressSub: Subscription | undefined;

  constructor() {}

  public addProgress() {
    this.progressSub = interval(1200).subscribe(() => {
      const maxNum = 7;
      const minNum = 1;
      const randomNum = Math.floor(Math.random() * (maxNum - minNum) + minNum);
      const progressNum =
        this.progress() + randomNum < 90 ? this.progress() + randomNum : 90;

      if (this.progress() >= 90) {
        this.progressSub?.unsubscribe();
      }

      this.progress.set(progressNum);
    });
  }

  public getProgress() {
    return this.progress();
  }

  public setProgressToFull() {
    this.progress.set(100);
    this.progressSub?.unsubscribe();
  }

  public setError() {
    this.error.set(true);
    this.progressSub?.unsubscribe();
  }

  public reset() {
    this.progress.set(0);
    this.error.set(false);
  }
}
