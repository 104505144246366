import { CanActivateFn, Router } from '@angular/router';
import { SessionService } from '../services';
import { inject } from '@angular/core';

export const hasSessionIdGuard: CanActivateFn = () => {
  const sessionId = inject(SessionService).getSessionId();

  if (!sessionId) {
    void inject(Router).navigate(['/auth/connection'], {
      queryParamsHandling: 'merge'
    });
  }

  return true;
};
