import { Injectable } from '@angular/core';
import { DataConnection } from 'peerjs';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { PeerCoordinatorService } from '../peer-coordinator/peer-coordinator.service';
import { SignalRService } from '../signalr/signalr.service';
import { Logger } from '@core/classes';
import { SocketEnum } from '@core/enums';

export interface FileInfo {
  file?: ArrayBuffer;
  fileName: string;
  fileSize: number;
  fileType: string;
}

export interface FileDataTransferError {
  abort: boolean;
  message: string;
}
@Injectable({
  providedIn: 'root'
})
export class FileDataTransfer2Service {
  public isBusy = new BehaviorSubject<boolean>(false);

  public file$ = new Subject<File>();
  public error$ = new Subject<FileDataTransferError | undefined>();
  public aborted$ = new Subject<void>();
  public onData$ = new Subject<void>();
  public offset$ = new BehaviorSubject<number>(0);

  private connection?: DataConnection;

  public file?: File;
  private fileReader?: FileReader;

  private chunckSize = 14000;

  private recievedBuffer: Array<ArrayBuffer> = [];
  private recievedSize = 0;
  private recievedFileInfo?: FileInfo;

  private subs: Array<Subscription> = [];

  constructor(
    private peerCoordinatorService: PeerCoordinatorService,
    private signalRService: SignalRService
  ) {}

  /**
   * When a component is initalized and uses the service it needs to call this function onInit
   */
  public initalize(): void {
    Logger.log('FileDataTransfer2Service.initalize - init');
    this.subs.push(
      this.error$.subscribe((error) => {
        if (error && error.abort) {
          Logger.error('Error happend, ABORT!');
          this.abort();
        }
      }),
      this.signalRService.message$.subscribe((msg) => {
        switch (msg?.name) {
          case SocketEnum.PEER_FILE_ABORT:
            this.handleOnAbort();
            break;
          case SocketEnum.PEER_FILE_COMPLETED:
            this.handleOnComplete();
            break;
          default:
            break;
        }
      })
    );
  }

  /**
   * When a component is destroyed and uses the service it needs to call this function onDestroy
   */
  public destroy(): void {
    Logger.log('FileDataTransfer2Service.destroy - init');
    this.resetAll();
    this.subs.forEach((s) => s.unsubscribe());
  }

  public resetAll(): void {
    this.isBusy.next(false);
    this.peerCoordinatorService.peerUsage.file = false;
    this.reset();
    this.resetFileReader();
  }

  /**
   * Sends a file via the connection
   * @param file
   * @param connection
   */
  public sendFile(file: File, connection: DataConnection): void {
    Logger.log('FileDataTransfer2Service.sendFile - init');
    this.resetError();
    if (!this.isBusy.value) {
      this.reset();
      this.peerCoordinatorService.peerUsage.file = true;
      this.logFile(file);

      if (file.size === 0) {
        this.onFileEmpty();
        return;
      }

      this.isBusy.next(true);
      this.setFileReader();
      this.file = file;
      this.connection = connection;
      this.readSlice(0, this.file);
    } else {
      const error = 'A file is already being transferred';
      Logger.log(`FileDataTransfer2Service.sendFile - ${error}`);
      this.error$.next({ abort: false, message: error });
    }
  }

  /**
   * Handles the data event from the PeerService which is connected to the connection
   */
  public handleDataConnectionOnData(data: unknown): void {
    this.resetError();
    try {
      const fileInfo = data as FileInfo;

      if (
        fileInfo.file &&
        fileInfo.fileName &&
        fileInfo.fileType &&
        fileInfo.fileSize
      ) {
        if (!this.isBusy.value) {
          this.isBusy.next(true);
          this.peerCoordinatorService.peerUsage.file = true;
          this.onData$.next();
          this.reset();
        }

        if (!this.recievedFileInfo) {
          this.recievedFileInfo = fileInfo;
        }

        this.recievedBuffer.push(fileInfo.file);
        this.recievedSize += fileInfo.file.byteLength;

        if (this.recievedSize === this.recievedFileInfo.fileSize) {
          this.createFile(
            this.recievedBuffer,
            fileInfo.fileName,
            fileInfo.fileType
          );

          this.fileTransferComplete();
        }
      }
    } catch (error) {
      const err = 'Something when wront with handleing the file';
      Logger.error(`FileDataTransfer2Service.sendFile - ${error as string}`);
      this.error$.next({ abort: true, message: err });
    }
  }

  public abort(): void {
    Logger.log('FileDataTransfer2Service.abort - init');
    if (this.fileReader) {
      this.fileReader.onload = null;
      this.fileReader?.abort();
    } else {
      this.handleOnAbort();
    }
  }

  /**
   * Method that is used when all the file chunks are recieved, which then combines the chunks to create a file
   */
  private createFile(
    buffer: Array<ArrayBuffer>,
    name: string,
    type: string
  ): void {
    Logger.log('FileDataTransfer2Service.createFile - init - creating file');

    const file = new File(buffer, name, {
      type: type
    });
    this.file$.next(file);
    this.logFile(file);
  }

  /**
   * Resets error variable
   */
  private resetError(): void {
    this.error$.next(undefined);
  }

  /**
   * Resets the variables
   */
  private reset(): void {
    Logger.log('FileDataTransfer2Service.reset - init');
    this.connection = undefined;
    this.file = undefined;
    this.offset$.next(0);

    this.recievedBuffer = [];
    this.recievedSize = 0;
    this.recievedFileInfo = undefined;
  }

  /**
   * Logs the file information
   * @param file
   */
  private logFile(file: File): void {
    Logger.log(
      `FileDataTransfer2Service.logFile - File is ${[
        file.name,
        file.size,
        file.type,
        file.lastModified
      ].join(' ')}`
    );
  }

  /**
   * When a file is empty
   */
  private onFileEmpty(): void {
    const error = 'File is empty, please select a non-empty file';
    Logger.log(error);
    this.error$.next({ abort: true, message: error });
  }

  /**
   * Generic method that is used for abort events
   */
  private handleOnAbort(): void {
    Logger.log('FileDataTransfer2Service.handleOnAbort - init');
    this.aborted$.next();
    this.cleanUp();
  }

  /**
   * Generic method that is used for abort events
   */
  private handleOnComplete(): void {
    Logger.log('FileDataTransfer2Service.handleOnComplete - init');
    this.cleanUp();
  }

  private cleanUp(): void {
    if (
      this.connection?.open &&
      this.peerCoordinatorService.peer &&
      !this.peerCoordinatorService.peerUsage.streaming
    ) {
      this.peerCoordinatorService.destroyPeer(); // Destroy peer triggers resetAll()
    } else {
      this.resetAll();
    }
  }

  /**
   * Triggered when the recieving peer has recieved everything
   */
  private fileTransferComplete(): void {
    Logger.log('FileDataTransfer2Service.fileTransferComplete - init');
    this.signalRService.sendMessageToSocket({
      name: SocketEnum.PEER_FILE_COMPLETED,
      value: 'true'
    });
    this.handleOnComplete();
  }

  // FILE READER RELATED

  /**
   * Resets the FileReader to undefined state
   */
  private resetFileReader(): void {
    Logger.log('FileDataTransfer2Service.resetFileReader - init');
    if (this.fileReader) {
      this.fileReader.onload = null;
      this.fileReader.onerror = null;
      this.fileReader.onabort = null;
      this.fileReader.onprogress = null;
      this.fileReader.abort();
    }

    this.fileReader = undefined;
  }

  /**
   * Handles FileReader onAbort
   */
  private handleFileReaderOnAbort(): void {
    Logger.log('FileDataTransfer2Service.handleFileReaderOnAbort - init');
    this.signalRService.sendMessageToSocket({
      name: SocketEnum.PEER_FILE_ABORT,
      value: 'true'
    });
    this.handleOnAbort();
  }

  /**
   * Slices a part of the file and then calls the method readAsArrayBuffer from the FileReader, which then sends it to the connection
   * @param offset The offset of the slice
   * @param file The file it needs to slice
   */
  private readSlice(offset: number, file: File): void {
    Logger.log('FileDataTransfer2Service.readslice - ', offset);
    const slice = file.slice(offset, offset + this.chunckSize);
    this.fileReader?.readAsArrayBuffer(slice);
  }

  /**
   * Sets the FileReader and its events
   */
  private setFileReader(): void {
    Logger.log('FileDataTransfer2Service.setFileReader - init');
    if (this.fileReader) {
      this.resetFileReader();
    }

    this.fileReader = new FileReader();

    this.fileReader.onerror = (e) => {
      Logger.error(
        'FileDataTransfer2Service.FileReader.onError - Error reading file:',
        e
      );
      this.error$.next({ abort: true, message: 'Error reading the file' });
    };
    this.fileReader.onabort = () => {
      this.handleFileReaderOnAbort();
    };

    this.fileReader.onload = (e) => {
      Logger.log('FileDataTransfer2Service.FileReader.onLoad - ', e);
      if (this.connection && this.connection.open && this.file) {
        if (e.target?.result) {
          if (e.target?.result) {
            const data: FileInfo = {
              file: e.target.result as ArrayBuffer,
              fileName: this.file.name,
              fileType: this.file.type,
              fileSize: this.file.size
            };

            void this.connection.send(data);

            this.offset$.next(
              this.offset$.value + (e.target.result as any).byteLength
            );
            if (this.offset$.value < this.file.size) {
              this.readSlice(this.offset$.value, this.file);
            } else {
              Logger.log(
                'FileDataTransfer2Service.FileReader.onLoad - transfer completed'
              );
            }
          }
        } else {
          this.error$.next({
            abort: true,
            message: 'No file result has been found'
          });
        }
      } else {
        this.error$.next({
          abort: true,
          message: 'No connection or file has been found!'
        });
      }
    };
  }
  // END FILE READER RELATED
}
