import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioOptionBase } from '../radio-option-base.class';
import { RadioInputComponent } from '../radio-input/radio-input.component';

export const radioColors = [
  'silver',
  'magenta',
  'blue',
  'green',
  'red',
  'white',
  'yellow',
  'random'
] as const;
export type RadioColors = (typeof radioColors)[number];

@Component({
  selector: 'app-radio-option-color',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './radio-option-color.component.html',
  styleUrls: ['./radio-option-color.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioOptionColorComponent extends RadioOptionBase {
  @Input({ required: true })
  public override value!: RadioColors;

  constructor(cd: ChangeDetectorRef, host?: RadioInputComponent) {
    super(cd, host);
  }
}
