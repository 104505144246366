import { AbstractControl, ValidatorFn } from '@angular/forms';

export const matchControlValidator = (
  controlName: string,
  nestedFormGroupName: string = ''
): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // Get the reference to the target control
    const targetControl = nestedFormGroupName
      ? control.root.get(nestedFormGroupName)?.get(controlName)
      : control.root.get(controlName);

    // Check if both controls exist and have the same value
    if (targetControl && control.value !== targetControl.value) {
      return { matchControl: { valid: false } };
    }

    // If the values match or the target control doesn't exist, return null (valid)
    return null;
  };
};
