import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  computed,
  signal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputBaseClass } from 'src/app/core/classes';
import { OptionObject } from 'src/app/core/models/option.model';
import { Subscription } from 'rxjs';
import {
  ButtonDirective,
  DropdownComponent,
  DropdownDirective
} from 'src/app/shared/directives';
import { SelectPanelComponent } from '../select-panel/select-panel.component';
import { LabelComponent, OptionLabelComponent } from '../../label';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { selectAnimation } from '../select-animation';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    OptionLabelComponent,
    SelectPanelComponent,
    DropdownDirective,
    DropdownComponent,
    LabelComponent,
    FontAwesomeModule
  ],
  templateUrl: './select.component.html',
  animations: [selectAnimation.chevronRotate],
  styleUrls: ['./select.component.scss']
})
export class SelectComponent
  extends InputBaseClass
  implements OnInit, OnDestroy, AfterContentInit
{
  @Input()
  public options?: Array<OptionObject>;

  @Input()
  public filterable = false;

  @Input()
  public filterPlaceholder = '';

  @Input()
  public filterThreshold = 7;

  @Input()
  public variant: 'default' | 'rounded' = 'default';

  @Input()
  public size: 'md' | 'sm' = 'md';

  @ViewChild(DropdownDirective)
  public panel?: DropdownDirective;

  @ViewChild(SelectPanelComponent)
  public selectPanel?: SelectPanelComponent;

  @HostBinding('class')
  public get classes(): Array<string> {
    return [this.variant, this.size];
  }

  public width = signal<number>(0);
  public panelOpen = signal(false);

  public panelState = computed(() => {
    return this.panelOpen() ? 'opened' : 'closed';
  });

  public get selectedOption(): OptionObject | undefined {
    return this.options?.find((o) => o.value === this.control.value);
  }

  private subs: Array<Subscription> = [];

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  public ngAfterContentInit(): void {
    this.cd.markForCheck();

    const div = document.getElementById('container');
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line prefer-const
      for (let entry of entries) {
        this.width.set(entry.contentRect.width);
      }
    });

    resizeObserver.observe(div as HTMLElement);
  }

  public ngOnInit(): void {
    this.subs.push(
      this.control.valueChanges.subscribe(() => {
        this.cd.markForCheck();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public onPanelOpened(): void {
    this.panelOpen.set(true);
  }

  public panelClosed(): void {
    this.panelOpen.set(false);
  }

  public onSelect(): void {
    this.panelOpen.set(false);
    this.panel?.close();
  }
}
