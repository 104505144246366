import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  hasActiveLicenseGuard,
  isAuthenticatedGuard,
  testingGuard
} from './core/guards';
import { AuthLayoutComponent } from './shared/layout';

const routes: Routes = [
  {
    path: 'test',
    canActivate: [testingGuard],
    loadChildren: () =>
      import('./feature/test/test.module').then((m) => m.TestModule)
  },
  {
    path: 'pricing-overview',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./feature/pricing/pricing.routes').then((r) => r.PRICING_ROUTES)
  },
  {
    path: 'offer',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./feature/offer/offer.routes').then((r) => r.OFFER_ROUTES)
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./feature/auth/auth.routes').then((r) => r.AUTH_ROUTES)
  },
  {
    path: 'session',
    canActivate: [isAuthenticatedGuard, hasActiveLicenseGuard],
    loadChildren: () =>
      import('./feature/session/session.routes').then((r) => r.SESSION_ROUTES)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth'
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
