import { License } from '@core/services/license/license.service';
import { Gender } from './gender.model';

export interface User {
  id: string;
  active: boolean;
  company?: string;
  companyId: number;
  emailAddress: string;
  gender?: Gender;
  genderId?: number;
  instertTime: string;
  onboardingUsers: Array<string>;
  password?: string;
  ssoEnabled: boolean;
  token: string;
  userRoles: Array<Role>;
  licenses: Array<License>;
  username: string;
}

export interface Role {
  name: UserRole;
}

export enum UserRole {
  PATIENT = 'PATIENT',
  ANONYMOUS_PATIENT = 'ANONYMOUS_PATIENT',
  RESEARCHER = 'RESEARCHER',
  THERAPIST = 'THERAPIST',
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPERADMIN',
  DEVELOPER = 'DEVELOPER',
  DEMO = 'DEMO',
  BRONS = 'BRONS',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  API = 'API',
  VRET_THERAPIST = 'VRET_THERAPIST',
  EXPOSURE_THERAPIST = 'EXPOSURE_THERAPIST'
}
