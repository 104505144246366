import { Injectable } from '@angular/core';
import { Logger } from '@core/classes';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';

export interface Target {
  id: number;
  name: string;
  suds: Array<Sud>;
  vocs: Array<Voc>;
}

export interface Sud {
  value: number;
  time: string;
}

export interface Voc {
  value: number;
  time: string;
}

@Injectable({
  providedIn: 'root'
})
export class TargetService {
  private _targets = new BehaviorSubject<Array<Target>>([]);

  public targets$ = this._targets.asObservable();

  public get targets(): Array<Target> {
    return this._targets.getValue();
  }

  public setTargets(value: Array<Target>): void {
    this._targets.next(value);
  }

  public reset(): void {
    this._targets.next([]);
  }

  public addTarget(value: Target): void {
    const targets = cloneDeep(this.targets);
    const targetIndex = targets.findIndex((s) => s.id === value.id);
    if (targetIndex === -1) {
      targets.push(value);
      this._targets.next(targets);
    } else {
      Logger.error(
        'TargetService.addTarget: Target already exists in the list'
      );
    }
  }

  public updateTarget(value: Target): void {
    const targets = cloneDeep(this.targets);
    const targetIndex = targets.findIndex((s) => s.id === value.id);

    if (targetIndex !== -1) {
      targets[targetIndex] = value;
      this._targets.next(targets);
    } else {
      this.addTarget(value);
    }
  }
}
