<ul>
  @for (listItem of listData; track $index) {
    <li>
      <fa-icon icon="check"></fa-icon>
      <span>
        {{ listItem }}
      </span>
    </li>
  }
</ul>
