import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogRef } from '@angular/cdk/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProgressBarComponent } from '@shared/components/progress-bar/progress-bar.component';
import { Subscription } from 'rxjs';

import { TranslateModule } from '@ngx-translate/core';
import { ProgressLoadingService } from '@core/services';

@Component({
  selector: 'app-progress-signup',
  standalone: true,
  imports: [
    CommonModule,
    DialogComponent,
    ProgressBarComponent,
    FontAwesomeModule,
    TranslateModule
  ],
  templateUrl: './progress-signup.component.html',
  styleUrls: ['./progress-signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressSignupComponent implements OnInit, OnDestroy {
  private progressSub: Subscription | undefined;

  constructor(
    private dialogRef: DialogRef<boolean, ProgressSignupComponent>,
    protected progressService: ProgressLoadingService
  ) {}

  public ngOnDestroy(): void {
    this.progressService.reset();
  }

  public ngOnInit(): void {
    this.progressService.addProgress();
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
}
