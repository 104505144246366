/* eslint-disable @typescript-eslint/naming-convention */
export type Company = {
  id: number;
  name: string;
  enableSSOLogin: boolean;
};

export enum CompanyStatus {
  PROSPECT = 1,
  CUSTOMER = 2,
  DEACTIVE = 3,
  LEAD = 4,
  SUSPECT = 5,
  HOTPROSPECT = 6,
  DEMO = 7,
  NO_CUSTOMER = 8,
  ACTIVE = 9
}
