import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { User } from '@core/models';
import { AuthService, DialogService } from '@core/services';
import { LicenseService } from '@core/services';
import { Observable, map, tap } from 'rxjs';
import { LicenseExpiresDialogComponent } from 'src/app/feature/auth/components/license-expires-dialog/license-expires-dialog.component';

export const hasActiveLicenseGuard: CanActivateFn = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const user = inject(AuthService).getUser();

  const router = inject(Router);
  const licenseService = inject(LicenseService);
  const dialogService = inject(DialogService);

  if (!user) {
    void router.navigate(['/'], { queryParamsHandling: 'merge' });
  }

  return licenseService.checkLicenseNotExpired(user as User).pipe(
    map((licenseResult) => {
      return licenseResult.active;
    }),
    tap((hasActiveLicense) => {
      if (!hasActiveLicense) {
        dialogService
          .open(
            LicenseExpiresDialogComponent,
            { email: user?.emailAddress, hours: 0 },
            false,
            true
          )
          .subscribe();
        void router.navigate(['/pricing-overview']);
      }
    })
  );
};
