import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SocketMessage } from '../models';
import { SignalRService } from '../services';

@Directive()
export abstract class SessionClientBase implements OnDestroy {
  protected subs: Array<Subscription> = [];

  protected abstract onSocketMessage(msg: SocketMessage): void;

  constructor(protected signalRService: SignalRService) {
    this.subscribeToComponentMessage();
  }

  public ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  protected subscribeToComponentMessage(): void {
    this.subs.push(
      this.signalRService.componentMessage$.subscribe((msg) => {
        this.onSocketMessage(msg);
      })
    );
  }
}
