import { environment } from 'src/environments/environment';
import { Moovd_ENV } from '../enums';
import { CanActivateFn } from '@angular/router';

export const testingGuard: CanActivateFn = () => {
  return (
    environment.env === Moovd_ENV.LOCAL ||
    environment.env === Moovd_ENV.DEVELOPMENT
  );
};
