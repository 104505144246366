import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { ButtonGroupComponent } from '../button-group/button-group.component';

@Component({
  selector: 'app-button-group-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button-group-item.component.html',
  styleUrl: './button-group-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonGroupItemComponent implements OnDestroy {
  @Input({ required: true })
  public value!: unknown;

  @Input()
  @HostBinding('attr.tabindex')
  public tabIndex = 0;

  @HostBinding('class.active')
  public get isChecked(): boolean {
    return this.value === this.host?.control.value;
  }

  private _valueUpdated?: Subscription;

  constructor(
    protected cd: ChangeDetectorRef,
    protected host?: ButtonGroupComponent
  ) {
    this._valueUpdated = this.host?.valueUpdated$.subscribe(() =>
      cd.markForCheck()
    );
  }

  public ngOnDestroy(): void {
    this._valueUpdated?.unsubscribe();
  }

  @HostListener('click')
  @HostListener('keyup.enter')
  public onClick(): void {
    if (this.host?.control.value !== this.value) {
      this.host?.control.setValue(this.value);
    }
  }
}
