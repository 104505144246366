<app-toggle-button
  (valueChanged)="onValueChanged()"
  [value]="value"
  [control]="control">
  <app-toggle-button-item [value]="true">{{
    onLabel ? onLabel : ('label.on' | translate)
  }}</app-toggle-button-item>
  <app-toggle-button-item [value]="false">{{
    offLabel ? offLabel : ('label.off' | translate)
  }}</app-toggle-button-item>
</app-toggle-button>
