import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { CompanyStatus } from '@core/models/company.model';

import dayjs from 'dayjs';
import { Subscription, filter, forkJoin } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { SanitizePipe } from '@shared/pipes';
import {
  License,
  LicenseService,
  AuthService,
  CompanyService
} from '@core/services';

interface TrialLicenseObject {
  days: number;
  hours: number;
}
@Component({
  selector: 'app-trial-banner',
  standalone: true,
  imports: [CommonModule, RouterLink, TranslateModule, SanitizePipe],
  templateUrl: './trial-banner.component.html',
  styleUrls: ['./trial-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrialBannerComponent implements OnInit, OnDestroy {
  public emdrLicense?: License;
  public licenseExpiresIn = signal<TrialLicenseObject | undefined>(undefined);
  public showBanner = signal<boolean | undefined>(false);

  private subs: Array<Subscription> = [];
  constructor(
    private licenseService: LicenseService,
    public auth: AuthService,
    private companyService: CompanyService,
    private router: Router
  ) {}

  public ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public ngOnInit(): void {
    this.checkIfDemoLicense();

    this.subs.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.checkIfDemoLicense();
        })
    );

    this.auth.onLogout().subscribe(() => {
      // Perform cleanup on logout
      this.companyService.clearCachedStatus(); // Clear cached status from CompanyService
    });
  }

  public onPaymentClick() {
    sessionStorage.removeItem('license');

    window.addEventListener(
      'focus',
      () => {
        if (!sessionStorage.getItem('license')) {
          this.checkIfDemoLicense();
        }
      },
      false
    );

    void this.router.navigate(['/pricing-overview']);
    // window.open(
    //   `${environment.stripeLink}?prefilled_email=${user.emailAddress}&client_reference_id=${user.emailAddress}`,
    //   '_blank'
    // );
  }

  private checkIfDemoLicense() {
    const user = this.auth.getUser();
    const userEmail = user?.username ?? user?.emailAddress;

    if (userEmail) {
      forkJoin([
        this.licenseService.getActiveLicenses(user),
        this.companyService.getCompanyStatusByEmail(userEmail)
      ]).subscribe((val) => {
        const licenseList = val[0];
        const status = val[1];

        this.emdrLicense = licenseList.find(
          (l) => l.applicationName.toLowerCase() === 'emdr'
        );

        if (this.emdrLicense && status === CompanyStatus.DEMO) {
          const endDate = this.emdrLicense.expiresAt;

          if (this.isTrialLicense() && this.isCorrectRoute()) {
            const today = dayjs();
            const licenseEnd = dayjs(endDate);

            const licenseStartEndDiff = licenseEnd.diff(today, 'day', true);
            const days = Math.floor(licenseStartEndDiff);
            const hours = Math.floor((licenseStartEndDiff - days) * 24);

            if (days > 0) {
              this.licenseExpiresIn.set({
                days: days,
                hours: hours
              });
            } else {
              this.licenseExpiresIn.set({
                days: 0,
                hours: hours
              });
            }
          } else {
            this.licenseExpiresIn.set(undefined);
          }
        } else {
          this.licenseExpiresIn.set(undefined);
        }
      });
    } else {
      this.licenseExpiresIn.set(undefined);
    }
  }

  private isCorrectRoute(): boolean {
    return this.router.url.includes('auth/connection');
  }

  private isTrialLicense() {
    const formattedStartDate = dayjs(this.emdrLicense?.insertTime);
    const formattedEndDate = dayjs(this.emdrLicense?.expiresAt);
    const licenseStartEndDiff = formattedEndDate.diff(
      formattedStartDate,
      'day',
      true
    );
    const days = Math.floor(licenseStartEndDiff);

    // For now because we have no way of fetching the company yet,

    if (days < 50) {
      return true;
    } else {
      return false;
    }
  }
}
