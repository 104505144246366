import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogRef } from '@angular/cdk/dialog';
import { LabelComponent } from '@shared/components/label';
import { ButtonDirective } from '@shared/directives';
import { Router } from '@angular/router';
import { SessionService } from '@core/services';
import { LoaderComponent } from '@shared/components/loading';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-connection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogComponent,
    LabelComponent,
    ButtonDirective,
    LoaderComponent,
    TranslateModule
  ],
  templateUrl: './connection-dialog.component.html',
  styleUrl: './connection-dialog.component.scss'
})
export class ConnectionDialogComponent {
  public loading = signal(false);
  constructor(
    private dialogRef: DialogRef<boolean, ConnectionDialogComponent>,
    private router: Router,
    private sessionService: SessionService
  ) {}

  protected confirm(): void {
    this.loading.set(true);
    this.sessionService.login().subscribe({
      next: () => {
        this.dialogRef.close();
      },
      error: () => {
        this.loading.set(false);
      }
    });
  }

  protected cancel(): void {
    this.dialogRef.close();
    void this.router.navigate(['/auth/connection']);
  }
}
