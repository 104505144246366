import {
  getSupportedInputTypes,
  Platform,
  supportsPassiveEventListeners,
  supportsScrollBehavior
} from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DetectBrowserService {
  public supportedInputTypes = Array.from(getSupportedInputTypes()).join(', ');
  public supportsPassiveEventListeners = supportsPassiveEventListeners();
  public supportsScrollBehavior = supportsScrollBehavior();

  constructor(public platform: Platform) {}

  public getBrowser() {
    if (this.platform.ANDROID) {
      return 'ANDROID';
    } else if (this.platform.BLINK) {
      return 'BLINK';
    } else if (this.platform.EDGE) {
      return 'EDGE';
    } else if (this.platform.FIREFOX) {
      return 'FIREFOX';
    } else if (this.platform.IOS) {
      return 'ISO';
    } else if (this.platform.SAFARI) {
      return 'SAFARI';
    } else if (this.platform.TRIDENT) {
      return 'TRIDENT';
    } else if (this.platform.WEBKIT) {
      return 'WEBKIT';
    } else if (this.platform.isBrowser) {
      return 'IsBROWSER';
    } else {
      return 'BrowserNotFound';
    }
  }
}
