import { Injectable } from '@angular/core';
import { countries } from './data/countries-data.geo';
import { Country } from '@core/models/country.model';

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  public getCountries(): Array<Country> {
    return countries;
  }

  public getCountryByCode(code: string): Country | undefined {
    return this.getCountries().find((c) => c.code === code);
  }
}
