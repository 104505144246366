/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { DeviceInfoOS, ConnectionStatus, SessionStorageKey } from '@core/enums';
import { SessionLogin } from '@core/models';
import { DeviceInfo } from 'ngx-device-detector';
import { DetectBrowserService } from '@core/services';

const sessionIdEncryptor = 364;
@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public deviceInfo?: DeviceInfo;
  public deviceInfoOS?: DeviceInfoOS;
  public visualSpeedChange$ = new Subject<number>();
  public waitingRoom = signal(false);
  public statusConnection = new BehaviorSubject(ConnectionStatus.Connecting);

  private _sessionId$ = new BehaviorSubject<string | undefined>(
    this.getSessionIdFromSessionStorage()
  );

  private _disclaimerAgreed$ = new BehaviorSubject<boolean | undefined>(false);

  public sessionId$ = this._sessionId$.asObservable();
  public disclaimerAgreed$ = this._disclaimerAgreed$.asObservable();

  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private platform: DetectBrowserService
  ) {}

  public isIOS(): boolean {
    const bool = this.deviceInfoOS?.name.toLowerCase().includes('ios');

    return bool !== undefined ? bool : false;
  }

  /**
   * Checks if the given session id is valid
   */
  public checkSessionId(id: string): Observable<boolean> {
    return this.http.get<boolean>(environment.baseUrl + 'Session/check/' + id);
  }

  /**
   * Sets the session id in the session storage
   */
  public setSessionId(id: string): void {
    this._sessionId$.next(id);
    this.sessionStorageService.set(SessionStorageKey.SessionId, id);
  }

  public setDisclaimerAgreed(agreed: boolean): void {
    this._disclaimerAgreed$.next(agreed);
  }

  public registerSession(): Observable<any> {
    const body = {};
    return this.http.post(environment.baseUrl + 'session/device/', body);
  }

  /**
   * Registers a sud to the back-end
   */
  public registerSUD(target: number, sud: number) {
    return this.http.post(
      environment.baseUrl +
        'SessionData/sud/' +
        this.getSessionId() +
        '/' +
        target +
        '/' +
        sud,
      {}
    );
  }

  /**
   * Clears the sessionId from the storage and from the BehaviourSubject
   */
  public clearSessiondId(): void {
    this._sessionId$.next(undefined);
    this.sessionStorageService.remove(SessionStorageKey.SessionId);
    this.waitingRoom.set(false);
  }

  /**
   * Returns the stored session id
   */
  public getSessionId(): string | undefined {
    return this._sessionId$.getValue();
  }

  public getDisclaimerAgreed(): boolean | undefined {
    return this._disclaimerAgreed$.getValue();
  }

  /**
   * Get the session id from the session storage
   */
  public getSessionIdFromSessionStorage(): string | undefined {
    return this.sessionStorageService.get(SessionStorageKey.SessionId);
  }

  /**
   * Encrypt the session id that has been filled in
   */
  public encryptSessionId(id: string): string {
    return (Number(id) / sessionIdEncryptor).toString();
  }

  /**
   * Encrypt the session id that has been filled in
   */
  public decryptSessionId(id?: string): string {
    return (Number(id) * sessionIdEncryptor).toString();
  }

  /**
   * Login to the session
   */
  public login(): Observable<boolean> {
    const data: SessionLogin = {
      sessionId: Number(this.getSessionId()),
      platform: 'FRONTEND',
      version: environment.version,
      browser: this.platform.getBrowser()
    };

    const body = JSON.stringify(data);
    return this.http.put<boolean>(environment.baseUrl + 'Session/login', body);
  }

  /**
   * Logs out the session
   */
  public logout(): Observable<boolean> {
    return this.http.get<boolean>(
      environment.baseUrl + 'Session/logout/' + this.getSessionId()
    );
  }

  /**
   * Resumes the session
   */
  public resume(): Observable<object> {
    return this.http.put(
      environment.baseUrl + 'Session/resumeSession/' + this.getSessionId(),
      {}
    );
  }

  /**
   * End the session
   */
  public end(): Observable<object> {
    return this.http
      .put(
        environment.baseUrl + 'Session/endSession/' + this.getSessionId(),
        {}
      )
      .pipe(
        tap(() => {
          this.clearSessiondId();
        })
      );
  }

  public getSessionInviteURL(): string {
    const sessionId = this.decryptSessionId(this.getSessionId() as string);
    return `${environment.clientDesktopUrl}?sessionId=${sessionId}`;
  }
}
