import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@core/models';
import dayjs from 'dayjs';
import { Observable, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface License {
  id: string;
  applicationId: string;
  applicationName: string;
  insertTime: string;
  deleteTime: string;
  expiresAt?: string;
  sessionLimit?: number;
}

export interface LicenseResult {
  active: boolean;
  license?: License;
}

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  constructor(private http: HttpClient) {}
  public license?: License;

  /**
   * Method for checking if a license is expired
   */
  public checkLicenseNotExpired(user: User): Observable<LicenseResult> {
    return this.getActiveLicenses(user).pipe(
      map((licenses) => {
        let licenseIsActive: { active: boolean; license?: any } = {
          active: false
        };

        licenses.forEach((l) => {
          console.log('License', l);
          if (
            l.applicationName.toLowerCase() === 'emdr' &&
            licenseIsActive.active === false
          ) {
            if (dayjs().isBefore(dayjs(l.expiresAt)) || l.expiresAt === null) {
              console.log('license end still in future');
              licenseIsActive = { active: true, license: l };
            } else {
              console.log('license end is past');
              licenseIsActive = { active: false, license: l };
            }
          }
        });

        console.log('Final license', licenseIsActive);

        return licenseIsActive;
      })
    );
  }

  public getActiveLicenses(user: User | undefined): Observable<License[]> {
    if (user?.licenses) {
      return of(user.licenses);
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.get<License[]>(
      environment.identityApiUrl + 'License/' + user?.username,
      {
        headers
      }
    );
  }
}
