export const changeColorNameToHexValue = (colorName: string) => {
  switch (colorName) {
    case 'SILVER': {
      return '#c0c0c0';
    }
    case 'MAGENTA': {
      return '#ff00ff';
    }
    case 'BLUE': {
      return '#0000ff';
    }
    case 'GREEN': {
      return '#00ff00';
    }
    case 'RED': {
      return '#ff0000';
    }
    case 'WHITE': {
      return '#ffffff';
    }
    case 'YELLOW': {
      return '#ffff00';
    }
    case 'RANDOM': {
      return 'RANDOM';
    }
    case 'FULLRANDOM': {
      return 'FULLRANDOM';
    }
    case '0': {
      return '#c0c0c0';
    }
    case '1': {
      return '#ff00ff';
    }
    case '2': {
      return '#0000ff';
    }
    case '3': {
      return '#00ff00';
    }
    case '4': {
      return '#ff0000';
    }
    case '5': {
      return '#ffffff';
    }
    case '6': {
      return '#ffff00';
    }
    case '7': {
      return 'RANDOM';
    }
    case '8': {
      return 'FULLRANDOM';
    }
    default:
      return '#c0c0c0';
  }
};
