import {
  ChangeDetectionStrategy,
  Component,
  computed,
  signal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonDirective } from '@shared/directives';
import { TranslateModule } from '@ngx-translate/core';
import { RadioInputComponent, RadioOptionComponent } from '@shared/components';
import { environment } from 'src/environments/environment';
import { Logger } from '@core/classes';
import {
  PaymentInterval,
  SubscriptionType
} from 'src/app/feature/auth/components/new-pricing-table/new-pricing-table.component';

@Component({
  selector: 'app-plans-comparation',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    RadioInputComponent,
    RadioOptionComponent,
    TranslateModule,
    ButtonDirective
  ],
  templateUrl: './plans-comparation.component.html',
  styleUrls: ['./plans-comparation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlansComparationComponent {
  public paymentIntervals = PaymentInterval;
  public subscriptionType = SubscriptionType;
  public selectedInterval = signal(PaymentInterval.yearly);
  private selectedIntervalText = computed(() =>
    this.selectedInterval() === PaymentInterval.monthly ? 'monthly' : 'yearly'
  );

  public prices = {
    [SubscriptionType.essential]: {
      yearly: {
        price: '395',
        stripeLink: environment.stripePaymentLinks.essentialYearly
      },
      monthly: {
        price: '35.95',
        stripeLink: environment.stripePaymentLinks.essentialMonthly
      }
    },
    [SubscriptionType.perform]: {
      yearly: {
        price: '460',
        stripeLink: environment.stripePaymentLinks.performYearly
      },
      monthly: {
        price: '42.95',
        stripeLink: environment.stripePaymentLinks.performMonthly
      }
    }
  };

  public changeHandler(e: PaymentInterval): void {
    Logger.log(e);
    this.selectedInterval.set(e);
  }

  public getPrice(type: SubscriptionType) {
    const textInterval = this.selectedIntervalText().slice(
      0,
      this.selectedIntervalText().length - 2
    );

    return (
      '$ ' +
      this.prices[type][this.selectedIntervalText()].price +
      '/' +
      textInterval
    );
  }

  public redirectToStripe(type: SubscriptionType) {
    window.open(this.prices[type][this.selectedIntervalText()].stripeLink);
  }
}
