import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from '../../directives';
import { FlagIconComponent, LabelComponent } from '../../components';
import { LanguageSwitchComponent } from '../../components/language-switch/language-switch.component';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Observable, map, timer } from 'rxjs';
import { I18nService } from '@core/i18n';
import { Router } from '@angular/router';
import { MobileService, AuthService } from '@core/services';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    FlagIconComponent,
    LabelComponent,
    LanguageSwitchComponent,
    TranslateModule
  ],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit {
  public time$?: Observable<string>;

  @Input()
  @HostBinding('class.transparent')
  public transparent = false;

  @Input()
  @HostBinding('class.shadow')
  public shadow = false;

  @Input()
  public clientName?: string;

  @Input()
  public showTips = false;

  @Input()
  public showDateTime = false;

  @Input()
  public showSessionID = false;

  @Input()
  public title?: string;

  public isAuthenticated = this.authService.isAuthenticated();

  constructor(
    public mobileService: MobileService,
    private i18nService: I18nService,
    private authService: AuthService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.initalize();
  }

  public goHome(): void {
    void this.router.navigate(['/auth/actor']);
  }

  private getCurrentTime(): string {
    const dateTime = dayjs();
    const month = this.i18nService.translate(
      `month.${dateTime.format('MMMM').toLowerCase()}`
    );

    return `${month} ${dateTime.format('D, YYYY')} - ${dateTime.format(
      'HH:mm'
    )}`;
  }

  private initalize(): void {
    this.time$ = timer(0, 1000).pipe(map(() => this.getCurrentTime()));
  }
}
