<div class="input-wrapper" [class.has-icon]="icon !== undefined">
  <ng-content select="[prefix]"></ng-content>
  <fa-icon *ngIf="icon" [icon]="icon" class="icon"></fa-icon>
  <input
    #inputElement
    class="input"
    [class.split-left]="split === 'left'"
    [class.split-right]="split === 'right'"
    [type]="inputType"
    [placeholder]="placeholder ? placeholder : ' '"
    [formControl]="control"
    [autocomplete]="autocomplete"
    [class.danger]="danger"
    [ngClass]="size === 'md' ? 'md' : 'sm'"
    [min]="min"
    [max]="max"
    (paste)="onPaste()"
    [style.padding-right.rem]="paddingRight"
    (keydown.enter)="preventEnter ? $event.preventDefault() : null" />

  @if (control.touched && control.valid && control.value) {
    <fa-icon class="success-icon" icon="circle-check"></fa-icon>
  }

  <ng-content select="[suffix]"></ng-content>
</div>
