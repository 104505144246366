import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { FormBase } from '@core/classes';
import { I18nService } from '@core/i18n';
import { Credentials, CredentialsFields, User } from '@core/models';
import { AuthService, DialogService } from '@core/services';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import {
  AlertComponent,
  FormComponent,
  FormFieldComponent,
  InputComponent,
  LabelComponent,
  LoaderComponent
} from '@shared/components';
import { DialogComponent } from '@shared/components/dialog/dialog/dialog.component';
import { ButtonDirective, LinkDirective } from '@shared/directives';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogComponent,
    InputComponent,
    TranslateModule,
    FontAwesomeModule,
    FormComponent,
    FormFieldComponent,
    AlertComponent,
    LabelComponent,
    LinkDirective,
    ButtonDirective,
    RouterModule,
    LoaderComponent
  ],
  templateUrl: './login-dialog.component.html',
  styleUrl: './login-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginDialogComponent
  extends FormBase<Credentials, User>
  implements OnInit, OnDestroy
{
  public override fields = CredentialsFields;

  public url = window.location.href;
  public identityAuthorityUrl = environment.idp.authorityUrl;
  public override form = new FormGroup({
    [this.fields.Username]: new FormControl<string | null>(null, [
      Validators.required,
      Validators.email
    ]),
    [this.fields.Password]: new FormControl<string | null>(null, [
      Validators.required
    ])
  });

  private subs: Array<Subscription> = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialogRef: DialogRef<unknown, LoginDialogComponent>,
    protected locale: I18nService,
    private dialogService: DialogService,
    private i18nService: I18nService
  ) {
    super();
  }

  public ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public ngOnInit(): void {
    this.initialize();
  }

  public getError(error: any): string {
    switch (error.status) {
      case 0:
        return 'error.0';
      default:
        return 'alert.wrong_credentials';
    }
  }

  public forgotPassword(): void {
    this.dialogService
      .openPopup({
        primaryLabel: this.i18nService.translate('label.ok'),
        title: this.i18nService.translate('label.something_went_wrong'),
        description: this.i18nService.translate(
          'dialog.info.password_not_working'
        )
      })
      .subscribe();
    // window.open(
    //   this.identityAuthorityUrl +
    //     'Account/ForgotPassword?returnUrl={' +
    //     this.url +
    //     '}' +
    //     '&culture=' +
    //     this.locale.getCurrentLocaleCode(),
    //   'blank'
    // );
  }

  public goBack(): void {
    this.dialogRef.close();
    void this.router.navigate(['/auth']);
  }

  protected override onSubmit(item: Credentials): Observable<User | undefined> {
    return this.authService.login(item);
  }

  private initialize(): void {
    this.subs.push(
      this.success$.subscribe((user) => {
        this.loginSuccess(user);
      })
    );
  }

  private loginSuccess(user: User): void {
    this.authService.setUser(user);
    this.dialogRef.close();
  }
}
