import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonDirective,
  DropdownComponent,
  DropdownDirective
} from '../../directives';
import { FlagIconComponent } from '../flag-icon/flag-icon.component';
import {
  FormControl,
  FormControlStatus,
  ReactiveFormsModule
} from '@angular/forms';
import { InputBaseClass } from 'src/app/core/classes';
import { SelectPanelComponent } from '../select';
import { GeoService } from 'src/app/core/services';
import { OptionObject } from 'src/app/core/models/option.model';
import { Country } from 'src/app/core/models/country.model';
import {
  CountryCode,
  E164Number,
  PhoneNumber,
  parsePhoneNumber
} from 'libphonenumber-js';
import { Validators } from '../../validators/validator.class';
import { Subscription, startWith } from 'rxjs';
import { InputComponent } from '../inputs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-phone-input',
  standalone: true,
  imports: [
    CommonModule,
    DropdownDirective,
    FlagIconComponent,
    InputComponent,
    SelectPanelComponent,
    ButtonDirective,
    ReactiveFormsModule,
    InputComponent,
    DropdownComponent
  ],
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneInputComponent
  extends InputBaseClass
  implements OnInit, OnDestroy
{
  @ViewChild(SelectPanelComponent)
  public selectPanel?: SelectPanelComponent;

  @ViewChild(DropdownDirective)
  public panel?: DropdownDirective;

  public readonly defaultCountry!: CountryCode;

  public options = this.geoService.getCountries().map((country) => {
    return {
      name: country.name,
      value: country.code,
      country: country.code
    } as OptionObject;
  });

  public country = new FormControl<CountryCode | null>(this.defaultCountry);
  public number = new FormControl<number | null>(null);

  public selectedCountry?: Country;
  public phoneNumberCache?: PhoneNumber;
  public panelOpen = false;

  private subs: Array<Subscription> = [];

  public get invalid(): boolean {
    return this.control.invalid && this.control.touched;
  }

  constructor(
    private geoService: GeoService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {
    super();

    this.subscribeToInternalControls();
  }
  public ngOnInit(): void {
    this.subscribeToControl();
    this.setDefaultFromParam();
  }

  public ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public numberTouched(): void {
    this.control.markAsTouched();
    this.cd.markForCheck();
  }
  public onPanelOpened(): void {
    this.panelOpen = true;
  }

  public onSelect(): void {
    this.panelOpen = false;
    this.panel?.close();
  }

  private setControlValue(value?: E164Number): void {
    this.control.setValue(value, { emitEvent: false });
    this.control.markAsDirty();
  }

  private parsePhoneNumber(
    number: string,
    country?: string
  ): PhoneNumber | undefined {
    if (!country) {
      country = this.geoService.getCountryByCode('NL')?.code;
    }
    //TODO: GIVES ERROR ON SHORT NUMBER
    return parsePhoneNumber(
      number,
      (country || this.defaultCountry) as CountryCode
    );
  }

  private countryValueChanged(value: string | null): void {
    if (!value) return;

    this.selectedCountry = this.geoService.getCountryByCode(value);

    this.selectedCountry &&
      this.setValidatorsForCountry(this.selectedCountry.code as CountryCode);

    this.cd.markForCheck();
  }

  private numberValueChanged(value: number | null): void {
    const country = this.selectedCountry?.code;

    try {
      const phoneNumber = this.parsePhoneNumber(
        value?.toString() || '',
        country
      );

      this.setControlValue(phoneNumber?.number);
    } catch (error) {
      this.control.setValue(value, { emitEvent: false });
    }
    this.cd.markForCheck();
  }

  private controlValueChanged(value?: string): void {
    if (!value) {
      return this.resetPhoneNumber();
    }

    const phoneNumber = this.parsePhoneNumber(value);

    if (!phoneNumber?.isValid()) {
      const country = this.geoService.getCountryByCode('NL');
      this.country.setValue(country?.code as CountryCode);
      if (phoneNumber) {
        this.number.setValue(Number(phoneNumber.nationalNumber));
      }
      return;
    }

    this.country.setValue(phoneNumber.country as CountryCode);
    this.number.setValue(Number(phoneNumber.nationalNumber));
    this.cd.detectChanges();
  }

  private controlStatusChanged(status?: FormControlStatus): void {
    if (status === 'DISABLED') {
      this.country.disable();
      this.number.disable();
    } else {
      this.country.enable();
      this.number.enable();
    }
  }

  private resetPhoneNumber(): void {
    this.country.setValue(this.defaultCountry);
    this.number.setValue(null);
    this.number.updateValueAndValidity();
  }

  private setValidatorsForCountry(country: CountryCode): void {
    const validator = [Validators.validPhoneNumberForCountry(country)];

    if (this.control.errors?.['required']) {
      validator.push(Validators.required);
    }

    this.control.setValidators(validator);
    this.number.setValidators(validator);
    this.number.updateValueAndValidity();
  }

  private subscribeToControl(): void {
    if (!this.control.value) {
      this.country.setValue(this.defaultCountry);
    }

    this.subs.push(
      this.control.statusChanges.subscribe((status) =>
        this.controlStatusChanged(status)
      ),
      this.control.valueChanges
        .pipe(startWith(this.control.value))
        .subscribe((value) => this.controlValueChanged(value))
    );
  }

  private subscribeToInternalControls(): void {
    this.subs.push(
      this.number.valueChanges.subscribe((value) => {
        this.numberValueChanged(value);
      }),
      this.country.valueChanges.subscribe((value) =>
        this.countryValueChanged(value)
      )
    );
  }

  private setDefaultFromParam(): void {
    this.activatedRoute.root.queryParams.subscribe((params) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const lang = params['lang'].toUpperCase();
      this.country.setValue(lang as CountryCode);
    });
  }
}
