<div id="onboarding-container" [ngClass]="{ hidden: loading }">
  <span>
    <div>
      <h3>{{ title || '' | translate }}</h3>
    </div>
    <div>{{ description || '' | translate }}</div>
  </span>
  <div class="buttons-container">
    <a
      id="cancel-btn"
      (click)="cancelMessage()"
      (keyup.enter)="cancelMessage()"
      tabindex="0"
      >{{ 'label.hide_tips' | translate }}</a
    >
    <button
      appButton="primary"
      id="next-btn"
      mat-button
      (click)="nextMessage()"
      [rounded]="true">
      {{ btnName | translate }}
    </button>
  </div>
  <div id="box-arrow"></div>
</div>
