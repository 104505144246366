<label [for]="id" [class.mb-2]="ref.children.length > 0">
  <input
    type="radio"
    [name]="prefix"
    (change)="onChanged($event.target)"
    [checked]="isChecked"
    [value]="value"
    [id]="id" />
  <p>
    <ng-content></ng-content>
  </p>
</label>

<div
  #ref
  class="description"
  [class.mb-10]="checkedContent.children.length > 0 && isChecked">
  <ng-content select="[description]"></ng-content>
</div>

<div #checkedContent class="checked-content" [class.hidden]="!isChecked">
  <ng-content select="[checkedContent]"></ng-content>
</div>
