import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-unordered-list',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './unordered-list.component.html',
  styleUrls: ['./unordered-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnorderedListComponent {
  @Input({ required: true })
  public listData!: string[];
}
