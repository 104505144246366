import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { OnboardingStep, OnboardingUser } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private readonly _open = new BehaviorSubject(false);

  public readonly open$ = this._open.asObservable();

  constructor(private http: HttpClient) {}

  public open(): void {
    this._open.next(true);
  }

  public close(): void {
    this._open.next(false);
  }

  public GetNewOnboardingStepsForUserById(userId: string) {
    return this.http.get<OnboardingStep[]>(
      environment.baseUrl +
        'onboarding/Step/GetNewOnboardingStepsForUserById/' +
        userId
    );
  }
  public GetAllOnboardingSteps(): Observable<Array<OnboardingStep>> {
    // return this.http.get<OnboardingStep[]>(
    //   environment.baseUrl + 'onboarding/Step/'
    // );

    return of([
      {
        id: 1,
        description: 'step 1',
        stepNumber: 1,
        lastUpdateDate: '2023-06-12T14:55:51.53' as unknown as Date,
        json: {
          TargetContainerId: 'session_streaming-container',
          PlacementDialog: 'left',
          DialogIcon: '/assets/img/v2/video_call.png',
          DialogHeader: 'show_tips.step.1.header',
          DialogText: 'show_tips.step.1.text'
        },
        active: 1,
        onboardingUsers: []
      },
      {
        id: 2,
        description: 'step 2',
        stepNumber: 2,
        lastUpdateDate: '2023-06-12T14:55:51.5333333' as unknown as Date,
        json: {
          TargetContainerId: 'session_visual_options_container',
          PlacementDialog: 'right',
          DialogIcon: '/assets/img/onboarding-switch.png',
          DialogHeader: 'show_tips.step.2.header',
          DialogText: 'show_tips.step.2.text'
        },
        active: 1,
        onboardingUsers: []
      },
      {
        id: 3,
        description: 'step 3',
        stepNumber: 3,
        lastUpdateDate: '2023-06-12T14:55:51.5333333' as unknown as Date,
        json: {
          TargetContainerId: 'start-task',
          PlacementDialog: 'right',
          DialogIcon: '',
          DialogHeader: 'show_tips.step.3.header',
          DialogText: 'show_tips.step.3.text'
        },
        active: 1,
        onboardingUsers: []
      }
    ]);
  }
  public GetOnboardingStepByNumber(stepNumber: number) {
    return this.http.get<OnboardingStep[]>(
      environment.baseUrl +
        'onboarding/Step/GetOnboardingStepByNumber/' +
        stepNumber.toString()
    );
  }
  public GetActiveOnboardingSteps() {
    return this.http.get<OnboardingStep[]>(
      environment.baseUrl + 'onboarding/Step/GetActiveOnboardingSteps'
    );
  }
  public GetActiveOnboardingUserById(userId: string) {
    return this.http.get<OnboardingStep[]>(
      environment.baseUrl + 'onboarding/User/' + userId
    );
  }
  public GetOnboardingStepById(id: number) {
    return this.http.get<OnboardingStep>(
      environment.baseUrl +
        'onboarding/Step/GetOnboardingStepById/' +
        id.toString()
    );
  }
  public UpdateOnboardingStepForUser(onboardingUser: OnboardingUser) {
    const body = JSON.stringify(onboardingUser);
    return this.http.put<OnboardingUser>(
      environment.baseUrl + 'onboarding/User/UpdateOnboardingStepForUser',
      body
    );
  }
  public AddOnboardingUser(onboardinUser: OnboardingUser) {
    const body = JSON.stringify(onboardinUser);
    return this.http.post<OnboardingUser>(
      environment.baseUrl + 'onboarding/User/AddOnboardingUser',
      body
    );
  }
  public AddOnboardingStep(onboardingStep: OnboardingStep) {
    const body = JSON.stringify(onboardingStep);
    return this.http.post<OnboardingUser>(
      environment.baseUrl + 'onboarding/Step/AddOnboardingStep',
      body
    );
  }
}
