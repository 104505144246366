import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { AuthService } from '@core/services';
import { Observable, of, switchMap, timer } from 'rxjs';

export const emailAvailable = (authService: AuthService): AsyncValidatorFn => {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return timer(500).pipe(
      switchMap(() => authService.checkIfEmailExists(control.value as string)),
      switchMap((result) => {
        if (result === true || control.pristine) {
          return of(null);
        }

        control.markAsTouched();
        return of({ emailAvailable: 'this email is unavailable' });
      })
    );
  };
};
