<app-dialog [disableClose]="true">
  <h6 class="title">{{ 'connection.dialog.title' | translate }}</h6>

  @if (loading()) {
    <app-loader size="xl" />
  } @else {
    <p class="description">
      {{ 'connection.dialog.description' | translate }}
    </p>
  }

  <div class="button-group">
    <button
      appButton
      [stretch]="true"
      (click)="cancel()"
      [isDisabled]="loading()">
      <app-label>{{ 'label.leave_session' | translate }}</app-label>
    </button>

    <button
      appButton="primary"
      [stretch]="true"
      (click)="confirm()"
      [isDisabled]="loading()">
      <app-label>{{ 'label.retry' | translate }}</app-label>
    </button>
  </div>
</app-dialog>
