<app-dialog [disableClose]="true">
  <h2>{{ 'Select devices' | translate }}</h2>
  <p class="mb-8">
    {{
      'Not all devices for video calling were selected, please select the desired devices to start video calling the client'
        | translate
    }}
  </p>

  <div class="device-select-container mb-8">
    @if (deviceSelectionService.audioInputOptions(); as options) {
      <app-select
        [options]="options"
        [control]="deviceSelectionService.audioInputControl"
        variant="rounded"
        size="sm"
        icon="microphone" />
    }
    @if (deviceSelectionService.audioOutputOptions(); as options) {
      <app-select
        [options]="options"
        [control]="deviceSelectionService.audioOutputControl"
        variant="rounded"
        size="sm"
        icon="volume-high" />
    }
    @if (deviceSelectionService.videoInputOptions(); as options) {
      <app-select
        [options]="options"
        [control]="deviceSelectionService.videoInputControl"
        variant="rounded"
        size="sm"
        icon="video" />
    }
  </div>

  <div class="button-group">
    <button appButton (click)="close(false)">
      <app-label>{{ 'Cancel' }}</app-label>
    </button>

    <button
      appButton="primary"
      (click)="close(true)"
      [isDisabled]="
        deviceSelectionService.videoInputControl.invalid ||
        deviceSelectionService.audioInputControl.invalid ||
        deviceSelectionService.audioOutputControl.invalid
      ">
      <app-label>{{ 'Confirm' }}</app-label>
    </button>
  </div>
</app-dialog>
