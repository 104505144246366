<div #container id="container">
  <button
    #button
    appButton
    [class.has-value]="!!control.value"
    [appDropdown]="dropdown"
    [width]="width()"
    (closed)="panelClosed()"
    type="button"
    [rounded]="variant === 'rounded'"
    class="input"
    [offsetY]="8"
    [class.danger]="isInvalid"
    [disabled]="isDisabled || !options?.length"
    (opened)="onPanelOpened()">
    @if (icon) {
      <fa-icon [icon]="icon" class="icon"></fa-icon>
    }

    @if (selectedOption) {
      <app-option-label
        class="select-label"
        [option]="selectedOption"></app-option-label>
    } @else {
      <app-label>{{ placeholder }}</app-label>
    }

    <fa-icon
      [@chevronRotate]="panelState()"
      class="chevron"
      icon="chevron-down"></fa-icon>
    <!-- <label *ngIf="label" [class.danger]="isInvalid">{{ label }}</label> -->
  </button>
</div>

<ng-template #dropdown>
  <div class="dropdown-wrapper">
    <app-select-panel
      [filterable]="filterable"
      [filterPlaceholder]="filterPlaceholder"
      [filterThreshold]="filterThreshold"
      [options]="options"
      [control]="control"
      (selected)="onSelect()"></app-select-panel>
  </div>
</ng-template>
