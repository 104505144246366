export enum DeviceType {
  Computer = 'computer',
  Mobile = 'mobile'
}

export interface DeviceInfoOS {
  name: string;
  version: string;
  platform: 'ARM' | 'x64' | 'x86' | 'MIPS' | 'SuperH' | '';
}
