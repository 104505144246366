import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-instruction-image',
  standalone: true,
  imports: [],
  templateUrl: './instruction-image.component.html',
  styleUrl: './instruction-image.component.scss'
})
export class InstructionImageComponent {
  constructor(private deviceService: DeviceDetectorService) {}

  @Input() public browser: string | undefined;

  protected imageSources = {
    desktop: {
      chrome: 'assets/img/permissions/chrome-permissions.svg',
      safari: 'assets/img/permissions/safari-permissions.svg',
      firefox: 'assets/img/permissions/firefox-permissions.svg',
      edge: 'assets/img/permissions/edge-permissions.svg'
    },
    mobile: {
      chrome: 'assets/img/permissions/chrome-permissions.svg',
      safari: 'assets/img/permissions/safari-mobile-permissions.svg',
      firefox: 'assets/img/permissions/firefox-permissions.svg',
      edge: 'assets/img/permissions/edge-permissions.svg'
    }
  };

  protected get src(): string {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      switch (this.browser) {
        case 'Chrome':
          return this.imageSources.mobile.chrome;
        case 'Safari':
          return this.imageSources.mobile.safari;
        case 'Firefox':
          return this.imageSources.mobile.firefox;
        default:
          return this.imageSources.mobile.edge;
      }
    } else {
      switch (this.browser) {
        case 'Chrome':
          return this.imageSources.desktop.chrome;
        case 'Safari':
          return this.imageSources.desktop.safari;
        case 'Firefox':
          return this.imageSources.desktop.firefox;
        default:
          return this.imageSources.desktop.edge;
      }
    }
  }
}
