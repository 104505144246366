<app-dialog [disableClose]="true">
  <h2 class="text-bold mb-10">{{ 'auth.login.title' | translate }}</h2>
  <p class="description mb-14">{{ 'auth.login.description' | translate }}</p>

  @if (error$ | async; as error) {
    <app-alert class="text-sm mb-8">
      {{ getError(error) | translate }}
    </app-alert>
  }

  <app-form [form]="form" (formSubmit)="submit()">
    <app-form-field [label]="'label.email' | translate" class="mb-15">
      <app-input
        [control]="form.controls[fields.Username]"
        [placeholder]="'placeholder.enter_your_email' | translate" />
    </app-form-field>

    <app-form-field [label]="'label.password' | translate">
      <app-input
        inputType="password"
        [control]="form.controls[fields.Password]"
        [placeholder]="'placeholder.your_password' | translate" />
    </app-form-field>

    <a
      appLink
      target="_blank"
      rel="noreferrer noopener"
      (clicked)="forgotPassword()"
      class="mb-15">
      <app-label>{{ 'label.forgot_password' | translate }}</app-label>
    </a>

    <button appButton="primary" [stretch]="true" [isDisabled]="form.invalid">
      <app-label>{{ 'label.login' | translate }}</app-label>
    </button>
    <span
      class="back-span"
      (click)="goBack()"
      (keydown.enter)="goBack()"
      tabindex="0">
      <fa-icon icon="arrow-left" class="mr-2" />
      {{ 'label.back' | translate }}</span
    >
  </app-form>
</app-dialog>
