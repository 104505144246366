<div class="left">
  <div
    class="banner cursor"
    (click)="goHome()"
    (keyup.enter)="goHome()"
    tabindex="0">
    <img src="assets/img/logos/wemind-blue.svg" class="logo" alt="EMDR logo" />
  </div>
  <div class="vr"></div>
  <div class="title">
    <h6>{{ title }}</h6>
    @if (showDateTime) {
      <span class="date-time">{{ time$ | async }} </span>
    }
  </div>
  <ng-content select="[left]"></ng-content>
</div>
<div class="right">
  <ng-content></ng-content>
</div>
