import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonDirective,
  DropdownDirective,
  DropdownComponent
} from '../../directives';
import { LabelComponent } from '../label';
import { FlagIconComponent } from '../flag-icon/flag-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { languageSwitchAnimations } from './language-switch.animation';
import { LangParamsService, MobileService } from 'src/app/core/services';
import { I18nService } from '@core/i18n';
import { I18nLocale } from '@core/i18n/enums/i18n.enum';
import { map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-language-switch',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    DropdownDirective,
    LabelComponent,
    FlagIconComponent,
    FontAwesomeModule,
    DropdownComponent,
    TranslateModule
  ],
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [languageSwitchAnimations.indicatorRotate]
})
export class LanguageSwitchComponent {
  @ViewChild(DropdownDirective)
  public dropdown?: DropdownDirective;

  @Input()
  public square = false;

  @Input()
  public size: 'lg' | 'md' = 'lg';

  @Input()
  public stretch = false;

  @HostBinding('class')
  public get classes(): Array<string> {
    const array: Array<string> = [this.size];

    this.square && array.push('square');
    return array;
  }

  public currentLanguage = this.i18nService.currentLocale$.pipe(
    map((locale: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      return this.i18nService.getLanguages().find((l) => l.locale === locale);
    })
  );

  public languages = this.i18nService.getLanguages();

  public isOpen = false;

  constructor(
    public mobileService: MobileService,
    private i18nService: I18nService,
    private langParamsService: LangParamsService
  ) {}

  public onOpened(): void {
    this.isOpen = true;
  }

  public onClosed(): void {
    this.isOpen = false;
  }

  public useLanguage(language: unknown): void {
    if (this.i18nService.getCurrentLocale() !== language) {
      void this.i18nService.useLanguage(language as I18nLocale).then(() => {
        this.langParamsService.setLanguageParams(
          this.i18nService.getCurrentLang() as string
        );
      });
      this.dropdown?.close();
    }
  }
}
