import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { ButtonDirective } from '@shared/directives';
import { LabelComponent } from '@shared/components/label';
import { DialogRef } from '@angular/cdk/dialog';
import { InputComponent } from '@shared/components/inputs';
import { FormFieldComponent } from '@shared/components/form';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-tips-protocol-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogComponent,
    ButtonDirective,
    LabelComponent,
    InputComponent,
    FormFieldComponent,
    TranslateModule
  ],
  templateUrl: './tips-protocol-dialog.component.html',
  styleUrl: './tips-protocol-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipsProtocolDialogComponent {
  public kiesslingTipsSetting = localStorage.getItem('roy-kiessling-tips');

  constructor(
    private dialogRef: DialogRef<unknown, TipsProtocolDialogComponent>
  ) {}

  public formControl = new FormControl<string>('');
  public hasSpecialTips(): boolean {
    if (this.kiesslingTipsSetting === '1') {
      return true;
    }
    return false;
  }

  public disableSpecialTips(): void {
    localStorage.setItem('roy-kiessling-tips', '0');
    window.location.reload();
  }

  public setSpecialTips(value: string): void {
    console.log('submit', value);
    if (value === 'KIESSLING') {
      localStorage.setItem('roy-kiessling-tips', '1');
      window.location.reload();
    }
    return;
  }

  public close(): void {
    this.dialogRef.close();
  }
}
