import { Injectable } from '@angular/core';
import { SessionStorageKey } from '@core/enums';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  /**
   * Sets a key in the session storage
   */
  public set(key: SessionStorageKey, value: unknown): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Gets a item out of the session storage
   */
  get<T>(key: SessionStorageKey): T | undefined {
    const value = sessionStorage.getItem(key);

    switch (value?.toLocaleLowerCase()) {
      case 'true':
        return true as unknown as T;
      case 'false':
        return false as unknown as T;
      default:
        return value ? (JSON.parse(value) as T) : undefined;
    }
  }

  /**
   * Removes a key from the session storage
   */
  remove(key: SessionStorageKey): void {
    sessionStorage.removeItem(key);
  }

  /**
   * Clears the whole session storage
   */
  clear(): void {
    sessionStorage.clear();
  }
}
