import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ActorType } from '../enums';
import { AuthService } from '../services';

const redirectToHome = () => {
  void inject(Router).navigate(['/']);
};

export const disclaimerGuard: CanActivateFn = (route) => {
  const { actor, redirect } = route.queryParams;

  switch (actor) {
    case ActorType.Therapist:
      if (!inject(AuthService).isAuthenticated() || redirect === undefined) {
        redirectToHome();
        return false;
      }
      break;

    default:
      return true;
  }
  return true;
};
