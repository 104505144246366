import { Moovd_ENV } from 'src/app/core/enums';
import packageInfo from '../../package.json';

export const environment = {
  version: packageInfo.version.toString(),
  env: Moovd_ENV.DEVELOPMENT,
  baseUrl: 'https://api.dev.digital-emdr.com/api/', // test server
  baseSignalR: 'https://dev.signal-cloud.moovd.nl/chat/',
  exposureUrl: 'https://api.dev.exposure.moovd.nl/api/',
  clientDesktopUrl: 'https://dev.client.we-mind.app',
  clientMobileUrl: 'https://dev.client.we-mind.app',
  identityApiUrl: 'https://api.dev.identity.moovd.nl/api/',
  adminUrl: 'https://dev.admin.moovd.nl',
  backGroundBaseName: 'NewAppartment',
  financeUrl: 'https://dev.finance.digital-emdr.com',
  idp: {
    authorityUrl: 'https://api.dev.identity.moovd.nl/',
    clientId: 'angular-digital-emdr-client',
    clientRoot: 'https://dev.we-mind.app'
  },
  stripeApiKey:
    'pk_test_51N3v0fAaFLnCHS7Vq4CwBzdZKL7ftSaGu7rahQi0WZwM1kbwo709y2wI3Yc6kJRC0XORo7n4ImpvbP57vg7slqiP00IFIUuT3s',
  stripePaymentLinks: {
    essentialYearly: 'https://buy.stripe.com/test_dR66qp4s9g9m1iMeV4',
    essentialMonthly: 'https://buy.stripe.com/test_4gw7utgaRbT6gdGdR1',
    performYearly: 'https://buy.stripe.com/test_4gw4ih7Elf5i2mQfZc',
    performMonthly: 'https://buy.stripe.com/test_3csdSRf6NcXad1u3cr'
  },
  localesPath: '/assets/locale',
  clientUrl: 'https://dev.client.we-mind.app/',
  redirectUrl: 'https://dev.we-mind.app',
  googleAnalyticsId: 'G-44DLVNH6P3',
  WeMindEcosystemBearer: 'Y29kd3JrciBpcyB0aGUgYmVzdCBjb21wYW55',
  WeMindVideoCallingServiceBaseURL:
    'https://9wwtfa2ow5.execute-api.ap-southeast-1.amazonaws.com/dev/api/',
  intercomAppId: 'dzqcm02f'
};
