<div class="table-container">
  <h2>{{ 'benefit-table.title' | translate }}</h2>
  <table>
    <thead class="main-header">
      <tr>
        <th class="title" scope="col">
          <h2>{{ 'benefit-table.title' | translate }}</h2>
        </th>
        <th scope="col">ESSENTIAL</th>
        <th scope="col">PERFORM</th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <th colspan="3" class="section-header">
          {{ 'benefit-table.general.title' | translate }}
        </th>
      </tr>
      <tr>
        <th scope="row">{{ 'benefit-table.general.list-1' | translate }}</th>
        <td>$35.95</td>
        <td>$42.95</td>
      </tr>
      <tr>
        <th scope="row">{{ 'benefit-table.general.list-2' | translate }}</th>
        <td>$395</td>
        <td>$460</td>
      </tr>
      <tr>
        <th scope="row">{{ 'benefit-table.general.list-3' | translate }}</th>
        <td>1</td>
        <td>1</td>
      </tr>
      <tr>
        <th scope="row">{{ 'benefit-table.general.list-4' | translate }}</th>
        <td>{{ 'label.days-trial' | translate }}</td>
        <td>{{ 'label.days-trial' | translate }}</td>
      </tr>
      <tr>
        <th scope="row">{{ 'benefit-table.general.list-5' | translate }}</th>
        <td>{{ 'label.unlimited' | translate }}</td>
        <td>{{ 'label.unlimited' | translate }}</td>
      </tr>

      <tr>
        <th colspan="3" class="section-header">
          {{ 'benefit-table.main.title' | translate }}
        </th>
      </tr>
      <tr>
        <th scope="row">
          {{ 'benefit-table.main.list-1' | translate }}
        </th>
        <td><fa-icon icon="check"></fa-icon></td>
        <td><fa-icon icon="check"></fa-icon></td>
      </tr>
      <tr>
        <th scope="row">{{ 'benefit-table.main.list-2' | translate }}</th>
        <td><fa-icon icon="check"></fa-icon></td>
        <td><fa-icon icon="check"></fa-icon></td>
      </tr>
      <tr>
        <th scope="row">
          {{ 'benefit-table.main.list-3' | translate }}
        </th>
        <td><fa-icon icon="check"></fa-icon></td>
        <td><fa-icon icon="check"></fa-icon></td>
      </tr>
      <tr>
        <th scope="row">
          {{ 'benefit-table.main.list-4' | translate }}
        </th>
        <td><fa-icon icon="check"></fa-icon></td>
        <td><fa-icon icon="check"></fa-icon></td>
      </tr>
      <tr>
        <th scope="row">{{ 'benefit-table.main.list-5' | translate }}</th>
        <td><fa-icon icon="check"></fa-icon></td>
        <td><fa-icon icon="check"></fa-icon></td>
      </tr>
      <tr>
        <th scope="row">{{ 'benefit-table.main.list-6' | translate }}</th>
        <td><fa-icon icon="check"></fa-icon></td>
        <td><fa-icon icon="check"></fa-icon></td>
      </tr>
      <tr>
        <th scope="row">
          {{ 'benefit-table.main.list-7' | translate }}
        </th>
        <td><fa-icon icon="check"></fa-icon></td>
        <td><fa-icon icon="check"></fa-icon></td>
      </tr>

      <tr>
        <th colspan="3" class="section-header">
          {{ 'benefit-table.exposure.title' | translate }}
        </th>
      </tr>
      <tr>
        <th scope="row">{{ 'benefit-table.exposure.list-1' | translate }}</th>
        <td><fa-icon icon="x"></fa-icon></td>
        <td><fa-icon icon="check"></fa-icon></td>
      </tr>

      <tr>
        <th colspan="3" class="section-header">
          {{ 'benefit-table.library.title' | translate }}
        </th>
      </tr>
      <tr>
        <th scope="row">{{ 'benefit-table.library.list-1' | translate }}</th>
        <td><fa-icon icon="x"></fa-icon></td>
        <td><fa-icon icon="check"></fa-icon></td>
      </tr>
    </tbody>
  </table>
  <div class="grid-row tr" colspan="3">
    <div class="th">
      <app-radio-input
        prefix="paymentInterval"
        class="centerized"
        [grid]="true"
        (valueChanged)="changeHandler($event)"
        [value]="selectedInterval()">
        <app-radio-option [value]="paymentIntervals.monthly"
          >{{ 'label.billed-monthly' | translate }}
        </app-radio-option>
        <app-radio-option [value]="paymentIntervals.yearly"
          >{{ 'label.billed-yearly' | translate }}
        </app-radio-option>
      </app-radio-input>
    </div>
    <div class="left-align td">
      <p class="text-bold">ESSENTIAL</p>
      <p class="text-bold mt-8">
        {{ 'auth.featured-plan.trial' | translate }}
      </p>
      <p class="then">
        {{ 'auth.featured-plan.then' | translate }}
      </p>
      <p class="text-bold">{{ getPrice(subscriptionType.essential) }}</p>
      <button
        [stretch]="true"
        class="mt-8"
        appButton="primary"
        (click)="redirectToStripe(subscriptionType.essential)">
        {{ 'label.start_free_trial' | translate }}
      </button>
    </div>
    <div class="left-align td" colspan="2">
      <p class="text-bold">PERFORM</p>
      <p class="text-bold mt-8">
        {{ 'auth.featured-plan.trial' | translate }}
      </p>
      <p class="then">
        {{ 'auth.featured-plan.then' | translate }}
      </p>
      <p class="text-bold">{{ getPrice(subscriptionType.perform) }}</p>
      <button
        [stretch]="true"
        class="mt-8"
        appButton="primary"
        (click)="redirectToStripe(subscriptionType.perform)">
        {{ 'label.start_free_trial' | translate }}
      </button>
    </div>
  </div>
</div>
