import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormControl } from '@angular/forms';

import {
  ToggleButtonComponent,
  ToggleButtonItemComponent
} from '../toggle-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-on-off-toggle',
  standalone: true,
  imports: [
    CommonModule,
    ToggleButtonComponent,
    ToggleButtonItemComponent,
    TranslateModule
  ],
  templateUrl: './on-off-toggle.component.html',
  styleUrl: './on-off-toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnOffToggleComponent implements OnChanges {
  @Input()
  public value: boolean = true;

  @Input()
  public control = new FormControl<boolean>(this.value);

  @Input()
  public onLabel?: string;

  @Input()
  public offLabel?: string;

  @Output()
  public valueChanged = new EventEmitter<boolean>();

  public onValueChanged(): void {
    this.valueChanged.emit(this.control.value as boolean);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      const value = changes['value'].currentValue;
      this.control.setValue(value, { emitEvent: false });
    }
  }
}
