/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from '@core/classes';
import { AuthService } from '@core/services';

import { Observable, catchError, throwError } from 'rxjs';

export enum HttpErrorStatus {
  InvalidToken = 401,
  Unauthorized = 403,
  NotFound = 404
}

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next
      .handle(request)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    switch (error.status) {
      case HttpErrorStatus.InvalidToken:
        Logger.error('HttpErrorInterceptorService: Token invalid');
        this.authService.logout();
        break;
    }

    return throwError(error);
  }
}
