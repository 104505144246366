import { CanActivateFn } from '@angular/router';
import { Moovd_ENV } from '@core/enums';

import { environment } from 'src/environments/environment';

export const isDevelopmentGuard: CanActivateFn = () => {
  if (environment.env === Moovd_ENV.DEVELOPMENT) {
    return true;
  }
  return false;
};
