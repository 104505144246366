<div class="content">
  <div
    class="content-header"
    [style.align-self]="isContentShowing ? 'stretch' : 'inherit'">
    @if (isContentShowing) {
      <button class="back-button" appButton (click)="backButtonClicked()">
        <app-label icon="arrow-left">{{ 'label.back' | translate }}</app-label>
      </button>
    }

    <button class="close-button" appButton (click)="closeDialogClicked(true)">
      <app-label icon="xmark"></app-label>
    </button>
  </div>

  <div
    class="content-body"
    [style.width]="isContentShowing ? '920px' : '663px'">
    <div
      id="startSession"
      [class]="
        selectedArcadeContent === 'startSession' ? 'content-arcade' : 'hidden'
      "
      style="
        position: relative;
        padding-bottom: calc(51.72991071428571% + 41px);
        height: 0;
        width: 100%;
      ">
      <iframe
        src="https://demo.arcade.software/vxJdrp18jzoqRxD5exLf?embed&show_copy_link=true"
        title="[1] How to connect &amp; video calling"
        frameborder="0"
        loading="lazy"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="clipboard-write"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color-scheme: light;
          border-radius: 12px;
        "></iframe>
    </div>

    <div
      id="startTask"
      [class]="
        selectedArcadeContent === 'startTask' ? 'content-arcade' : 'hidden'
      "
      style="
        position: relative;
        padding-bottom: calc(51.787709497206706% + 41px);
        height: 0;
        width: 100%;
      ">
      <iframe
        src="https://demo.arcade.software/EEOot2Lm2L5HiJH6WISm?embed&show_copy_link=true"
        title="[2] Start Task + SUD VOC"
        frameborder="0"
        loading="lazy"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="clipboard-write"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color-scheme: light;
          border-radius: 12px;
        "></iframe>
    </div>

    <div
      id="navigateMenu"
      [class]="
        selectedArcadeContent === 'navigateMenu' ? 'content-arcade' : 'hidden'
      "
      style="
        position: relative;
        padding-bottom: calc(51.72991071428571% + 41px);
        height: 0;
        width: 100%;
      ">
      <iframe
        src="https://demo.arcade.software/hG39TFC7yit8UHOQcIRK?embed&show_copy_link=true"
        title="[3] Menu Features"
        frameborder="0"
        loading="lazy"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="clipboard-write"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color-scheme: light;
          border-radius: 12px;
        "></iframe>
    </div>

    @if (!isContentShowing) {
      @if (isLoading) {
        <div class="content-frame">
          <div class="spinner-wrapper">
            <fa-icon icon="spinner" size="xl" class="spinner-icon"></fa-icon>
          </div>
        </div>
      } @else {
        <div class="content-frame">
          <div class="frame-header">
            <h3 class="frame-title">
              {{ 'onboarding.arcade.title' | translate }}
            </h3>
            <p class="frame-subtitle">
              {{ 'onboarding.arcade.subtitle' | translate }}
            </p>
          </div>
          <div class="frame-body">
            <div
              class="frame-item"
              (click)="itemContentClicked('startTask')"
              (keydown.enter)="itemContentClicked('startTask')"
              tabindex="0">
              <h5 class="item-text">
                {{ 'onboarding.arcade.item-text-1' | translate }}
              </h5>
            </div>
            <div
              class="frame-item"
              (click)="itemContentClicked('navigateMenu')"
              (keydown.enter)="itemContentClicked('navigateMenu')"
              tabindex="0">
              <h5 class="item-text">
                {{ 'onboarding.arcade.item-text-2' | translate }}
              </h5>
            </div>

            @if (isUserLoggedInBefore) {
              <div
                class="frame-item"
                (click)="itemContentClicked('startSession')"
                (keydown.enter)="itemContentClicked('startSession')"
                tabindex="0">
                <h5 class="item-text">
                  {{ 'onboarding.arcade.item-text-3' | translate }}
                </h5>
              </div>
            }
          </div>
        </div>
      }
    }

    <button class="content-button" (click)="closeDialogClicked(true)">
      <app-label>{{ 'onboarding.arcade.button-later' | translate }}</app-label>
    </button>
  </div>
</div>
