export interface ExposureCategory {
  id: number;
  name: string;
  description?: string;
  parentId?: number;
  parent?: string;
  themeCategories?: any;
  tagCategories?: any;
  subCategories?: Array<ExposureCategory>;
  videos?: string;
  created?: string;
  lastUpdated?: string;
  deleted?: boolean;
}

export interface ExposureVideo {
  id: string;
  title: string;
  description?: string;
  video_Location?: string;
  video360_Location?: string;
  thumpnail_Location?: string;
  category: ExposureCategory;
}
