import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SurveyOption } from '@core/models/survey.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  public http = inject(HttpClient);

  public getNewSurveyQuestionsForUserById(
    id: string
  ): Observable<Array<SurveyOption>> {
    return this.http.get<Array<SurveyOption>>(
      environment.baseUrl + 'Survey/GetNewSurveyQuestionsForUserById/' + id
    );
  }

  public addSurveyAnswerForUser(id: number, userId: string) {
    return this.http.post<Array<SurveyOption>>(
      environment.baseUrl + `Survey/AddSurveyAnswerForUser/${userId}/${id}`,
      {}
    );
  }
}
