import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SessionService } from '@core/services';

export const noSessionIdGuard: CanActivateFn = () => {
  const sessionService = inject(SessionService);
  const sessionId = sessionService.getSessionId();

  if (sessionId) {
    sessionService.clearSessiondId();
  }
  return true;
};
