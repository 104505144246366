import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import {
  AWSChimeAttendee,
  AWSChimeCreateAttendeeResponse,
  AWSChimeCreateMeetingResponse,
  AWSChimeMeeting
} from '@core/models';
import {
  AudioVideoObserver,
  BackgroundBlurVideoFrameProcessor,
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  DefaultVideoTransformDevice,
  LogLevel,
  MeetingSessionConfiguration,
  MeetingSessionStatus,
  VideoFrameProcessor,
  VideoTileState
} from 'amazon-chime-sdk-js';
import {
  Observable,
  concatMap,
  from,
  map,
  of,
  throwError,
  catchError,
  Subject,
  takeUntil
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeviceSelectionService } from '../device-selection/device-selection.service';

import { Logger } from '@core/classes';
import { v4 as uuidv4 } from 'uuid';
import { I18nService } from '@core/i18n';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

export enum AWSChimeErrorCodes {
  Unknown = 100,
  CreateMeetingFailed = 101,
  MeetingNotDefined = 102,
  NoDevicesSelected = 110,
  NoAudioInputPermission = 111,
  NoAudioOutputPermission = 112,
  NoVideoInputPermission = 113,
  AudioInputFetching = 114,
  AudioOuputFetching = 115,
  VideoInputFetching = 116,
  Client = 120
}

export class AWSChimeError extends Error {
  constructor(
    public override message: string,
    public code: AWSChimeErrorCodes
  ) {
    super(message);
    this.name = this.constructor.name;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AwsChimeSdkService {
  public readonly audioVideoDidStart$ = new Subject<void>();
  public readonly audioVideoDidStop$ = new Subject<MeetingSessionStatus>();
  public readonly audioVideoDidStartConnecting$ = new Subject<boolean>();
  public readonly videoTileDidUpdate$ = new Subject<VideoTileState>();
  public readonly videoTileWasRemoved$ = new Subject<number>();
  public readonly connectionDidBecomePoor$ = new Subject<void>();
  public readonly connectionDidSuggestStopVideo$ = new Subject<void>();
  public readonly attendeeLeft$ = new Subject<void>();

  public readonly camEnabled = signal(true);

  private readonly cancelCalling$ = new Subject<void>();

  private _meetingSession?: DefaultMeetingSession;
  private _meetingInfo?: AWSChimeMeeting;
  private _localAttendee?: AWSChimeAttendee;

  private _videoPreviewElement?: HTMLVideoElement;
  private audioOutputElement?: HTMLAudioElement;

  private logger: ConsoleLogger;
  private deviceController?: DefaultDeviceController;

  public get videoPreviewElement(): HTMLVideoElement | undefined {
    return this._videoPreviewElement;
  }

  public get meetingSession(): DefaultMeetingSession | undefined {
    return this._meetingSession;
  }

  public get meetingInfo(): AWSChimeMeeting | undefined {
    return this._meetingInfo;
  }

  public get localAttendee(): AWSChimeAttendee | undefined {
    return this._localAttendee;
  }

  constructor(
    private http: HttpClient,
    private deviceSelectionService: DeviceSelectionService,
    private i18nService: I18nService
  ) {
    this.logger = new ConsoleLogger('MeetingLogs', LogLevel.INFO);
  }

  public createMeeting(
    sessionId: string,
    therapistId: string,
    videoPreviewElement: HTMLVideoElement,
    audioOutputElement: HTMLAudioElement
  ) {
    this.deviceController = new DefaultDeviceController(this.logger);

    return this.createMeetingAPI(sessionId, therapistId).pipe(
      takeUntil(this.cancelCalling$),
      map((result) => {
        this._localAttendee = result.body.newMeeting.attendee;
        this._meetingInfo = result.body.newMeeting.meeting;

        return new MeetingSessionConfiguration(
          this._meetingInfo,
          this._localAttendee
        );
      }),
      concatMap((meetingConfiguration) =>
        this._creatingMeeting(meetingConfiguration)
      ),
      concatMap(() => this._setMeetingDevices()),
      concatMap(() =>
        this._bindMeetingElements(audioOutputElement, videoPreviewElement)
      ),
      concatMap(() => this._createMeetingObservers()),
      map(() => this.meetingSession as DefaultMeetingSession),
      catchError((error: AWSChimeError) => {
        void this.stop();
        if (!error.code) {
          error.code = AWSChimeErrorCodes.Unknown;
        }
        return throwError(() => error);
      })
    );
  }

  public addAttendeeToMeeting(
    meetingInfo: AWSChimeMeeting,
    audioOutputElement: HTMLAudioElement
  ) {
    this.deviceController = new DefaultDeviceController(this.logger);

    const attendeeId = uuidv4();
    this._meetingInfo = meetingInfo;

    return this.http
      .post<AWSChimeCreateAttendeeResponse>(
        environment.WeMindVideoCallingServiceBaseURL +
          'video-conference/create-attendee',
        {
          type: 'AWS',
          attendeeId,
          meetingId: meetingInfo.MeetingId,
          region: this.getServerRegionBasedOnTimezone()
        }
      )
      .pipe(
        takeUntil(this.cancelCalling$),
        map((result) => {
          this._localAttendee = result.body.newAttendee.attendee;

          return new MeetingSessionConfiguration(
            this._meetingInfo,
            this._localAttendee
          );
        }),
        concatMap((meetingConfiguration) =>
          this._creatingMeeting(meetingConfiguration)
        ),
        concatMap(() => this._setMeetingDevices()),
        concatMap(() => this._bindMeetingElements(audioOutputElement)),
        concatMap(() => this._createMeetingObservers()),
        map(() => this.meetingSession as DefaultMeetingSession),
        catchError((error: AWSChimeError) => {
          void this.stop();
          if (!error.code) {
            error.code = AWSChimeErrorCodes.Unknown;
          }
          return throwError(() => error);
        })
      );
  }

  public async stop(): Promise<void> {
    this.cancelCalling$.next();
    if (this.deviceController) {
      await this.deviceController?.destroy();
      this.deviceController = undefined;
    }

    if (this.meetingSession) {
      if (this.videoPreviewElement) {
        this.meetingSession.audioVideo.stopVideoPreviewForVideoInput(
          this.videoPreviewElement
        );
        this._videoPreviewElement = undefined;
      }

      if (this.audioOutputElement) {
        this.meetingSession.audioVideo.unbindAudioElement();
        this.audioOutputElement = undefined;
      }

      this.meetingSession.audioVideo.stopLocalVideoTile();
      await this.meetingSession.audioVideo.stopAudioInput();
      await this.meetingSession.audioVideo.stopVideoInput();
      this.meetingSession.audioVideo.removeLocalVideoTile();

      this.meetingSession.audioVideo.stop();
      await this.meetingSession.destroy();

      this._meetingSession = undefined;
      this._meetingInfo = undefined;
      this._localAttendee = undefined;
    }
  }

  public startVideoInput(
    meetingSession: DefaultMeetingSession,
    videoInputDeviceId: string
  ) {
    const startVideoInputAsync = async () => {
      const processors: Array<VideoFrameProcessor> = [];
      const blur = this.deviceSelectionService.backgroundBlur();

      if ((await BackgroundBlurVideoFrameProcessor.isSupported()) && blur) {
        const blurProcessor = await BackgroundBlurVideoFrameProcessor.create();
        processors.push(blurProcessor as VideoFrameProcessor);
      }
      const transformDevice = new DefaultVideoTransformDevice(
        this.logger,
        videoInputDeviceId,
        processors
      );
      return meetingSession.audioVideo.startVideoInput(transformDevice);
    };

    return from(startVideoInputAsync()).pipe(
      catchError((error) => {
        return throwError(
          () =>
            new AWSChimeError(
              error,
              this.isFetchingError(error)
                ? AWSChimeErrorCodes.VideoInputFetching
                : AWSChimeErrorCodes.NoVideoInputPermission
            )
        );
      })
    );
  }

  private createMeetingAPI(sessionId: string, therapistId: string) {
    return this.http.post<AWSChimeCreateMeetingResponse>(
      `${environment.WeMindVideoCallingServiceBaseURL}video-conference/create-meeting`,
      {
        type: 'AWS',
        sessionId,
        therapistId,
        region: this.getServerRegionBasedOnTimezone()
      }
    );
  }

  private _creatingMeeting(
    meetingConfiguration: MeetingSessionConfiguration
  ): Observable<DefaultMeetingSession> {
    try {
      const meetingSession = new DefaultMeetingSession(
        meetingConfiguration,
        this.logger,
        this.deviceController as DefaultDeviceController
      );
      this._meetingSession = meetingSession;

      return of(this._meetingSession);
    } catch (error: any) {
      return throwError(
        () => new AWSChimeError(error, AWSChimeErrorCodes.CreateMeetingFailed)
      );
    }
  }

  private _setMeetingDevices(): Observable<any> {
    const meetingSession = this.meetingSession;

    if (!meetingSession) {
      return throwError(
        () =>
          new AWSChimeError(
            'AwsChimeSDKService._setMeetingDevices: meetingSession is not defined',
            AWSChimeErrorCodes.MeetingNotDefined
          )
      );
    }

    const audioInputDeviceId =
      this.deviceSelectionService.audioInputControl.value;
    const audioOutputDeviceId =
      this.deviceSelectionService.audioOutputControl.value;
    const videoInputDeviceId =
      this.deviceSelectionService.videoInputControl.value;

    if (!audioInputDeviceId || !videoInputDeviceId) {
      return throwError(
        () =>
          new AWSChimeError(
            'AwsChimeSDKService._setMeetingDevices: No devices were selected',
            AWSChimeErrorCodes.NoDevicesSelected
          )
      );
    }

    return from(
      meetingSession.audioVideo.startAudioInput(audioInputDeviceId)
    ).pipe(
      catchError((error) => {
        return throwError(
          () =>
            new AWSChimeError(
              error,
              this.isFetchingError(error)
                ? AWSChimeErrorCodes.AudioInputFetching
                : AWSChimeErrorCodes.NoAudioInputPermission
            )
        );
      }),
      concatMap(() =>
        from(
          meetingSession.audioVideo.chooseAudioOutput(audioOutputDeviceId)
        ).pipe(
          catchError((error) => {
            return throwError(
              () =>
                new AWSChimeError(
                  error,
                  this.isFetchingError(error)
                    ? AWSChimeErrorCodes.AudioOuputFetching
                    : AWSChimeErrorCodes.NoAudioOutputPermission
                )
            );
          })
        )
      ),
      concatMap(() => {
        if (this.camEnabled()) {
          return this.startVideoInput(meetingSession, videoInputDeviceId);
        } else {
          return of(false);
        }
      })
    );
  }

  private _bindMeetingElements(
    audioOutputElement: HTMLAudioElement,
    videoPreviewElement?: HTMLVideoElement
  ): Observable<any> {
    const meetingSession = this.meetingSession;

    if (!meetingSession) {
      return throwError(
        () =>
          new AWSChimeError(
            'AwsChimeSDKService._bindMeetingElements: meetingSession is not defined',
            AWSChimeErrorCodes.MeetingNotDefined
          )
      );
    }
    if (videoPreviewElement) {
      this._videoPreviewElement = videoPreviewElement;
      meetingSession.audioVideo.startVideoPreviewForVideoInput(
        this._videoPreviewElement
      );
    }

    this.audioOutputElement = audioOutputElement;

    return from(
      meetingSession.audioVideo.bindAudioElement(this.audioOutputElement)
    );
  }

  private _createMeetingObservers(): Observable<any> {
    const meetingSession = this.meetingSession;

    if (!meetingSession) {
      return throwError(
        () =>
          new AWSChimeError(
            'AwsChimeSDKService._createMeetingObservers: meetingSession is not defined',
            AWSChimeErrorCodes.MeetingNotDefined
          )
      );
    }

    const observer: AudioVideoObserver = {
      audioVideoDidStart: () => {
        Logger.log('AwsChimeSDKService.observer.audioVideoDidStart: Started');
        this.audioVideoDidStart$.next();
      },
      audioVideoDidStop: (sessionStatus: MeetingSessionStatus) => {
        const sessionStatusCode = sessionStatus.statusCode();

        Logger.log(
          `AwsChimeSDKService.observer.audioVideoDidStop: Stopped with a session status code: ${sessionStatusCode}`
        );
        this.audioVideoDidStop$.next(sessionStatus);
      },
      audioVideoDidStartConnecting: (reconnecting: boolean) => {
        if (reconnecting) {
          // e.g. the WiFi connection is dropped.
          Logger.warn(
            `AwsChimeSDKService.observer.audioVideoDidStartConnecting: Attempting to reconnect`
          );
        }
        this.audioVideoDidStartConnecting$.next(reconnecting);
      },
      videoTileDidUpdate: (tileState: VideoTileState) => {
        Logger.log(
          `AwsChimeSDKService.observer.videoTileDidUpdate: Tilestate updated`
        );
        this.videoTileDidUpdate$.next(tileState);
      },
      videoTileWasRemoved: (tileId: number) => {
        Logger.log(
          `AwsChimeSDKService.observer.videoTileWasRemoved: Video tile removed with id: ${tileId}`
        );
        this.videoTileWasRemoved$.next(tileId);
      },
      connectionDidBecomePoor: () => {
        Logger.warn(
          `AwsChimeSDKService.observer.connectionDidBecomePoor: Your connection is poor`
        );
        this.connectionDidBecomePoor$.next();
      },
      connectionDidSuggestStopVideo: () => {
        Logger.warn(
          `AwsChimeSDKService.observer.connectionDidSuggestStopVideo: Recommend turning off your video`
        );
        this.connectionDidSuggestStopVideo$.next();
      },
      metricsDidReceive: (clientMetricReport) => {
        const metricReport = clientMetricReport.getObservableMetrics();

        const {
          videoPacketSentPerSecond,
          videoUpstreamBitrate,
          availableOutgoingBitrate,
          availableIncomingBitrate,
          audioSpeakerDelayMs
        } = metricReport;

        Logger.log(
          `Sending video bitrate in kilobits per second: ${
            videoUpstreamBitrate / 1000
          } and sending packets per second: ${videoPacketSentPerSecond}`
        );
        Logger.log(
          `Sending bandwidth is ${
            availableOutgoingBitrate / 1000
          }, and receiving bandwidth is ${availableIncomingBitrate / 1000}`
        );
        Logger.log(`Audio speaker delay is ${audioSpeakerDelayMs}`);
      }
    };

    meetingSession.audioVideo.addObserver(observer);

    const callback = (presentAttendeeId: any, present: any) => {
      console.log(`Attendee ID: ${presentAttendeeId} Present: ${present}`);
      if (!present) {
        this.attendeeLeft$.next();
      }
    };

    meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(callback); // Should be out of Angular Zone.js
    return of(true);
  }
  private getServerRegionBasedOnTimezone(): string {
    const userTimezone = dayjs.tz.guess();
    let region = 'eu';

    if (userTimezone.includes('America')) {
      region = 'us';
    } else if (userTimezone.includes('Asia')) {
      region = 'asia';
    }

    return this.getRegion(region);
  }

  private getRegion(region: string): string {
    switch (region) {
      case 'us':
        return 'us-east-1';
      case 'asia':
        return 'ap-southeast-1';
      default:
        return 'eu-west-1';
    }
  }

  private isFetchingError(error: Error): boolean {
    let fetching = false;
    if (error.message) {
      const message = error.message;

      if (message.toLowerCase().includes('fetching')) {
        fetching = true;
      }
    }
    return fetching;
  }
}
