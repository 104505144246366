export enum SocketEnum {
  //General commands
  DEFAULT = 0,
  EXCEPTION = 1,
  RECONNECT = 2,
  INFO = 3,

  //not editable in settings
  START_TASK,
  SESSION_REACTION,
  VISUAL_CLICK_TASK,
  VISUAL_BALL_CLICKABLE,
  EXPOSURE_ENABLED,
  EXPOSURE_STATE,
  STREAM_AUDIO,
  STREAM_VIDEO,
  STREAM_CLOSE,
  //editable in settings. perfectly mapped with properties in Set.
  //order by visual, audio, exposure and room
  VisualEnabled,
  VisualSpeed,
  VisualPattern,
  VisualSpeedDynamic,
  VisualColor,
  VisualColorSpeed,
  VisualRangeOfField,

  AudioDistractionEnabled,
  AudioRIREnabled,
  AudioErrorFeedbackEnabled,
  AudioInterval,
  AudioVolumeDistraction,
  AudioSpeedDistraction,
  AudioVolumeRIR,

  ExposureVolume,
  ExposureScreenSize,
  ExposureScreenDistance,

  RoomEnvironment,
  STREAM_CALL,
  PING,
  PONG,
  REQUEST_END_SESSION,
  END_SESSION,
  RESUME_SESSION,

  AUDIO_TASK_CLICKABLE,
  AUDIO_TASK_ENABLED,
  AudioIntervalAlgorithm,
  STREAM_DATA,
  STREAM_DATA_CLOSE,

  WEBRTC_HANGUP,
  WEBRTC_CALL,
  WEBRTC_ANSWER,
  WEBRTC_OFFER,
  WEBRTC_CANDIDATE,

  PEER_OFFER,
  PEER_ANWSER,
  PEER_DESTROY,

  PEER_FILE,
  PEER_FILE_INFO_RECIEVED,
  PEER_FILE_ABORT,
  PEER_FILE_COMPLETED,

  ARRAY,
  DEVICE_INFO,
  AudioSoundType,

  ForceSinkSettings,

  AWSChimeMeetingID,
  AWSChimeMeetingDeclined,
  AWSChimeMeetingError,
  AWSChimeMeetingHangUp,

  VisualChangeShape
}

export enum SceneEnum {
  APARTMENT = 0,
  DARK
}
export enum BackgroundEnum {
  APARTMENT = 0,
  DARK,
  BEACH,
  FOREST
}
export enum ColorEnum {
  SILVER,
  MAGENTA,
  BLUE,
  GREEN,
  RED,
  WHITE,
  YELLOW,
  RANDOM,
  FULLRANDOM
}

export enum ReactionEnum {
  NORMAL = 0,
  FAST = 1,
  SLOW = 2,
  NOTHING = 3,
  EMPTY = 4
}

export enum VisualPatternEnum {
  RANDOM,
  HORIZONTAL,
  TRIANGLE,
  DIAGONAL,
  VERTICAL
}

export enum AudioIntervalEnum {
  CONSTANT = 0,
  RANDOM = 1
}

export enum ExposureState {
  CLIENT_READY,
  PLAY,
  PAUSE,
  STOP,
  TIME,
  EX360,
  LOADING,
  SOURCE,
  CANCEL
}

export enum TaskEnum {
  VISUAL = 0,
  RIR = 1,
  VIDEO_START = 2,
  VIDEO_STOP = 3,
  AUDIO = 4
}

export enum ExposureType {
  VIDEO,
  IMAGE,
  AUDIO
}

export enum ExposureSource {
  CLOUD,
  UPLOAD
}

export enum AudioTypesEnum {
  Simple,
  Medium,
  Extensive,
  Strings1,
  Strings2
}
