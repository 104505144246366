<app-dialog (closeClicked)="cancel()" [disableClose]="true">
  <div class="container">
    <div class="timer-icon-container">
      <fa-icon icon="hourglass-start"></fa-icon>
    </div>

    <h4>{{ 'signup.popup.title' | translate }}</h4>
    <p class="description">
      {{ 'signup.popup.description' | translate }}
    </p>

    <div class="progress-bar-container">
      <app-progress-bar
        [value]="progressService.getProgress()"></app-progress-bar>

      @if (!progressService.error() && progressService.getProgress() !== 100) {
        <p>
          {{ progressService.getProgress() + '% ' }}
          {{ 'signup.popup.progress' | translate }}
        </p>
      }
    </div>

    @if (progressService.getProgress() === 100) {
      <div class="success-container">
        <fa-icon icon="circle-check"></fa-icon>
        <p>
          {{ 'signup.popup.success' | translate }}
        </p>
      </div>
    }

    @if (progressService.error() && progressService.getProgress() !== 100) {
      <div class="error-container">
        <fa-icon icon="circle-exclamation"></fa-icon>
        <p>
          {{ 'signup.popup.failed' | translate }}
        </p>
      </div>
    }
  </div>
</app-dialog>
