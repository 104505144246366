import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../services';
import { environment } from '../../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.authService.getAccessToken();
    const requestBody: any = request && request.body;

    // Check if the request body is FormData
    if (request.body instanceof FormData) {
      // If the request body is FormData, just append the Authorization header
      const headers = request.headers.set('Authorization', `Bearer ${token}`);
      const authReq = request.clone({ headers });
      return next.handle(authReq);
    } else if (requestBody && requestBody.type === 'AWS') {
      const awsToken = environment.WeMindEcosystemBearer;
      const headers = request.headers
        .set('Authorization', `Bearer ${awsToken}`)
        .append('Content-Type', 'application/json');
      const authReq = request.clone({ headers });
      return next.handle(authReq);
    } else {
      // For other requests, add Authorization and other headers
      if (!token) {
        const headers = request.headers.set('Content-Type', 'application/json');
        const req = request.clone({ headers });
        return next.handle(req);
      }

      const headers = request.headers
        .set('Authorization', `Bearer ${token}`)
        .append('Content-Type', 'application/json')
        .append('Cache-Control', 'no-cache')
        .append('Pragma', 'no-cache');

      const req = request.clone({ headers });

      return next.handle(req);
    }
  }
}
