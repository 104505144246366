import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingStep, OnboardingUser, User } from 'src/app/core/models';
import { AuthService, OnboardingService } from 'src/app/core/services';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonDirective } from '@shared/directives';

@Component({
  selector: 'app-onboarding',
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonDirective],
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  @Output()
  public closeEvent = new EventEmitter<boolean>();

  @Output()
  public hideScroll = new EventEmitter<boolean>();

  @Output()
  public triggerOnboarding = new EventEmitter<boolean>();

  public targetBox!: HTMLElement;
  public container!: HTMLElement;
  public arrow!: HTMLElement;
  public marginLeft?: number;
  public elementPosition!: DOMRect;
  public steps = 0;
  public title?: string;
  public description?: string;
  public imageSrc?: string;
  public onboardingItems: Array<OnboardingStep> = [];
  public user?: User;
  public onboardingUser?: OnboardingUser;
  public loading = true;
  public dontUpdate = true;
  public btnName = 'label.next';
  public ItemExist = false;

  private readonly rightStyleSettings =
    'top: 44%;border-bottom: 15px solid transparent;border-left: 0px solid ;border-right: 25px solid  white;border-top: 15px solid transparent;';
  private readonly leftStyleSettings =
    'top: 35%;right:-5%;border-bottom: 20px solid transparent;border-right: 0px solid ;border-left: 20px solid white;border-top: 20px solid transparent;';
  private readonly upStyleSettings =
    'top: 99%;border-left: 20px solid transparent;border-right: 20px solid transparent;border-top: 20px solid white;';
  private readonly downStyleSettings =
    'top: -15%;border-left: 20px solid transparent;border-right: 20px solid transparent;border-bottom: 20px solid white;';

  constructor(
    public onboardingService: OnboardingService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getUser();

    this.onboardingService
      .GetNewOnboardingStepsForUserById(this.user?.id as string)
      .subscribe({
        next: (steps) => {
          // this.onboardingItems = steps;
          this.triggerOnboarding.emit(true);
          // before saving the current user on the onboarding user.
          // check if it is not null and if the user.ids are the same.
          if (
            steps.length > 0 &&
            steps[0].onboardingUsers[0] !== null &&
            steps[0].onboardingUsers[0]?.userId === this.user?.id
          ) {
            this.onboardingUser = steps[0].onboardingUsers[0];
            this.dontUpdate = false;
          }

          if (this.onboardingItems[0] === undefined) {
            console.log('get all steps');
            this.getAllSteps();
            return;
          }

          this.startOnboarding(0);
        },
        error: (error) => {
          console.log(error);
        }
      });
  }

  private getAllSteps() {
    this.onboardingService.GetAllOnboardingSteps().subscribe((allSteps) => {
      this.triggerOnboarding.emit(false);
      this.onboardingItems = allSteps;
      console.log(allSteps);
      this.startOnboarding(0);
    });
  }

  startOnboarding(index: number): void {
    console.log(index);
    this.hideScroll.emit(true);
    this.loading = false;
    this.targetBox = document.getElementById(
      this.onboardingItems[index].json.TargetContainerId
    ) as HTMLElement;
    this.imageSrc = this.onboardingItems[index].json.DialogIcon;
    this.description = this.onboardingItems[index].json.DialogText;
    this.title = this.onboardingItems[index].json.DialogHeader;

    if (this.targetBox) {
      if (this.onboardingItems[index].json.PlacementDialog == 'right') {
        this.setElements();
        this.arrow.setAttribute('style', this.rightStyleSettings);
        this.arrow.style.marginLeft = '-53px';
        this.marginLeft = parseInt(getComputedStyle(this.targetBox).width);
        this.container.style.marginLeft = '30px';
        this.elementPosition = this.targetBox.getBoundingClientRect();
        this.container.style.top =
          String(
            this.elementPosition.top +
              parseInt(getComputedStyle(this.targetBox).height) / 2 -
              100
          ) + 'px';
        this.container.style.left =
          String(
            this.elementPosition.left +
              parseInt(getComputedStyle(this.targetBox).width)
          ) + 'px';
      } else if (this.onboardingItems[index].json.PlacementDialog == 'left') {
        this.setElements();
        this.arrow.setAttribute('style', this.leftStyleSettings);
        this.arrow.style.marginLeft = '-18px';
        this.elementPosition = this.targetBox.getBoundingClientRect();
        this.container.style.left =
          String(
            this.elementPosition.left -
              parseInt(getComputedStyle(this.container).width) -
              20
          ) + 'px';
        this.container.style.top =
          String(
            this.elementPosition.top +
              parseInt(getComputedStyle(this.targetBox).height) / 2 -
              100
          ) + 'px';
      } else if (this.onboardingItems[index].json.PlacementDialog == 'up') {
        this.setElements();
        this.arrow.setAttribute('style', this.upStyleSettings);
        this.arrow.style.marginLeft =
          String(parseInt(getComputedStyle(this.container).width) / 2.2) + 'px';
        this.elementPosition = this.targetBox.getBoundingClientRect();
        this.container.style.top =
          String(
            this.targetBox.getBoundingClientRect().top -
              this.container.getBoundingClientRect().height -
              40
          ) + 'px';
        this.container.style.left = this.elementPosition.left.toString() + 'px';
        if (
          this.container.getBoundingClientRect().right >
          this.targetBox.getBoundingClientRect().right
        ) {
          this.container.style.marginLeft =
            String(
              this.targetBox.getBoundingClientRect().right -
                this.container.getBoundingClientRect().right +
                this.container.getBoundingClientRect().width / 2
            ) + 'px';
        } else if (
          this.container.getBoundingClientRect().right <
          this.targetBox.getBoundingClientRect().right
        ) {
          this.container.style.marginLeft =
            String(
              (this.targetBox.getBoundingClientRect().right -
                this.container.getBoundingClientRect().right) /
                2
            ) + 'px';
        }
      } else if (this.onboardingItems[index].json.PlacementDialog == 'down') {
        this.setElements();
        this.arrow.setAttribute('style', this.downStyleSettings);
        this.arrow.style.marginLeft =
          String(parseInt(getComputedStyle(this.container).width) / 2.2) + 'px';
        this.elementPosition = this.targetBox.getBoundingClientRect();
        this.container.style.top =
          String(
            this.targetBox.getBoundingClientRect().top +
              this.targetBox.getBoundingClientRect().height +
              40
          ) + 'px';
        this.container.style.left =
          this.targetBox.getBoundingClientRect().left.toString() + 'px';
        if (
          this.container.getBoundingClientRect().right >
          this.targetBox.getBoundingClientRect().right
        ) {
          this.container.style.marginLeft =
            String(
              this.targetBox.getBoundingClientRect().right -
                this.container.getBoundingClientRect().right +
                this.container.getBoundingClientRect().width / 2
            ) + 'px';
        } else if (
          this.container.getBoundingClientRect().right <
          this.targetBox.getBoundingClientRect().right
        ) {
          this.container.style.marginLeft =
            String(
              (this.targetBox.getBoundingClientRect().right -
                this.container.getBoundingClientRect().right) /
                2
            ) + 'px';
        }
      }
    }

    // updating the database on where the user is in the steps
    this.updateOnboardingUser(this.onboardingItems[index].id as number);
  }
  setElements() {
    this.container = document.getElementById(
      'onboarding-container'
    ) as HTMLElement;
    this.arrow = document.getElementById('box-arrow') as HTMLElement;
    this.targetBox.insertAdjacentElement('afterend', this.container);
    this.container.style.marginLeft = '0px';
    this.targetBox.scrollIntoView({ block: 'center' });
    this.targetBox.setAttribute('style', ' z-index: 21;');
    this.container.insertAdjacentElement('afterbegin', this.arrow);
  }
  nextMessage() {
    let totalNumber = 0;
    this.steps += 1;
    this.onboardingItems.forEach((e, i) => {
      totalNumber = this.onboardingItems.length;
      if (i === this.steps) {
        this.targetBox.setAttribute('style', '');
        this.startOnboarding(i);
        this.ItemExist = true;
      } else if (this.steps >= totalNumber - 1) {
        this.btnName = 'label.finish';
      }
    });

    if (this.steps >= totalNumber) {
      this.targetBox.setAttribute('style', '');
      this.ItemExist = false;
      this.cancelMessage();
    }
  }

  cancelMessage() {
    console.log('cancel function');
    this.targetBox.setAttribute('style', '');
    this.loading = true;
    this.container.remove();
    this.arrow.remove();
    this.closeEvent.emit(false);
    this.hideScroll.emit(false);
    this.dontUpdate = true;
    this.onboardingService.close();
  }

  /**
   * Update the the last step the user did in the onboarding
   */
  private updateOnboardingUser(step: number): void {
    if (!this.dontUpdate) {
      if (this.onboardingUser) {
        this.onboardingUser.lastViewed = new Date();
        this.onboardingUser.onboardingStepId = step;
      }

      console.log(this.onboardingUser?.onboardingStepId);
      this.onboardingService
        .UpdateOnboardingStepForUser(this.onboardingUser as OnboardingUser)
        .subscribe({
          next: (e) => {
            console.log(e);
          },
          error: (error) => {
            console.log(error);
          }
        });
    } else {
      console.log('not updating');
    }
  }
}
