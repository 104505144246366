export const GetIceServers = (): RTCIceServer[] => {
  const s1: RTCIceServer = {
    urls: 'turn:relay1.expressturn.com:3478',
    username: 'ef2AR6TKVRZ40N2XXO',
    credential: 'ikUSHcqC5rxIOOJ2'
  };
  const s2: RTCIceServer = {
    urls: 'stun:stun.l.google.com:19302'
  };
  return [s1, s2];
};
