export interface OnboardingUser {
  userId?: string;
  onboardingStepId: number;
  lastViewed: Date;
  version: number;
}

export interface OnboardingStep {
  active: number;
  description: string;
  id?: number;
  lastUpdateDate: Date;
  json: any;
  onboardingUsers: OnboardingUser[];
  stepNumber: number;
}
