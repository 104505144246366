import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SessionStorageKey } from '@core/enums';
import { User } from '@core/models';
import { SessionStorageService } from '@core/services';
import { TutorialService } from '@core/services/tutorial/tutorial.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { LabelComponent } from '@shared/components';
import { ButtonDirective } from '@shared/directives';
import { finalize } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    LabelComponent,
    TranslateModule,
    ButtonDirective,
    FaIconComponent
  ],
  templateUrl: './onboarding-content.component.html',
  styleUrl: './onboarding-content.component.scss'
})
export class OnBoardingContentComponent implements OnInit {
  public isContentShowing: boolean = false;
  public isUserLoggedInBefore: boolean = false;
  public selectedArcadeContent?: string;
  public isLoading: boolean = false;

  constructor(
    private dialogRef: DialogRef<boolean>,
    private sessionStorageService: SessionStorageService,
    private tutorialService: TutorialService
  ) {}

  ngOnInit(): void {
    const { id: userId } = this.sessionStorageService.get(
      SessionStorageKey.LoggedInUser
    ) as User;

    this.isLoading = true;

    this.tutorialService
      .GetNewOnboardingStepsForUserById(userId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (res) => {
          if (res.length === 1) {
            this.isUserLoggedInBefore = true;
          }
        },
        error: (err) => {
          console.error('Error fetching onboarding steps:', err);
          // Handle error as needed
        }
      });
  }

  public backButtonClicked() {
    this.isContentShowing = false;
    this.selectedArcadeContent = undefined;
  }

  public itemContentClicked(selectedContent: string) {
    this.isContentShowing = true;
    this.selectedArcadeContent = selectedContent;
  }

  public closeDialogClicked(result: boolean): void {
    const { id: userId } = this.sessionStorageService.get(
      SessionStorageKey.LoggedInUser
    ) as User;
    this.tutorialService.UpdateOnboardingStepForUser({
      userId,
      onboardingStepId: 2,
      lastViewed: new Date(),
      version: 1
    });
    this.dialogRef.close(result);
  }
}
