import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from '../../directives';
import { LabelComponent } from '../label';

@Component({
  selector: 'app-button-card',
  standalone: true,
  imports: [CommonModule, ButtonDirective, LabelComponent],
  templateUrl: './button-card.component.html',
  styleUrls: ['./button-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonCardComponent {
  @Input()
  public buttonText?: string;

  @Output()
  public clicked = new EventEmitter<void>();

  public onClick(): void {
    this.clicked.emit();
  }
}
